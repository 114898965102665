import { IncomingMessage } from 'http';

import apiRequest from '@/utils/apiRequest';
import { userDataFromBrowser } from '@/utils/apiRequest/userDataFromBrowser';
import { userDataFromRequest } from '@/utils/apiRequest/userDataFromRequest';

import { pxTestHeaders } from '../../utils/apiRequest/perimeterx';
import { JSONValue } from '../../utils/types';

interface ApiFeatureFlag {
	name: string;
	value: JSONValue;
}

export interface ApiFeatureFlagResponse {
	feature_flags: ApiFeatureFlag[];
}

export async function getFeatureFlags(
	flags?: string[],
	req?: IncomingMessage,
	deviceId?: string,
	calmIdentifier?: string | undefined,
): Promise<ApiFeatureFlagResponse | null> {
	const isServer = req && deviceId;

	try {
		const endpoint =
			flags && Boolean(flags?.length) ? `feature-flags?filter[name]=${flags.join(',')}` : 'feature-flags';

		const result = await apiRequest<ApiFeatureFlagResponse>({
			endpoint,
			method: 'GET',
			userData: isServer ? userDataFromRequest({ req, deviceId, calmIdentifier }) : userDataFromBrowser(),
			customHeaders: {
				...pxTestHeaders,
			},
		});
		return result?.data;
	} catch (err) {
		return null;
	}
}
