import { AxiosResponse } from 'axios';

import { browserApiRequest } from '../apiRequest/browserApiRequest';

export function postDependents(subscriptionId: string | null): Promise<AxiosResponse> {
	return browserApiRequest({
		endpoint: 'b2b/dependents',
		method: 'POST',
		body: {
			b2b_partner_subscription_id: subscriptionId,
		},
	});
}
