import styled, { css } from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

import { Button, FontSizes, FontWeights, minWidth, Text, units } from '@calm-web/design-system';

import Image from '@/components/image/NextImage';
import { UPSELL_FOOTER_ID } from '@/components/UpsellFooter';
import { UPSELL_FOOTER_HEIGHT } from '@/components/UpsellFooter/styles';
import {
	COLLAPSED_AUDIO_PLAYER_INDEX,
	EXPANDED_AUDIO_PLAYER_INDEX,
	GUIDE_INFO_INDEX,
} from '@/utils/ui/zIndices';

export const Wrapper = styled.div<{
	isVisible: boolean;
	$isLargePlayer: boolean;
	$isWebAppRedesign?: boolean;
}>`
	bottom: 0;
	${p => (p.$isLargePlayer ? '' : 'height: calc(min(68px, 20vh))')};
	opacity: ${p => (p.isVisible ? '1' : '0')};
	left: 0;
	${p => (p.$isLargePlayer ? (p.$isWebAppRedesign ? 'position: sticky;' : '') : 'position: fixed;')};
	right: 0;
	transform: translate3d(0, ${p => (p.isVisible ? '0' : '100%')}, 0);
	transition: ${p => (p.isVisible ? theme('animation.transition') : 'none')};
	visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
	z-index: ${p => (p.$isLargePlayer ? EXPANDED_AUDIO_PLAYER_INDEX : COLLAPSED_AUDIO_PLAYER_INDEX)};

	${minWidth('tablet')} {
		${p => (p.$isLargePlayer ? '' : 'height: 100px')};
	}

	&:has(+ #${UPSELL_FOOTER_ID}) {
		bottom: ${UPSELL_FOOTER_HEIGHT};
	}
`;

export const PlayerClickTrap = styled(Button).attrs({
	backgroundColor: 'transparent',
})`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: none;
	border-radius: 0px;
	&& {
		&:after {
			border-radius: 0px;
			margin: ${units(0.5)};
		}
		&:before {
			opacity: 0;
		}
	}
`;

export const Container = styled.div<{
	$isLargePlayer: boolean;
	$isPreviewMode: boolean;
	$isZoomApp?: boolean;
}>`
	align-items: center;
	bottom: 0;
	display: flex;
	${p => (p.$isLargePlayer ? '' : 'height: 100%;')}
	left: 0;
	padding: ${p => (p.$isLargePlayer ? units(2, 2, 4) : '8px 16px')};
	${p => (p.$isLargePlayer ? 'justify-content: center;' : 'position: absolute;')}
	right: 0;
	overflow-y: ${p => (p.$isPreviewMode ? 'initial' : 'hidden')};

	${minWidth('tablet')} {
		${p => (p.$isLargePlayer ? '' : 'height: 100px')};
		padding: ${p => (p.$isLargePlayer ? units(2, 2, 4) : '8px 32px')};
	}
`;

export const Background = styled.div`
	background: ${palette('darkBlue')};
	bottom: 0;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
`;

export const GuideInfo = styled.div<{ isVisible: boolean }>`
	align-items: center;
	color: ${palette('white')};
	display: flex;
	flex: 1 1 80%;
	opacity: ${p => (p.isVisible ? '1' : '0')};
	pointer-events: none;
	transition: ${p => (p.isVisible ? theme('animation.transition') : 'none')};
	z-index: ${GUIDE_INFO_INDEX};
	margin-right: ${units(1)};
	max-height: 100%;

	${minWidth('tablet')} {
		flex: 1 0 258px;
	}
`;

export const GuideThumbnail = styled(Image)`
	border-radius: 50%;
`;

export const GuideThumbnailContainer = styled.div`
	flex: 0 1 32px;

	${minWidth('tablet')} {
		flex-basis: 64px;
	}
`;

export const GuideCopy = styled.div`
	margin-left: 8px;

	${minWidth('tablet')} {
		margin-left: 16px;
	}

	align-self: stretch;
	display: flex;
	align-items: normal;
	flex-direction: column;
	justify-content: center;
`;

export const GuideTitle = styled(Text).attrs({
	el: 'h2',
	weight: FontWeights.Regular,
	size: FontSizes.base,
	noMargin: true,
})`
	${minWidth('tablet')} {
		font-size: inherit;
	}
`;

export const ProgramTitle = styled.div`
	opacity: 0.8;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	display: none;

	${minWidth('mobileLarge')} {
		display: block;
	}

	${minWidth('tablet')} {
		font-size: inherit;
	}
`;

const smallTransport = css`
	align-items: center;
	display: flex;
	flex: 0 0 20%;
	height: 100%;
	margin-left: auto;
	position: relative;

	${minWidth('tablet')} {
		flex: 0 0 368px;
		margin-left: 0;
	}
`;

const largeTransport = css<{
	$isZoomApp?: boolean | undefined;
	$isWebAppRedesign?: boolean | undefined;
}>`
	align-items: center;
	display: flex;
	flex-direction: column;
	text-align: center;

	${ifProp(
		'$isWebAppRedesign',
		css`
			border-radius: 20px;
			border: 2px solid ${palette('blackAlpha10')};
			background: ${palette('blackAlpha90')};
			box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
			padding: ${units(2.5)};

			${minWidth('tablet')} {
				width: 560px;
				padding: ${units(3)} ${units(4.5)};
			}
		`,
	)}

	${minWidth('tablet')} {
		width: 560px;
	}

	${p =>
		p.$isZoomApp
			? 'padding-bottom: 60px;'
			: css`
					${minWidth('desktop')} {
						position: relative;
						left: calc(${theme('app.sidebarWidth')} / 2);

						${ifProp(
							'$isWebAppRedesign',
							css`
								width: calc(100vw - ${theme('app.sidebarWidth')} - ${2 * 80}px);
							`,
						)}
					}
			  `}
`;

export const TransportContainer = styled.div<{
	sizeTheme: 'large' | 'small';
	$isZoomApp?: boolean | undefined;
	$isWebAppRedesign?: boolean;
}>`
	${p => (p.sizeTheme === 'large' ? largeTransport : smallTransport)}
`;

export const PlayerIcons = styled.div`
	display: flex;
	> * {
		margin: 0px 8px;
	}
`;

export const SmallFaveHeartContainer = styled.div`
	display: none;
	flex: 0 0 24px;

	${minWidth('tablet')} {
		display: block;
		flex: 1 0 24px;
		text-align: right;
	}
`;
