import ProgramBackground from '@/components/app/layout/ProgramBackground';
import SceneVideoBackground from '@/components/app/layout/SceneVideoBackground';
import { useScenes } from '@/hooks/app/useScenes';
import { useThemeState } from '@/hooks/store';

import { sceneThemeGradients, sceneThemeMapping, sceneThemeTypes } from './data';
import { SceneGradient, Wrapper } from './styles';
import { AppBackgroundProps } from './types';

const getCurrentTheme = (sceneId?: string) => {
	if (!sceneId) {
		return sceneThemeGradients.blue;
	}

	const sceneType = sceneThemeMapping[sceneId];

	if (!sceneType || !sceneThemeTypes.includes(sceneType)) {
		return sceneThemeGradients.blue;
	}

	return sceneThemeGradients[sceneType];
};

const AppBackground = ({ isZenMode }: AppBackgroundProps) => {
	const theme = useThemeState();
	const { selectedScene } = useScenes();

	const currentTheme = getCurrentTheme(selectedScene?.id);

	return (
		<Wrapper>
			{selectedScene && (
				<>
					<SceneVideoBackground
						isVisible={isZenMode || !theme.webAppBackgroundImage}
						selectedScene={selectedScene}
					/>
					<SceneGradient
						isVisible={!isZenMode && !theme.webAppBackgroundImage}
						gradientStart={currentTheme.gradientStartColor}
						gradientEnd={currentTheme.gradientEndColor}
					/>
				</>
			)}
			{theme.webAppBackgroundImage && <ProgramBackground backgroundImage={theme.webAppBackgroundImage} />}
		</Wrapper>
	);
};

export default AppBackground;
