import { AxiosPromise } from 'axios';

import { User } from '@/store/user/types';
import { browserApiRequest } from '@/utils/apiRequest/browserApiRequest';
import { JSONObject } from '@/utils/types';

import { FacebookSignInTokenSuccess } from '../facebook/types';

export function postFacebookAuth(
	args: FacebookSignInTokenSuccess & { marketing_opt_in: boolean; guest_pass_code: string | undefined },
): AxiosPromise<User> {
	return browserApiRequest({
		endpoint: 'auth/facebook',
		method: 'POST',
		body: args as JSONObject,
	});
}
