import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { FormStateContext } from '@/context/FormStateContextProvider';
import { FORM_ACTIONS } from '@/hooks/forms';
import { InputFields } from '@/hooks/forms/types';
import { useForgotPasswordSubmit } from '@/hooks/forms/useForgotPasswordSubmit';

import messages from './messages';
import { DesktopCta, ForgotPasswordTextButton, MobileCta, Wrapper } from './styles';

interface ForgotPasswordProps {
	inputFields: InputFields;
	lightText: boolean;
	isLightLeftAlignStyles?: boolean;
}

const ForgotPassword = ({ inputFields, lightText, isLightLeftAlignStyles }: ForgotPasswordProps) => {
	const { formatMessage } = useIntl();
	const { formDispatch, setFormError, setFormSuccess } = useContext(FormStateContext);
	const onForgotPasswordSubmit = useForgotPasswordSubmit();

	async function onSubmit(): Promise<void> {
		formDispatch({ type: FORM_ACTIONS.requestSent });

		const data = await onForgotPasswordSubmit(inputFields.email);

		if (data?.success) {
			setFormSuccess(data.success);
			formDispatch({ type: FORM_ACTIONS.requestSuccess });
		} else if (data?.error) {
			setFormError(data?.error);
			formDispatch({ type: FORM_ACTIONS.requestFailure });
		} else {
			setFormError(null);
			setFormSuccess(null);
			formDispatch({ type: FORM_ACTIONS.requestCancelled });
		}
	}

	return (
		<Wrapper
			isLightLeftAlignStyles={isLightLeftAlignStyles}
			className="forgotPasswordWrapper"
			lightText={lightText}
		>
			<DesktopCta>
				<ForgotPasswordTextButton
					onClick={onSubmit}
					aria-label={formatMessage(messages.desktopCta)}
					data-testid="forgot-password-button"
					isLightLeftAlignStyles={isLightLeftAlignStyles}
				>
					{formatMessage(messages.desktopCta)}
				</ForgotPasswordTextButton>
			</DesktopCta>
			<MobileCta onClick={onSubmit} isLightLeftAlignStyles={isLightLeftAlignStyles}>
				{formatMessage(messages.desktopCta)}
			</MobileCta>
		</Wrapper>
	);
};

export default ForgotPassword;
