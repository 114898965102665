import React, { ReactElement, useEffect } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import Branding from '@/components/SimplifiedSignup/Components/Branding';
import ContentBasedImage from '@/components/SimplifiedSignup/Components/ContentBasedImage';
import { useSimplifiedSignupContext } from '@/components/SimplifiedSignup/Context';
import { useSimplifiedSignupModalContext } from '@/context/SimplifiedSignupModalContextProvider';
import { useAnalytics, useExperimentClient } from '@/hooks/analytics';
import { usePartnerState, usePricesState, usePurchaseParamsState, useUserState } from '@/hooks/store';
import { useIsMobile } from '@/hooks/ui/useIsMobile';
import { useComponentDidMount } from '@/hooks/utils/useComponentDidMount';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import getUrl from '@/utils/getUrl';

import SisuAuthForm from '../Components/SisuAuthForm';
import { TwoColumnWrapper } from '../styles';
import messages from './messages';
import {
	AccountPageWrap,
	AccountValuePropsList,
	AuthColumnWrapper,
	Collapsible,
	FAQContentWrapper,
	FAQWrapper,
	HorizontalLine,
	ValuePropsListWrapper,
} from './styles';
import { AccountScreenConfig } from './types';

const Account = ({ config }: { config?: AccountScreenConfig }) => {
	const { formatMessage } = useIntl();
	const user = useUserState();
	const partner = usePartnerState();
	const { name, activeScreenConfig, coBranded, logPageViewEvent } = useSimplifiedSignupContext();
	const {
		backgroundKey,
		upsellHeader,
		upsellSubHeader,
		contentBasedImage,
		brandingPosition = 'right',
		hasPremiumSubCallback,
		FAQs,
		showHeader,
		upsellBullets = [],
		valuePropsTitleSize,
		valuePropsTitleColor,
		isValuePropsListOrdered,
		valuePropsColor,
		valuePropsButtonColor,
		AdditionalContent,
		isWithinModal,
		onAuthSuccess,
		hideValueProps,
	} = activeScreenConfig as AccountScreenConfig;
	const { setIsModalOpen } = useSimplifiedSignupModalContext();
	const routerPush = useRouterPush();
	const { logEvent } = useAnalytics();
	const [isMobile] = useIsMobile();
	const prices = usePricesState();
	const purchaseParams = usePurchaseParamsState();
	const experimentClient = useExperimentClient();

	useComponentDidMount(() => {
		logPageViewEvent('account');
		logEvent({
			eventName: 'Login Form : Landed',
			eventProps: {
				source: name,
			},
		});
	});

	useEffect(() => {
		if (user?.subscription?.valid && !partner && hasPremiumSubCallback) {
			hasPremiumSubCallback(routerPush, setIsModalOpen);
		}
	}, [user, routerPush, hasPremiumSubCallback, partner, setIsModalOpen]);

	function getTrigger(faq: {
		trigger: MessageDescriptor;
		children: MessageDescriptor;
		placeholders?: { [key: string]: string | number | boolean | (() => JSX.Element) };
	}): {
		trigger: ReactElement;
		children: ReactElement;
	} {
		return {
			trigger: (
				<Text el="h3" size={FontSizes.base} weight={FontWeights.Demi}>
					{formatMessage(faq.trigger, faq.placeholders)}
				</Text>
			),
			children: (
				<Text weight={FontWeights.Regular}>
					{formatMessage(faq.children, {
						...faq.placeholders,
						br: (
							<>
								<br />
								<br />
							</>
						),
						site: children => (
							<a target="_blank" href={`${getUrl('www')}/subscribe`} rel="noreferrer">
								{children}
							</a>
						),
						ul: children => <ul>{children}</ul>,
						li: children => <li>{children}</li>,
					})}
				</Text>
			),
		};
	}

	const getBrandingHeader = () => {
		if (showHeader) {
			return null;
		}

		return <Branding backgroundColor={activeScreenConfig?.backgroundKey} coBranded={coBranded} />;
	};

	const getValueListTitle = () => {
		if (!upsellHeader) return null;

		const { message, placeholders = {} } = upsellHeader({ prices, purchaseParams });

		return formatMessage(message, { ...placeholders, br: <br /> });
	};

	const getValueListSubTitle = () => {
		if (!upsellSubHeader) return null;

		const { message, placeholders = {} } = upsellSubHeader();

		return formatMessage(message, { ...placeholders, br: <br /> });
	};

	useEffect(() => {
		if (user && onAuthSuccess) {
			onAuthSuccess(experimentClient);
		}
	}, [user, experimentClient, onAuthSuccess]);

	return (
		<AccountPageWrap
			backgroundKey={backgroundKey}
			isWithinModal={isWithinModal}
			isCompact={isWithinModal || hideValueProps}
		>
			<TwoColumnWrapper isWithinModal={isWithinModal}>
				<AuthColumnWrapper>
					{((brandingPosition === 'right' && !isMobile) || (isMobile && !isWithinModal)) &&
						getBrandingHeader()}
					<SisuAuthForm />
				</AuthColumnWrapper>
				{!hideValueProps && (
					<ValuePropsListWrapper showHeader={showHeader} isWithinModal={isWithinModal}>
						{((brandingPosition === 'left' && !isMobile) || (isMobile && isWithinModal)) &&
							getBrandingHeader()}
						{contentBasedImage && <ContentBasedImage {...contentBasedImage} />}
						<AccountValuePropsList
							isOrdered={isValuePropsListOrdered}
							titleSize={valuePropsTitleSize}
							titleColor={valuePropsTitleColor}
							propSize={FontSizes.sm}
							copy={!(isMobile && isWithinModal) ? upsellBullets : undefined}
							title={getValueListTitle()}
							subTitle={getValueListSubTitle()}
							buttonColor={valuePropsButtonColor ?? 'buttonPurple'}
							textColor={valuePropsColor ?? 'black'}
							fontWeight={FontWeights.Medium}
							gapSize="20px"
						/>
					</ValuePropsListWrapper>
				)}
			</TwoColumnWrapper>
			{AdditionalContent && <AdditionalContent />}
			{FAQs && FAQs.length && (
				<FAQWrapper isCompact={hideValueProps}>
					<FAQContentWrapper>
						<div>
							<Text
								el="h2"
								align="center"
								size={FontSizes['2xl']}
								color="buttonBlue"
								weight={FontWeights.Demi}
							>
								{formatMessage(messages.faqHeading)}
							</Text>
						</div>
						<div>
							<HorizontalLine />
							{FAQs.map(faq => (
								<div key={faq.id}>
									<Collapsible {...getTrigger(faq)} />
									<HorizontalLine />
								</div>
							))}
						</div>
					</FAQContentWrapper>
				</FAQWrapper>
			)}
		</AccountPageWrap>
	);
};

export default Account;
