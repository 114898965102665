import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { FocusRingVariants } from '@calm-web/design-system';

import CreditCardInput from '@/components/purchase/CreditCardInput';
import CvcHelpModal from '@/components/purchase/CvcHelpModal';

import purchaseMessages from '../messages';
import messages from './messages';
import { CvcWrapper, HelpButton, HelpIcon, RowWrapper } from './styles';
import { CreditCardFormInputsProps } from './types';

const CreditCardFormInputs = ({
	analyticsPrefix,
	setIsCCNumberValid,
	setIsCvcValid,
	setIsExpiryValid,
}: CreditCardFormInputsProps) => {
	const { formatMessage } = useIntl();

	const [isCvcModalShown, setIsCvcModalShown] = useState(false);

	return (
		<>
			<CreditCardInput
				placeholder={formatMessage(messages.cardNumber)}
				StripeElement={CardNumberElement}
				analyticsPrefix={`${analyticsPrefix} : Card Number`}
				setIsValid={setIsCCNumberValid}
			/>
			<RowWrapper>
				<CreditCardInput
					placeholder={formatMessage(messages.expiryShortPlaceholder)}
					StripeElement={CardExpiryElement}
					analyticsPrefix={`${analyticsPrefix} : Expiration`}
					setIsValid={setIsExpiryValid}
				/>
				<CvcWrapper>
					<CreditCardInput
						placeholder={formatMessage(messages.cvcShortPlaceholder)}
						StripeElement={CardCvcElement}
						analyticsPrefix={`${analyticsPrefix} : CVC`}
						setIsValid={setIsCvcValid}
					/>
					<HelpButton
						aria-label={formatMessage(purchaseMessages.cvvHelpModalTitle)}
						onPress={() => setIsCvcModalShown(true)}
						Icon={HelpIcon}
						focusRingVariant={FocusRingVariants.Dark}
					/>
				</CvcWrapper>
				<CvcHelpModal isCvcModalShown={isCvcModalShown} setIsCvcModalShown={setIsCvcModalShown} />
			</RowWrapper>
		</>
	);
};

export default CreditCardFormInputs;
