import React, { ReactElement, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';

import { ToastNotification as PresentationalToastNotification } from '@calm-web/design-system';

import { useNotificationState } from '@/hooks/store';
import { setNotification } from '@/store/notification/actions';

// This component expects there to be a maxmimum
// of one instance present on the page at a time.
const ToastNotification = (): ReactElement | null => {
	const notification = useNotificationState();
	const isVisible = Boolean(notification);

	const dispatch = useDispatch();

	const [isMounted, setIsMounted] = useState(false);
	const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);

	const onPress = () => {
		dispatch(setNotification(null));
	};

	useEffect(() => {
		if (isVisible) {
			const container = document.createElement('div');
			container.id = 'toast-notification-container';
			document.body.appendChild(container);
			setPortalContainer(container);
			setIsMounted(true);

			return () => {
				setIsMounted(false);
				container.remove();
				setPortalContainer(null);
			};
		}
	}, [isVisible]);

	// After 5 seconds, remove notification
	useEffect(() => {
		const timeout = notification
			? setTimeout(() => {
					dispatch(setNotification(null));
			  }, 5000)
			: undefined;

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [dispatch, notification]);

	useEffect(() => {
		return () => {
			dispatch(setNotification(null));
		};
	}, [dispatch]);

	if (!isMounted || notification === null || !portalContainer) {
		return null;
	}

	return createPortal(
		<PresentationalToastNotification
			backgroundColor={notification.backgroundColor}
			iconColor={notification.iconColor}
			fromTop={notification.position === 'top'}
			isVisible={isVisible}
			onPress={onPress}
		>
			{notification.children ?? notification.message}
		</PresentationalToastNotification>,
		portalContainer,
	);
};

export default ToastNotification;
