import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { CalmTheme } from '@calm-web/design-system';

import messages from '@/components/purchase/PlanSkuToggle/messages';
import { useAnalytics } from '@/hooks/analytics';
import { Device } from '@/store/device/types';
import { Coupon, Prices } from '@/store/prices/types';
import { isValidPlan, PlanCadence } from '@/store/purchaseParams/types';
import { AmplitudeExperimentNames } from '@/utils/experiments/amplitudeExperiment';
import { initGiftPricing } from '@/utils/getInitialState/intiGiftPricing';
import isUSPricing from '@/utils/prices/isUSPricing';

import { useDeviceState, usePricesState, usePurchaseParamsState } from '../store';
import { useAmplitudeExperiment } from '../utils/experiments/useAmplitudeExperiment';
import { useFormattedCurrency } from './useFormattedCurrency';
import { useFreeTrial } from './useFreeTrial';

export type PlanSku = {
	duration: string;
	id: 'monthly' | 'yearly' | 'family' | 'quarterly';
	originalPrice?: number | null;
	price: number;
	overridePriceDisplay?: string | null;
	pricePerMonth?: number;
	active: boolean;
	dueToday?: number;
	trialLength?: number;
	subheading?: string;
	numPeople?: string;
	badge?: string;
	badgeBg?: keyof typeof CalmTheme.palette;
	currency?: string;
};

type UsePlanSkuToggleArgs = {
	isGift?: boolean;
};

export const getYearlyOriginalPrice = (prices: Prices, device: Device) => {
	if (isUSPricing(prices, device)) {
		return prices?.current?.yearly === prices?.original?.yearly
			? prices?.current?.monthly * 12
			: prices?.original?.yearly;
	}

	return prices?.original?.yearly;
};

export const usePlanSkuToggle = (props?: UsePlanSkuToggleArgs) => {
	const { query } = useRouter();
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const formatCurrency = useFormattedCurrency();
	const purchaseParams = usePurchaseParamsState();
	const { isEnrolled: isFamilyPlanTestEnrolled, isLoading: isFamilyPlanTestLoading } = useAmplitudeExperiment(
		AmplitudeExperimentNames.FAMILY_PLAN_TOGGLE,
	);

	const isGift = props?.isGift;
	const [activePlan, _setActivePlan] = useState<PlanSku['id']>(
		typeof query.plan === 'string' && ['yearly', 'monthly', 'family'].includes(query.plan)
			? (query.plan as PlanSku['id'])
			: 'yearly',
	);

	const setActivePlan = useCallback(
		(id: PlanSku['id']) => {
			logEvent({
				eventName: 'Subscription Plan : Selected',
				eventProps: {
					plan_id: id,
				},
			});
			_setActivePlan(id);
		},
		[logEvent],
	);

	const prices = usePricesState();
	const device = useDeviceState();
	const userCanTrial = useFreeTrial();
	const getCouponByPlan = (plan: PlanCadence): Coupon | undefined =>
		isValidPlan(plan) ? prices?.coupons?.[plan] : undefined;

	const getPlanBadge = (planId: PlanCadence, userCanTrial: boolean): string | undefined => {
		const coupon = getCouponByPlan(planId);

		if (coupon) {
			return formatMessage(messages.couponLabel, {
				amount: coupon?.percent_off,
			});
		}

		if (userCanTrial && planId === 'yearly') {
			const trialLength = purchaseParams.purchaseType?.duration;

			return trialLength
				? formatMessage(messages.customTrialLabel, {
						amount: trialLength,
				  })
				: formatMessage(messages.trialLabel);
		}
	};

	const annualMonthlyBreakdown = prices?.current?.yearly / 12;

	const skus: PlanSku[] = [
		{
			duration: 'Yearly',
			id: 'yearly',
			originalPrice: getYearlyOriginalPrice(prices, device),
			price: prices?.current?.yearly,
			pricePerMonth: annualMonthlyBreakdown,
			active: activePlan === 'yearly',
			dueToday: userCanTrial && !getCouponByPlan('yearly') ? 0 : prices?.current?.yearly,
			badge: getPlanBadge('yearly', userCanTrial),
		},
		{
			duration: 'Monthly',
			id: 'monthly',
			price: prices?.current?.monthly,
			pricePerMonth: prices?.current?.monthly,
			active: activePlan === 'monthly',
			dueToday: prices?.current?.monthly,
		},
	];

	const familySkus: PlanSku[] = [
		{
			duration: 'Individual',
			id: 'yearly',
			originalPrice: prices?.current?.yearly,
			price: prices?.current?.yearly,
			active: activePlan === 'yearly',
			subheading: formatMessage(messages.amountPerPersonIndividual, {
				amount: formatCurrency(prices?.current?.yearly),
			}),
			dueToday: userCanTrial && !getCouponByPlan('yearly') ? 0 : prices?.current?.yearly,
			numPeople: formatMessage(messages.numPeopleIndividual),
			badge: getPlanBadge('yearly', userCanTrial),
			badgeBg: 'accessibleBrandGradient',
		},
		{
			duration: 'Family',
			id: 'family',
			originalPrice: prices?.original?.family * 6,
			price: prices?.current?.family,
			active: activePlan === 'family',
			subheading: formatMessage(messages.amountPerPersonFamily, {
				amount: formatCurrency(prices?.current?.family / 6),
			}),
			numPeople: formatMessage(messages.numPeopleFamily),
			dueToday: prices?.current?.family,
			badge: formatMessage(messages.greatDealBadge),
			badgeBg: 'accentRainbow04',
		},
	];

	const giftSkus = (): PlanSku[] => {
		const skus: PlanSku[] = [];
		const annual = initGiftPricing('annual', typeof query.coupon === 'string' ? query.coupon : null);
		// TODO: Uncomment in the phase #2 of Gift Card Redesign project.
		// const quarter = initGiftPricing('quarter');

		// if (quarter) {
		// 	skus.push({
		// 		duration: formatMessage(messages.quarterDuration),
		// 		id: 'quarterly',
		// 		originalPrice: quarter.originalPrice,
		// 		price: quarter.price,
		// 		overridePriceDisplay: formatCurrency(quarter.price, 'USD'),
		// 		active: activePlan === 'quarterly',
		// 		dueToday: quarter.price,
		// 		badge: getPlanBadge('quarterly', false),
		// 		currency: quarter.currency,
		// 	});
		// }

		if (annual) {
			skus.push({
				duration: formatMessage(messages.yearDuration),
				id: 'yearly',
				originalPrice: annual?.originalPrice,
				overridePriceDisplay: formatCurrency(annual.price, 'USD'),
				price: annual.price,
				active: activePlan === 'yearly',
				dueToday: annual.price,
				badge: getPlanBadge('yearly', false),
				currency: annual.currency,
			});
		}

		return skus;
	};

	const skusToDisplay = () => {
		if (isGift) {
			return giftSkus();
		}

		if (isFamilyPlanTestEnrolled) {
			return familySkus;
		}

		const dontShowMonthlyPricing = !isUSPricing(prices, device) || query?.utm_medium === 'paid';

		if (dontShowMonthlyPricing) {
			return skus.filter(sku => sku.id !== 'monthly');
		}

		return skus;
	};

	return {
		activePlan,
		setActivePlan,
		skus: skusToDisplay(),
		isFamilyToggleTest: isFamilyPlanTestEnrolled,
		isLoading: isFamilyPlanTestLoading,
	};
};
