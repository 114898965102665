import { PropsWithChildren } from 'react';

import SkipToMain from '@/components/accessibility/SkipToMain';
import ClientProviders from '@/components/layout/ClientProviders';
import Devtools from '@/components/layout/Devtools';
import LayoutScripts from '@/components/layout/LayoutScripts';
import RecaptchaModal from '@/components/layout/RecaptchaModal';
import ToastNotification from '@/components/ui/ToastNotification';

const PageWrapper = ({
	customMainId,
	hideAcceptCookiesModal,
	children,
}: PropsWithChildren<{ customMainId?: string; hideAcceptCookiesModal?: boolean }>) => {
	return (
		<ClientProviders hideAcceptCookiesModal={hideAcceptCookiesModal}>
			<LayoutScripts />
			<SkipToMain href={customMainId} />
			{children}
			<Devtools />
			<RecaptchaModal />
			<ToastNotification />
		</ClientProviders>
	);
};

export default PageWrapper;
