import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import AuthTerms from '@/components/authForm/AuthTermsRefresh';
import partnerMessages from '@/components/SimplifiedSignup/configs/Partnerships/messages';
import { usePartnerState } from '@/hooks/store';
import genericMessages from '@/messages/messages';

import { FooterText } from './styles';

const PartnerSpecificAuthTerms = ({
	terms,
	requiresDataSharing,
}: {
	terms?: string;
	requiresDataSharing?: boolean;
}) => {
	const { formatMessage } = useIntl();
	const partner = usePartnerState();
	const isAARP = partner?.name.toLowerCase() === 'aarp';

	if (requiresDataSharing) {
		return (
			<FooterText data-testid="requires-data-sharing-terms">
				{formatMessage(genericMessages.loginTermsDataSharing, {
					terms: (
						<a href="/terms" target="_blank" rel="noopener noreferrer">
							{formatMessage(genericMessages.terms)}
						</a>
					),
					privacypolicylink: chunks => (
						<a href="/privacy" target="_blank" rel="noopener noreferrer">
							{chunks}
						</a>
					),
				})}
			</FooterText>
		);
	}

	return (
		<>
			{isAARP && (
				<>
					<br />
					<FooterText>
						{formatMessage(partnerMessages.aarp_membership, {
							join: (...chunks: ReactNode[]) => (
								<a
									href="https://appsec.aarp.org/mem/join?campaignId=FFPUCALM&cmp=ASI_P_MU_JN_CALM"
									target="_blank"
									rel="noreferrer"
								>
									{chunks}
								</a>
							),
							renew: (...chunks: ReactNode[]) => (
								<a
									href="https://appsec.aarp.org/mem/renew?campaignId=FFPUCALM&cmp=ASI_P_MU_RN_CALM"
									target="_blank"
									rel="noreferrer"
								>
									{chunks}
								</a>
							),
						})}
					</FooterText>
					<br />
				</>
			)}
			<br />
			<AuthTerms loginTermsText={terms} signupTermsText={terms as string} />
		</>
	);
};

export default PartnerSpecificAuthTerms;
