import { useRouter } from 'next/router';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { PacksContext } from '@/components/app/layout/PacksProvider';
import { useAnalytics } from '@/hooks/analytics';
//Share Hooks
import { useShareCopy } from '@/hooks/share/useShareCopy';
import { useShareRequestToken } from '@/hooks/share/useShareRequestToken';
import { useShareVerifyToken } from '@/hooks/share/useShareVerifyToken';
import { useUserState } from '@/hooks/store';
import { userCanTrial } from '@/utils/subscriptions';

import { ShareContextProps, ShareLoadingElement } from './types';

export const ShareContext = createContext<ShareContextProps>({
	isShareContentRecipient: false,
	isFetchingShare: false,
	shareSenderFirstName: null,
	cta: '',
	heading: '',
	subheading: '',
	sidebarCta: '',
	isFetchingShareRequest: false,
	shareUrl: null,
	shareToken: null,
	senderShareToken: null,
	shouldShowSharePayment: false,
	shouldShowPrimer: false,
	shouldShowShareHandoff: false,
	onPurchaseSuccess: () => {},
	onShowSharePayment: (shareElementClicked: ShareLoadingElement) => Promise.resolve(),
	onUpsellExit: () => {},
	dismissHandoff: () => {},
	setPrimerVisible: () => {},
});

export const useShareContext = () => {
	const value = useContext(ShareContext);
	return value;
};

const ShareProvider = ({ children }: { children?: ReactNode }) => {
	const { logEvent } = useAnalytics();
	const { query } = useRouter();
	const user = useUserState();
	const shareToken = query.share_token as string | undefined;
	const { currentGuide } = useContext(PacksContext);
	const { isShareContentRecipient, isFetchingShare, shareSenderFirstName } = useShareVerifyToken(
		currentGuide?.id,
		shareToken,
	);
	const { cta, heading, subheading, sidebarCta } = useShareCopy(shareSenderFirstName);

	const [shouldShowPrimer, setShouldShowPrimer] = useState(
		isShareContentRecipient && !user?.subscription?.valid,
	);
	const [shouldShowSharePayment, setShowSharePayment] = useState(false);
	const [shouldShowShareHandoff, setShowShareHandoff] = useState(false);
	const { isFetchingShareRequest, shareUrl, senderShareToken } = useShareRequestToken(currentGuide?.id);
	const [primerDismissed, setPrimerDismissed] = useState(false);

	const onShowSharePayment = useCallback(
		async (shareElementPressed?: ShareLoadingElement) => {
			const isEligible = userCanTrial(user);
			logEvent({
				eventName: 'Web App : CTA : Clicked',
				eventProps: {
					type: 'button',
					copy: isEligible ? 'ELIGIBLE' : 'INELIGIBLE',
					button: shareElementPressed ?? null,
				},
			});
			setShowSharePayment(true);
		},
		[user, logEvent],
	);

	const onUpsellExit = useCallback(() => {
		logEvent({
			eventName: 'Web App : Upsell : Closed',
			eventProps: {
				type: 'button',
			},
		});
		setShowSharePayment(false);
	}, [setShowSharePayment, logEvent]);

	const onPurchaseSuccess = useCallback(() => {
		setShowShareHandoff(true);
		logEvent({
			eventName: 'Web App : Share : Purchased',
			eventProps: {
				type: 'button',
			},
		});
		setShowSharePayment(false);
	}, [setShowSharePayment, setShowShareHandoff, logEvent]);

	const dismissHandoff = useCallback(() => {
		logEvent({
			eventName: 'Web App : Share : Closed Handoff',
			eventProps: {
				type: 'button',
			},
		});
		setShowShareHandoff(false);
	}, [setShowShareHandoff, logEvent]);

	const setPrimerVisible = useCallback(
		(x?: boolean) => {
			if (primerDismissed) {
				setShouldShowPrimer(false);
				return;
			}
			if (x === false) {
				setPrimerDismissed(true);
				setShouldShowPrimer(false);
				return;
			}
			setShouldShowPrimer(isShareContentRecipient && !user?.subscription?.valid);
		},
		[isShareContentRecipient, user, primerDismissed],
	);

	const value = useMemo(
		() => ({
			isShareContentRecipient,
			isFetchingShare,
			shareSenderFirstName,
			cta,
			shareToken,
			heading,
			subheading,
			sidebarCta,
			isFetchingShareRequest,
			shareUrl,
			senderShareToken,
			shouldShowSharePayment,
			shouldShowPrimer,
			shouldShowShareHandoff,
			onShowSharePayment,
			onUpsellExit,
			dismissHandoff,
			onPurchaseSuccess,
			setPrimerVisible,
		}),
		[
			isShareContentRecipient,
			isFetchingShare,
			shareSenderFirstName,
			shareToken,
			cta,
			heading,
			subheading,
			sidebarCta,
			isFetchingShareRequest,
			shareUrl,
			senderShareToken,
			shouldShowSharePayment,
			shouldShowPrimer,
			shouldShowShareHandoff,
			onShowSharePayment,
			onUpsellExit,
			dismissHandoff,
			onPurchaseSuccess,
			setPrimerVisible,
		],
	);

	return <ShareContext.Provider value={value}>{children}</ShareContext.Provider>;
};

export default ShareProvider;
