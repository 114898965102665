import { IncomingMessage } from 'http';

import { Prices } from '@/store/prices/types';
import apiRequest from '@/utils/apiRequest';
import { userDataFromBrowser } from '@/utils/apiRequest/userDataFromBrowser';
import { userDataFromRequest } from '@/utils/apiRequest/userDataFromRequest';
import { calmLogger } from '@/utils/calmLogger';

import { PriceCatalogResponse } from '../../utils/prices/mergeCatalogPriceIntoPrices';

export const FAMILY_PLAN_YEARLY_PLAN_ID = 'family_plan_1y_autorenew';
const FAMILY_PRICING_ENDPOINT = `stripe-price-catalog/price?quantity=1&plan=${FAMILY_PLAN_YEARLY_PLAN_ID}`;

export async function getFamilyPrices(
	req?: IncomingMessage,
	deviceId?: string,
	calmIdentifier?: string | undefined,
): Promise<PriceCatalogResponse | null> {
	const isServer = req && deviceId;

	try {
		const response = await apiRequest<PriceCatalogResponse>({
			endpoint: FAMILY_PRICING_ENDPOINT,
			method: 'GET',
			userData: isServer ? userDataFromRequest({ req, deviceId, calmIdentifier }) : userDataFromBrowser(),
		});
		return response?.data || null;
	} catch (err) {
		calmLogger.error(`Error retrieving family prices via GET /${FAMILY_PRICING_ENDPOINT}`, {}, err);
		return null;
	}
}

export const isFamilyPricingAvailable = (prices?: Prices) => {
	return Boolean(prices?.current?.family);
};
