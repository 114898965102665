import { announce } from '@react-aria/live-announcer';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useAuthContext } from '@/context/auth/AuthContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useAuthFormModeState, useThemeState } from '@/hooks/store';
import { setAuthFormMode } from '@/store/authFormMode/actions';
import { setUser } from '@/store/user/actions';
import useLogoutofTeams from '@/teams-calm/src/hooks/logoutOfTeams';
import { useGetIsTeamsApp } from '@/utils/app/msftTeams';
import { removeCookie } from '@/utils/cookies';
import getUrl from '@/utils/getUrl';
import { CALM_ID, CALM_IS_HIPAA_USER, CALM_USER_ID, CALM_USER_TOKEN } from '@/utils/privacyCookies';

const messages = defineMessages({
	loggedOutAnnouncement: {
		id: 'login.loggedOutAnnouncement',
		defaultMessage: 'You have successfully logged out',
		description: 'Notification to screen reader that user has logged out',
	},
});

export function useLoginForm(): {
	onLogout: () => void;
	onModeToggle: () => void;
} {
	const dispatch = useDispatch();
	const theme = useThemeState();
	const [logUserOut] = useLogoutofTeams();
	const authFormMode = useAuthFormModeState();
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const { isTeamsApp } = useGetIsTeamsApp();
	const { setIsAuthenticating } = useAuthContext();

	function handleLogout(): void {
		if (theme.isTeamsApp || isTeamsApp) {
			logUserOut();
		}
		removeCookie(CALM_USER_TOKEN);
		removeCookie(CALM_USER_ID);
		removeCookie(CALM_ID);
		removeCookie(CALM_IS_HIPAA_USER);
		dispatch(setUser(null));
		announce(formatMessage(messages.loggedOutAnnouncement));
	}

	function onLogout(): void {
		setIsAuthenticating(true);
		handleLogout();
		window.location.href = `${getUrl('web-api')}/auth/logout`;
	}

	function onModeToggle(): void {
		const newMode = authFormMode === 'signup' ? 'login' : 'signup';

		logEvent({
			eventName: 'Login Form : Mode : Toggled',
			eventProps: {
				mode: newMode,
			},
		});
		dispatch(setAuthFormMode(newMode));
	}

	return { onLogout, onModeToggle };
}
