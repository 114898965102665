import { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';

import useQueryParams from '@/hooks/utils/useQueryParams';
import {
	getDevice,
	getDeviceConsentAndCheckForServerMaintenance,
	getPrices,
	getUser,
} from '@/server/initState';
import { getFeatureFlags } from '@/server/initState/getFeatureFlags';
import { setAppLoaded } from '@/store/appLoaded/actions';
import { setBrowserLanguage } from '@/store/browserLanguage/actions';
import { setCampaign } from '@/store/campaign/actions';
import { Campaign } from '@/store/campaign/types';
import { setComplianceLevel } from '@/store/complianceLevel/actions';
import { setDevice } from '@/store/device/actions';
import { setFeatureFlags } from '@/store/feature-flags/actions';
import { FeatureFlags } from '@/store/feature-flags/types';
import { setLocale } from '@/store/locale/actions';
import type { Locale } from '@/store/locale/types';
import { setPrices } from '@/store/prices/actions';
import { setPurchaseParams } from '@/store/purchaseParams/actions';
import { setUser } from '@/store/user/actions';
import { calmLogger } from '@/utils/calmLogger';
import { getCookie } from '@/utils/cookies';
import { postDevice } from '@/utils/endpoints';
import { initPurchaseParams } from '@/utils/getInitialState';
import { determineComplianceLevel } from '@/utils/getInitialState/initComplianceLevel';
import {
	ACCEPT_GDPR_COOKIES_KEY,
	CALM_IS_HIPAA_USER,
	CALM_LIMITED_DATA_USE,
	DECLINE_GDPR_COOKIES_KEY,
} from '@/utils/privacyCookies';

import { useAppLoadedState } from './useAppState';

export enum InitRequest {
	DEVICE = 'device',
	USER = 'user',
	PRICES = 'prices',
}

const isAllowedReq = (requests: InitRequest[], route: InitRequest): boolean => requests.includes(route);

interface UseInitStateArgs {
	requests?: InitRequest[];
	awaitPostDevice?: boolean;
	language?: Locale;
	campaign?: Campaign;
}

interface UseInitState {
	(args?: UseInitStateArgs): void;
}

export const useInitState: UseInitState = ({
	requests = [],
	awaitPostDevice = false,
	language,
	campaign,
} = {}) => {
	const appLoaded = useAppLoadedState();
	const dispatch = useDispatch();
	const query = useQueryParams();

	const initState = async (): Promise<void> => {
		if (awaitPostDevice) {
			await postDevice();
		} else {
			postDevice().catch(error => calmLogger.error('Error in useInitState postDevice', {}, error));
		}

		const ua = window?.navigator?.userAgent;
		const deviceId = getCookie('x-device-id');

		const [deviceConsent, featureFlags, prices, device, user] = await Promise.all([
			getDeviceConsentAndCheckForServerMaintenance(getCookie(ACCEPT_GDPR_COOKIES_KEY)),
			getFeatureFlags(),
			isAllowedReq(requests, InitRequest.PRICES) ? getPrices(query) : null,
			isAllowedReq(requests, InitRequest.DEVICE) ? getDevice(ua, undefined, deviceId) : null,
			isAllowedReq(requests, InitRequest.USER) ? getUser() : null,
		]);

		const isLimitedData = Boolean(getCookie(CALM_LIMITED_DATA_USE));
		const hasDeclinedGdprCookies = Boolean(getCookie(DECLINE_GDPR_COOKIES_KEY));
		const isHipaaUser = Boolean(getCookie(CALM_IS_HIPAA_USER));

		const complianceLevel = determineComplianceLevel({
			requiresGdpr: deviceConsent.requires_gdpr,
			isLimitedData,
			hasDeclinedGdprCookies,
			isHipaaUser,
		});

		const deviceWithIP = {
			...device,
			requires_gdpr_choice: deviceConsent.requires_gdpr_choice,
			ip_country: deviceConsent.ip_country,
		};

		function parseFeatureFlags(): FeatureFlags {
			return (
				featureFlags?.feature_flags.reduce<FeatureFlags>(
					(acc, apiFlag) => ({
						...acc,
						[apiFlag.name]: apiFlag.value,
					}),
					{},
				) ?? {}
			);
		}

		batch(() => {
			if (deviceWithIP) dispatch(setDevice(deviceWithIP));
			const lang = language || device?.language;
			if (lang) dispatch(setLocale(lang));
			if (prices) {
				const purchaseParams = initPurchaseParams({
					query,
					prices,
					user,
				});
				dispatch(setPrices(prices));
				dispatch(setPurchaseParams(purchaseParams));
			}
			if (campaign) {
				dispatch(setCampaign(campaign));
			}

			// note that user can be null
			if (isAllowedReq(requests, InitRequest.USER)) {
				// Don't set user unless we have a reason to do so
				dispatch(setUser(user));
			}
			dispatch(setComplianceLevel(complianceLevel));
			dispatch(setFeatureFlags(parseFeatureFlags()));
			dispatch(setBrowserLanguage(window.navigator.language));
			dispatch(setAppLoaded(true));
		});
	};

	useEffect(
		() => {
			if (!appLoaded) {
				initState().catch(error => calmLogger.error('Error in useInitState initState', {}, error));
			}
		}, // TODO [WEB-1595]: Fix this exhaustive-deps issue
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);
};
