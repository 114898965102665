import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, PrimaryButton, Text } from '@calm-web/design-system';

import { useSimplifiedSignupContext } from '@/components/SimplifiedSignup/Context';
import { useAnalytics } from '@/hooks/analytics';
import { useRouterPush } from '@/hooks/utils/useRouterPush';

import { Copy, Wrapper } from './styles';

const Error = () => {
	const { formatMessage } = useIntl();
	const routerPush = useRouterPush();
	const { error } = useSimplifiedSignupContext();
	const { logEvent } = useAnalytics();
	const [isRouting, setIsRouting] = useState(false);

	useEffect(() => {
		error?.onScreenViewed?.(logEvent);
	}, [error, logEvent]);

	if (!error) {
		return null;
	}
	const { heading, subheading, cta } = error;

	const onPress = () => {
		setIsRouting(true);
		cta.onClick(routerPush, logEvent);
	};

	return (
		<Wrapper>
			<Copy>
				<Text noMargin el="h1" weight={FontWeights.Demi} size={FontSizes['2xl']}>
					{formatMessage(heading)}
				</Text>
				<Text noMargin el="h2" weight={FontWeights.Regular} size={FontSizes.base}>
					{formatMessage(subheading)}
				</Text>
			</Copy>
			<PrimaryButton isLoading={isRouting} onPress={onPress}>
				{formatMessage(cta.label)}
			</PrimaryButton>
		</Wrapper>
	);
};

export default Error;
