import { useRouter } from 'next/router';
import qs from 'query-string';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { CalmLogo, CalmLogoColor } from '@calm-web/design-system';

import { OfferBannerContext } from '@/components/app/layout/OfferBannerProvider';
import AuthModal from '@/components/authForm/AuthModal';
import { getPodcastRedirectUrl } from '@/components/campaign';
import DesktopNav from '@/components/header/DesktopNav';
import MobileNav from '@/components/header/MobileNav';
import AuthModalContextProvider from '@/context/AuthModalContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { ApiRequest, useApi } from '@/hooks/api';
import { useCoupon } from '@/hooks/purchase';
import { useAuthFormModeState, usePricesState, useThemeState, useUserState } from '@/hooks/store';
import { usePinnedHeader } from '@/hooks/ui/usePinnedHeader';
import { useFeatureFlags } from '@/hooks/utils/useFeatureFlags';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { Device } from '@/store/device/types';
import { FeatureFlags } from '@/store/feature-flags/types';
import { setTheme } from '@/store/theme/actions';
import { User } from '@/store/user/types';
import redirect from '@/utils/legacy/redirect';
import isValidLifetimeCoupon from '@/utils/prices/isValidLifetimeCoupon';
import { userCanTrial } from '@/utils/subscriptions';

import { SUPPORT_LINK } from './constants';
import { loggedInNavItems } from './data';
import messages from './messages';
import { ExitIcon, ExitIconWrapper, GradientBg, HeaderGridWrapper, Navigation, Wrapper } from './styles';
import { HeaderProps } from './types';

interface DeviceResponse {
	data: Device;
}

const shouldCheckTermsStatus = (user: User, flagValues: FeatureFlags) => {
	const b2b_details = user.subscription?.b2b_details;
	const isB2BTermsEnabled = flagValues['b2b-terms-and-conditions'];
	const shouldCheckTerms = isB2BTermsEnabled && b2b_details?.partner_slug === 'kp';
	return shouldCheckTerms;
};

const shouldShowToS = async (user: User, apiRequest: ApiRequest) => {
	const token = user?.token;

	try {
		if (token) {
			const result: DeviceResponse = await apiRequest({
				endpoint: 'device',
				method: 'GET',
				customHeaders: { 'x-session-token': token },
			});

			const hasToSCta = result?.data?.call_to_action?.name === 'b2b_tos';
			return hasToSCta;
		}
	} catch (error) {
		// eslint-disable-next-line
		console.log('Header -> shouldShowToS -> device -> error', error);
	}
	return false;
};

/**
 *
 * Displays a Nav bar with various i18n links in it
 *
 */
const Header = ({
	hideMenu,
	isStatic,
	hideProfile,
	onExit,
	initialTheme,
	className,
	hideLogo = false,
	useContactButton,
	logoColor,
	isForSchools,
	isDisplayBlock = false,
	alignLeft = false,
	hideFreeTrialCta = false,
	hideForBusinessCta = false,
	hideAuthCTA = false,
	hideHambugerNav = false,
	onFreeCtaClick,
	freeCTAColorText,
	freeCTAColorBG,
}: HeaderProps) => {
	const isPinned = usePinnedHeader();
	const authFormMode = useAuthFormModeState();
	const theme = useThemeState();
	const user = useUserState();
	const prices = usePricesState();
	const { isCoupon } = useCoupon();
	const { logEvent } = useAnalytics();
	const { query } = useRouter();
	const dispatch = useDispatch();
	const { flagValues } = useFeatureFlags('b2b-terms-and-conditions');
	const { isBannerVisible } = useContext(OfferBannerContext);
	const { formatMessage } = useIntl();
	const routerPush = useRouterPush();
	const apiRequest = useApi();

	const queryString = Object.keys(query).length ? `?${qs.stringify(query)}` : '';

	const forBusinessHref = `https://health.calm.com/${isForSchools ? 'employers/' : ''}${
		queryString ? `${queryString}&` : '?'
	}utm_campaign=homepage_for_business`;

	const onForBusinessClick = (): void => {
		logEvent({ eventName: 'Homepage : NavHeader : For Business : Clicked' });
	};

	const onNavLinkClick = (href: string): void => {
		logEvent({
			eventName: 'Homepage : NavHeader Links Clicked',
			eventProps: {
				link_url: href,
				is_pinned: isPinned,
			},
		});
	};

	const _onFreeCtaClick = () => {
		logEvent({
			eventName: 'Homepage : NavHeader : Try Calm for Free : Clicked',
		});

		if (onFreeCtaClick) {
			logEvent({
				eventName: 'Landing Page : Signup Flow CTA : Clicked',
				eventProps: {
					event_location: 'Header',
					event_action: 'Skip to End',
				},
			});

			return onFreeCtaClick();
		}

		if (!userCanTrial(user)) {
			return routerPush('/app');
		}

		if (isCoupon) {
			return routerPush('/subscribe');
		}

		// Send to signup-flow
		logEvent({
			eventName: 'Landing Page : Signup Flow CTA : Clicked',
		});

		return routerPush('/signup-flow', {
			source: 'homepage',
		});
	};

	const contactUsHref = `${SUPPORT_LINK}${queryString}`;
	const onContactUsClick = (): void => {
		logEvent({
			eventName: 'Homepage : NavHeader : Contact Us : Clicked',
		});
	};

	const logoColorEnum =
		logoColor === 'gradient' || theme.header === 'light' ? CalmLogoColor.Gradient : CalmLogoColor.White;

	const isPodcastFlow = query.utm_medium === 'podcast';

	const onAuthSuccess = async (_user: User): Promise<void> => {
		if (isPodcastFlow) {
			const hasLifeTimeUpsellCoupon = isValidLifetimeCoupon(prices);
			const podcastRedirectUrl = getPodcastRedirectUrl(user, prices, hasLifeTimeUpsellCoupon);

			redirect(podcastRedirectUrl);
		} else if (authFormMode === 'signup' && userCanTrial(_user)) {
			redirect('/signup-flow');
		} else if (shouldCheckTermsStatus(_user, flagValues)) {
			const showToS = await shouldShowToS(_user, apiRequest);
			redirect(showToS ? '/b2b/tos' : '/app');
		} else {
			redirect('/app');
		}
	};

	useEffect(
		() => {
			const headerTheme = initialTheme ?? (isStatic || isPinned ? 'light' : 'dark');

			dispatch(
				setTheme({
					...theme,
					header: headerTheme,
				}),
			);
		}, // TODO [WEB-1595]: Fix this exhaustive-deps issue
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isStatic, isPinned],
	);

	const isDark = theme.header === 'dark';

	return (
		<AuthModalContextProvider>
			<Wrapper
				isDisplayBlock={isDisplayBlock}
				isBannerVisible={isBannerVisible}
				isStatic={isStatic}
				isDark={isDark}
				className={className}
			>
				{!isStatic && <GradientBg />}
				<HeaderGridWrapper alignLeft={alignLeft}>
					<Navigation>
						{!hideLogo && <CalmLogo height={30} color={logoColorEnum} />}
						<DesktopNav
							onNavLinkClick={onNavLinkClick}
							isDark={isDark}
							loggedInNavItems={loggedInNavItems}
							onFreeCtaClick={_onFreeCtaClick}
							query={queryString}
							forBusinessHref={forBusinessHref}
							onForBusinessClick={onForBusinessClick}
							hideMenu={hideMenu}
							hideProfile={hideProfile}
							useContactButton={useContactButton}
							contactUsHref={contactUsHref}
							onContactUsClick={onContactUsClick}
							hideFreeTrialCta={isPodcastFlow || hideFreeTrialCta}
							hideForBusinessCta={isPodcastFlow || hideForBusinessCta}
							hideAuthCTA={hideAuthCTA}
						/>
						<MobileNav
							freeCTAColorBG={freeCTAColorBG}
							freeCTAColorText={freeCTAColorText}
							onNavLinkClick={onNavLinkClick}
							isDark={isDark}
							loggedInNavItems={loggedInNavItems}
							onFreeCtaClick={_onFreeCtaClick}
							query={queryString}
							hideMenu={hideMenu}
							forBusinessHref={forBusinessHref}
							onForBusinessClick={onForBusinessClick}
							useContactButton={useContactButton}
							contactUsHref={contactUsHref}
							onContactUsClick={onContactUsClick}
							hideFreeTrialCta={isPodcastFlow || hideFreeTrialCta}
							hideForBusinessCta={isPodcastFlow || hideForBusinessCta}
							hideAuthCTA={hideAuthCTA}
							hideHambugerNav={hideHambugerNav}
						/>
						{onExit && (
							<ExitIconWrapper>
								<ExitIcon onPress={onExit} $isDark={isDark} aria-label={formatMessage(messages.ariaLabel)} />
							</ExitIconWrapper>
						)}
					</Navigation>
				</HeaderGridWrapper>
				<AuthModal onAuthSuccess={onAuthSuccess} />
			</Wrapper>
		</AuthModalContextProvider>
	);
};

export default Header;
