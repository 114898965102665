export type SubscriptionType =
	| 'freetrial'
	| 'monthly'
	| 'quarterly'
	| 'lifetime'
	| 'family'
	| 'subscribe'
	| 'already-trialing'
	| 'already-subscribed'
	| 'previously-trialed';

const planSkuIds = ['monthly', 'quarterly', 'yearly', 'lifetime', 'family'] as const;
export type PlanCadence = (typeof planSkuIds)[number];
export const isValidPlan = (value: string): value is PlanCadence => planSkuIds.includes(value as PlanCadence);

export interface PurchaseType {
	type: SubscriptionType;
	modifier?: string;
	duration?: number;
	units?: string | null;
	price?: number;
	plan?: PlanCadence;
	isFreeTrial?: boolean;
	promotion?: string;
}

export type GiftPrice = null | {
	originalPrice: number;
	price: number;
	currency?: string;
};

export interface PurchaseParams {
	plan: PlanCadence;
	coupon?: string | null;
	promotion?: string | null;
	samsungGUID?: string | null;
	guestPass?: string | null;
	calmjwt?: string | null;
	is30DayOverride?: boolean;
	purchaseType?: PurchaseType | null;
	giftPrice?: GiftPrice;
	isUpdateBillingScreen?: boolean;
	trialLengthOverride?: number | null;
}

export type RouteSpecificPurchaseParams = Partial<PurchaseParams> & {
	freeTrialLength?: number | null;
	hasFreeTrial?: boolean | null;
	freeTrialLengthUnit?: string | null;
};

export const SET_PURCHASE_PARAMS = 'SET_PURCHASE_PARAMS';

export interface SetPurchaseParamsAction {
	type: typeof SET_PURCHASE_PARAMS;
	payload: PurchaseParams;
}
