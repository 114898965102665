// Add data types to window.navigator for use in this file. See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html#-reference-types- for more info.
/// <reference types="user-agent-data-types" />

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { calmLogger } from '../calmLogger';

const IS_TEAMS_APP = 'true';
export const TEAMS_CALM_APP_PATH = '/teams-calm';

export function isTeamsPath(path: string): boolean {
	return path.includes(TEAMS_CALM_APP_PATH);
}

const TEAMS_APP_REFERER = 'teams.microsoft';
const NEW_TEAMS_APP_REFERER = 'teams.cloud.microsoft';
export function isTeamsReferer(referer: string): boolean {
	return referer.includes(TEAMS_APP_REFERER) || referer.includes(NEW_TEAMS_APP_REFERER);
}

const isTeamsNative = (): boolean => {
	if (typeof window === 'undefined') return false;

	const isTeamsNativeAppUserAgent = /Teams\//.test(navigator.userAgent);

	const isTeamsBrandNavigator = navigator?.userAgentData?.brands.some(
		item => item.brand === 'Microsoft Edge WebView2',
	);

	return isTeamsNativeAppUserAgent || isTeamsBrandNavigator || false;
};

export function isTeams(): boolean {
	if (typeof window === 'undefined') return false;

	const isNative = isTeamsNative();
	const isTeamsApp = window.sessionStorage.getItem('isTeamsApp') === IS_TEAMS_APP;

	return isNative || isTeamsApp;
}

export function setExpiringCalmTokenForTeams(token: string) {
	window.sessionStorage.setItem('hasSetExpiringToken', 'true');
	window.sessionStorage.setItem('expiringCalmToken', token);
}

export function hasSetExpiringCalmTokenForTeams(): boolean {
	return window.sessionStorage.getItem('hasSetExpiringToken') === 'true';
}

export function clearExpiringCalmTokenForTeams() {
	window.sessionStorage.removeItem('hasSetExpiringToken');
	window.sessionStorage.removeItem('expiringCalmToken');
}

export function expiringCalmTokenForTeams(): string | null {
	return window.sessionStorage.getItem('expiringCalmToken');
}

export function useGetIsTeamsApp() {
	const [isTeamsApp, setIsTeamsApp] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (typeof window !== 'undefined' && window.sessionStorage) {
			setIsTeamsApp(isTeams());
		}
		setIsLoading(false);
	}, []);

	return { isTeamsApp, isLoading };
}

export function useSetIsTeamsApp() {
	const router = useRouter();

	useEffect(() => {
		try {
			if (typeof window !== 'undefined' && window.sessionStorage) {
				/* Removing the iframe check here because Teams mobile does not have iframes
					 We have two checks to rely on:
					 1. Only time this util is called is inside of the teams AppWrapper, so anything wrapped in AppWrapper is a teams page
					 2. We check the path uses /teams-calm

					 We can decide to add a third check by calling this util only in MsftTeamsApp function when SDK is loaded if we see that the above two checks are not enough
				*/
				const isTeamsApp = isTeamsPath(router.asPath);
				if (isTeamsApp) {
					window.sessionStorage.setItem('isTeamsApp', IS_TEAMS_APP);
				}
			}
		} catch (err) {
			calmLogger.error('Error in msftTeams useSetIsTeamsApp', {}, err);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

export function useIsTeamsNativeApp() {
	const [isTeamsNativeApp, setIsTeamsNativeApp] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		try {
			const newVal = isTeamsNative();
			setIsTeamsNativeApp(newVal);
		} finally {
			setIsLoading(false);
		}
	}, []);
	return { isTeamsNativeApp, isLoading };
}
