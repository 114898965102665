import styled from 'styled-components';

import { maxWidth, Modal } from '@calm-web/design-system';

export const SimplifiedSignupModalComponent = styled(Modal)`
	border: none;
	[role='dialog'] {
		max-height: 95vh;
	}

	${maxWidth('tablet')} {
		> div {
			border: none;
		}
	}
`;
