import { useIntl } from 'react-intl';

import { Modal } from '@calm-web/design-system';

import RecaptchaForm from '@/components/recaptcha/RecaptchaForm';
import recaptchaMessages from '@/components/recaptcha/RecaptchaForm/messages';
import { useRecaptchaVisibleState } from '@/hooks/store/useAppState';

const RecaptchaModal = () => {
	const { formatMessage } = useIntl();
	const recaptchaVisible = useRecaptchaVisibleState();

	if (!recaptchaVisible) {
		return null;
	}

	return (
		<Modal
			canClose={false}
			isOpen={recaptchaVisible}
			closeModal={(): null => null}
			aria-label={formatMessage(recaptchaMessages.title)}
		>
			<RecaptchaForm />
		</Modal>
	);
};

export default RecaptchaModal;
