/* eslint-disable max-len */

import { defineMessages } from 'react-intl';

export default defineMessages({
	purchaseTermsWithTrial_yearly: {
		id: 'b2b.payment.trial.terms1',
		defaultMessage:
			'By clicking “Start Free Trial”, you agree to be automatically billed for your first {quantity, plural, one {year} other {{quantity} years}} of Calm Premium at your offer rate of {offerPrice} when your trial period ends, which will then automatically renew at {originalPrice} every year until canceled.  {cancel}.',
		description: 'Purchase terms if the offer includes a trial',
	},
	purchaseTermsWithoutTrial_yearly: {
		id: 'b2b.payment.notrial.terms1',
		defaultMessage:
			'By clicking “Get Calm Premium”, you agree to be automatically billed for your first {quantity, plural, one {year} other {{quantity} years}} of Calm Premium at your offer rate of {offerPrice}, which will automatically renew at {originalPrice} every year until canceled.  {cancel}.',
		description: "Purchase terms if the offer doesn't include a trial",
	},
	purchaseTermsWithTrial_monthly: {
		id: 'b2b.payment.trial.monthly.terms2',
		defaultMessage:
			'By clicking “Start Free Trial”, you agree to be automatically billed for your first {quantity, plural, one {month} other {{quantity} months}} of Calm Premium at your offer rate of {offerPrice} when your trial period ends, which will then automatically renew at {originalPrice} every month until canceled.  {cancel}.',
		description: 'Purchase terms if the offer includes a trial',
	},
	purchaseTermsWithoutTrial_monthly: {
		id: 'b2b.payment.notrial.monthly.terms',
		defaultMessage:
			'By clicking “Get Calm Premium”, you agree to be automatically billed for your first {quantity, plural, one {month} other {{quantity} months}} of Calm Premium at your offer rate of {offerPrice}, which will automatically renew at {originalPrice} every month until canceled.  {cancel}.',
		description: "Purchase terms if the offer doesn't include a trial",
	},
	purchaseTermsWithTrial_quarterly: {
		id: 'b2b.payment.trial.quarterly.terms2',
		defaultMessage:
			'By clicking “Start Free Trial”, you agree to be automatically billed for your first {quantity, plural, one {quarter} other {{quantity} quarters}} of Calm Premium at your offer rate of {offerPrice} when your trial period ends, which will then automatically renew at {originalPrice} every quarter until canceled.  {cancel}.',
		description: 'Purchase terms if the offer includes a trial',
	},
	purchaseTermsWithoutTrial_quarterly: {
		id: 'b2b.payment.notrial.quarterly.terms',
		defaultMessage:
			'By clicking “Get Calm Premium”, you agree to be automatically billed for your first {quantity, plural, one {quarter} other {{quantity} quarters}} of Calm Premium at your offer rate of {offerPrice}, which will automatically renew at {originalPrice} every quarter until canceled.  {cancel}.',
		description: "Purchase terms if the offer doesn't include a trial",
	},
	cancelAnytime: {
		id: 'signup.premium.included.premium.cancelAnytime',
		defaultMessage: 'Cancel anytime',
		description: 'Cancel anytime bullet point',
	},
});
