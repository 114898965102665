import Device from '@/components/devtools/Device';
import User from '@/components/devtools/User';
import Loader from '@/components/Loader';
import AuthModalContextProvider from '@/context/AuthModalContextProvider';
import { InitRequest, useAppLoadedState, useInitState } from '@/hooks/store';

import { ExitIcon, ExitIconWrapper, Wrapper } from './styles';
import { DevToolsProps } from './types';

const DevToolsOverlay = ({ setIsDevToolsOpen }: DevToolsProps) => {
	const appLoaded = useAppLoadedState();

	useInitState({
		requests: [InitRequest.USER, InitRequest.DEVICE],
		awaitPostDevice: true,
	});

	const onExit = () => {
		setIsDevToolsOpen(false);
	};

	return (
		<Wrapper>
			<AuthModalContextProvider>
				{appLoaded ? (
					<>
						<User />
						<Device />
					</>
				) : (
					<Loader color="gray7" />
				)}
				<ExitIconWrapper>
					<ExitIcon onClick={onExit} />
				</ExitIconWrapper>
			</AuthModalContextProvider>
		</Wrapper>
	);
};

export default DevToolsOverlay;
