import { IncomingMessage, OutgoingMessage } from 'http';
import nookies from 'nookies';

import { User } from '@/store/user/types';
import apiRequest from '@/utils/apiRequest';
import { userDataFromBrowser } from '@/utils/apiRequest/userDataFromBrowser';
import { userDataFromRequest } from '@/utils/apiRequest/userDataFromRequest';
import { calmLogger } from '@/utils/calmLogger';

import { getCookieValueFromRes } from '../utils/getCookieValueFromRes';

export async function getUser(
	req?: IncomingMessage,
	res?: OutgoingMessage,
	deviceId?: string,
	calmIdentifier?: string | undefined,
): Promise<User | null> {
	const isServer = req && res && deviceId;
	const cookies = nookies.get({ req });

	// A bit wonky, not sure why the server code is substantially different than the client code
	// but in the spirit of not breaking existing functionality, I'll leave both versions in
	if (isServer) {
		let resCookie = '';
		if (res.getHeaders()['set-cookie']) {
			const resCookies = res.getHeaders()['set-cookie'];
			resCookie = getCookieValueFromRes(resCookies, 'calm-user-token');
		}

		if (!cookies['calm-user-token'] && !req.headers.authorization && resCookie.length === 0) return null;
		try {
			const result = await apiRequest({
				endpoint: 'v2/me',
				method: 'GET',
				userData: userDataFromRequest({ req, deviceId, calmIdentifier, calmUserToken: resCookie }),
			});

			return result?.data || null;
		} catch (err) {
			calmLogger.error('Error retrieving user via GET /me', {}, err);
			return null;
		}
	}

	try {
		const result = await apiRequest({
			endpoint: 'v2/me',
			method: 'GET',
			userData: userDataFromBrowser(),
		});

		return result?.data || null;
	} catch (err) {
		calmLogger.error('Error retrieving user via GET /me', {}, err);
		return null;
	}
}
