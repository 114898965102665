import { useStripe } from '@stripe/react-stripe-js';
import {
	CreateTokenCardData,
	PaymentMethod,
	StripeCardElement,
	StripeCardNumberElement,
	Token,
} from '@stripe/stripe-js';

import { PaymentType } from '@/components/purchase/PurchaseForm/types';

interface UsePurchaseToken {
	(): CreateTokenObject;
}

interface CreateStripeToken {
	({
		cardInfo,
		cardData,
	}: {
		cardInfo: StripeCardElement | StripeCardNumberElement;
		cardData: CreateTokenCardData;
	}): Promise<Token | null>;
}

interface CreateTokenObject {
	({
		cardInfo,
		cardData,
		token,
		paymentType,
	}: {
		cardInfo?: StripeCardElement | StripeCardNumberElement;
		cardData?: CreateTokenCardData;
		token?: Token | PaymentMethod;
		paymentType?: PaymentType;
	}): Promise<{
		stripeToken?: Token | PaymentMethod;
	} | null>;
}

export const usePurchaseToken: UsePurchaseToken = () => {
	const stripe = useStripe();

	const createStripeToken: CreateStripeToken = async ({ cardInfo, cardData }) => {
		if (!stripe || !cardInfo) {
			throw new Error("stripe or card info doesn't exist");
		}

		const { error, token } = await stripe.createToken(cardInfo, cardData);

		if (error || !token) throw new Error(error?.code);

		return token;
	};

	const createTokenObject: CreateTokenObject = async ({ paymentType, token, cardInfo, cardData }) => {
		// Otherwise requires Stripe token
		if (token) {
			return { stripeToken: token as Token, stripe_token: token as Token };
		}
		if (!cardInfo || !cardData) return null;

		const newToken = await createStripeToken({ cardInfo, cardData });
		return { stripeToken: newToken as Token, stripe_token: newToken as Token };
	};

	return createTokenObject;
};
