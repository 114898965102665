import {
	ComplianceLevel,
	IS_GDPR_LIMITED,
	IS_HIPAA,
	IS_LIMITED,
	NO_RESTRICTIONS,
	REQUIRE_GDPR_MODAL,
} from '@/store/complianceLevel/types';

export const determineComplianceLevel = ({
	requiresGdpr,
	isLimitedData,
	hasDeclinedGdprCookies,
	isHipaaUser,
}: {
	requiresGdpr?: boolean | null;
	isLimitedData?: boolean;
	hasDeclinedGdprCookies?: boolean;
	isHipaaUser?: boolean;
}): ComplianceLevel => {
	if (hasDeclinedGdprCookies === true) {
		return IS_GDPR_LIMITED;
	}
	if (requiresGdpr === true) {
		return REQUIRE_GDPR_MODAL;
	}
	if (isLimitedData === true) {
		return IS_LIMITED;
	}
	if (isHipaaUser) {
		return IS_HIPAA;
	}
	return NO_RESTRICTIONS;
};
