import { useContext } from 'react';

import SeekBar from '@/components/app/contentPlayer/SeekBar';
import TransportIcon, { TransportIconSpacer } from '@/components/app/contentPlayer/TransportIcon';
import { PacksContext } from '@/components/app/layout/PacksProvider';
import { formatTime } from '@/utils/app/formatTime';
import back15Icon from 'icons/back-15-filled.svg';
import forward15Icon from 'icons/forward-15-filled.svg';
import pauseIcon from 'icons/pause-filled.svg';
import playIcon from 'icons/play-filled.svg';
import stopIcon from 'icons/stop-filled.svg';

import { MediaPlayerContext } from '../MediaPlayerProvider';
import { TransportClickHandler } from '../MediaPlayerProvider/types';
import { MobileTransportIcons, SeekBarContainer, Time, TransportIcons, Wrapper } from './styles';

interface TransportProps {
	playerTheme: 'small' | 'large';
	isWebAppRedesign?: boolean;
}

const Transport = ({ playerTheme, isWebAppRedesign }: TransportProps) => {
	const {
		trackProgress,
		onBack15,
		onPause,
		onForward15,
		onStop,
		onEndScrub,
		onPlay,
		onAudioStart,
		currentTime,
		duration,
		playerMode,
		onAudioScrub: onScrub,
		hideControls,
	} = useContext(MediaPlayerContext);
	const { currentProgram } = useContext(PacksContext);

	const onPlayClick: TransportClickHandler = () => {
		if (playerMode === 'stopped') {
			onAudioStart();
		} else if (playerMode === 'paused') {
			onPlay();
		} else if (playerMode === 'playing') {
			onPause();
		}
	};

	const shouldShowSeekBar = currentProgram?.meditation_type !== 'soundscape';

	return (
		<Wrapper $sizeTheme={playerTheme} $isWebAppRedesign={isWebAppRedesign}>
			{!hideControls && (
				<TransportIcons $sizeTheme={playerTheme} $isWebAppRedesign={isWebAppRedesign}>
					<TransportIconSpacer $isWebAppRedesign={isWebAppRedesign} />
					<TransportIcon
						aria-label="rewind 15 seconds"
						alignment="left"
						icon={back15Icon}
						onClick={onBack15}
						sizeTheme={playerTheme}
						{...(isWebAppRedesign ? { width: 'auto' } : {})}
					/>
					<TransportIcon
						aria-label={playerMode === 'playing' ? 'pause' : 'play'}
						isPrimary
						alignment="center"
						icon={playerMode === 'playing' ? pauseIcon : playIcon}
						onClick={onPlayClick}
						sizeTheme={playerTheme}
						{...(isWebAppRedesign ? { width: 'auto' } : {})}
					/>
					<TransportIcon
						aria-label="fast-forward 15 seconds"
						alignment="right"
						icon={forward15Icon}
						onClick={onForward15}
						sizeTheme={playerTheme}
						{...(isWebAppRedesign ? { width: 'auto' } : {})}
					/>
					<TransportIcon
						aria-label="stop playing"
						alignment="right"
						icon={stopIcon}
						onClick={() => onStop()}
						sizeTheme={playerTheme}
						{...(isWebAppRedesign ? { width: 'auto' } : {})}
					/>
				</TransportIcons>
			)}
			{shouldShowSeekBar && (
				<SeekBarContainer $sizeTheme={playerTheme}>
					<Time $sizeTheme={playerTheme}>{formatTime(trackProgress)}</Time>
					<SeekBar
						onScrub={onScrub}
						trackProgress={trackProgress}
						duration={duration}
						currentTime={currentTime}
						onEndScrub={onEndScrub}
						playerTheme={playerTheme}
						disabled={hideControls}
					/>
					<Time $sizeTheme={playerTheme}>{duration && formatTime(duration)}</Time>
				</SeekBarContainer>
			)}
			{playerTheme === 'small' && (
				<MobileTransportIcons>
					<TransportIcon
						aria-label={playerMode === 'playing' ? 'pause' : 'play'}
						isPrimary
						sizeTheme={playerTheme}
						icon={playerMode === 'playing' ? pauseIcon : playIcon}
						onClick={onPlayClick}
						{...(isWebAppRedesign ? { width: 'auto' } : {})}
					/>
				</MobileTransportIcons>
			)}
		</Wrapper>
	);
};

export default Transport;
