import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { useAnalytics } from '@/hooks/analytics';
import _apiRequest from '@/utils/apiRequest';
import { isServerMaintenanceResponse } from '@/utils/apiRequest/isServerMaintenanceResponse';
import { isPxResponse, pxTestHeaders } from '@/utils/apiRequest/perimeterx';
import { ApiRequestArgsOptionalUserData } from '@/utils/apiRequest/types';
import { userDataFromBrowser } from '@/utils/apiRequest/userDataFromBrowser';
import { JSONObject } from '@/utils/types';

import { useRecaptcha } from './useRecaptcha';

export interface ApiRequest {
	<T = JSONObject>(args: ApiRequestArgsOptionalUserData): Promise<AxiosResponse<T>>;
}

export const useApi = (): ApiRequest => {
	const showRecaptcha = useRecaptcha();
	const { logEvent } = useAnalytics();

	const apiRequest: ApiRequest = useCallback(
		async <T>(args: ApiRequestArgsOptionalUserData) => {
			const {
				endpoint,
				method,
				body,
				customHeaders,
				service = 'api',
				noCache = false,
				userData = null,
				timeout = 15000,
				excludedHeaders,
			} = args;
			const _userData = userData ?? userDataFromBrowser();
			const _customHeaders = {
				...pxTestHeaders,
				...customHeaders,
			};

			try {
				const res = await _apiRequest<T>({
					endpoint,
					method,
					body,
					service,
					noCache,
					timeout,
					excludedHeaders,
					customHeaders: _customHeaders,
					userData: _userData,
				});
				return res;
			} catch (err) {
				if (isServerMaintenanceResponse(err)) {
					if (typeof window !== 'undefined') {
						window.location.assign('/server-maintenance');
					}
				}
				/**
				 * TODO: this recaptcha stuff is what requires us to make a new closure
				 * whenever logEvent or showRecaptcha change
				 * which themselves are re-made on every change to:
				 * * user (login state / ID)
				 * * privacy settings (HIPAA / GDPR / limited data / etc)
				 * * purchase coupon
				 * So if we could find a way to pull this behavior out of the hook we could
				 * stop closure-busting quite so often.
				 * Thankfully most of this is tied to the user so it will just change once on user auth,
				 * but definitely worth keeping an eye on
				 */
				if (isPxResponse(err)) {
					logEvent({
						eventName: 'Perimeter X : Error : Triggered',
						eventProps: { method, service, endpoint },
					});
					await showRecaptcha(err?.data);
					return apiRequest(args);
				}
				throw err;
			}
		},
		[logEvent, showRecaptcha],
	);

	return apiRequest;
};
