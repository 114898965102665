import { ReactNode, useEffect } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FontSizes, FontWeights, Loader, Text } from '@calm-web/design-system';

import { useFormattedCurrency } from '@/hooks/purchase';
import { useFormattedPriceWithTax } from '@/hooks/purchase/useFormattedPriceWithTax';
import { usePlanSkuToggle } from '@/hooks/purchase/usePlanSkuToggle';
import { usePricesState, usePurchaseParamsState, useUserState } from '@/hooks/store';
import { setPurchaseParams } from '@/store/purchaseParams/actions';
import { SubscriptionType } from '@/store/purchaseParams/types';
import { userCanTrial as userCanTrialEval } from '@/utils/subscriptions';

import messages from './messages';
import PlanSkuItems from './PlanSkuItems';
import PlanSkuItemsFamily from './PlanSkuItemsFamily';
import { Divider, TermsText, TotalDueTodayContainer } from './styles';

const PlanSkuToggle = ({ hideCount = false, terms }: { hideCount?: boolean; terms?: MessageDescriptor }) => {
	const formatCurrency = useFormattedCurrency();
	const user = useUserState();
	const prices = usePricesState();
	const purchaseParams = usePurchaseParamsState();
	const annualMonthlyBreakdown = prices.current.yearly / 12;
	const yearlyPriceWithTax = useFormattedPriceWithTax(prices.current.yearly);
	const userCanTrial = userCanTrialEval(user);
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const { activePlan, setActivePlan, skus, isFamilyToggleTest, isLoading } = usePlanSkuToggle();

	const activeSku = skus.find(sku => activePlan === sku.id);
	const dueToday = useFormattedPriceWithTax(activeSku?.dueToday || 0);

	const userDirectSub = activePlan === 'yearly' && userCanTrial;

	useEffect(() => {
		if (activePlan !== purchaseParams.plan) {
			const getSubType = (): SubscriptionType => {
				if (activePlan === 'yearly' && userCanTrial) {
					return 'freetrial';
				}

				if (activePlan !== 'yearly') {
					return activePlan;
				}

				return 'subscribe';
			};

			const subType = getSubType();

			dispatch(
				setPurchaseParams({
					...purchaseParams,
					coupon: null,
					plan: activePlan,
					purchaseType: {
						...(purchaseParams?.purchaseType || {}),
						type: subType,
						isFreeTrial: activePlan === 'yearly' && userCanTrial,
					},
				}),
			);
		}
	}, [activePlan, purchaseParams, dispatch, userCanTrial]);

	if (isLoading) {
		return <Loader color="gray8" />;
	}

	return (
		<div>
			<Text size={FontSizes.lg} el="h2" weight={FontWeights.Medium}>
				{!hideCount && '1. '}
				{formatMessage(messages.confirmPlan)}
			</Text>
			<br />
			<Text size={FontSizes.base}>{formatMessage(messages.confirmSubtitle)}</Text>
			{isFamilyToggleTest ? (
				<PlanSkuItemsFamily skus={skus} setActivePlan={setActivePlan} />
			) : (
				<PlanSkuItems skus={skus} setActivePlan={setActivePlan} />
			)}
			<Divider />
			<TotalDueTodayContainer>
				<Text size={FontSizes.base} weight={FontWeights.Medium}>
					{formatMessage(messages.totalDue)}
				</Text>
				<Text size={FontSizes.base} weight={FontWeights.Medium}>
					{dueToday}
				</Text>
			</TotalDueTodayContainer>
			<TermsText userDirectSub={userDirectSub} size={FontSizes.sm} weight={FontWeights.Medium} color="gray5">
				{formatMessage(terms ?? messages.terms, {
					amount: purchaseParams.purchaseType?.duration || 14,
					monthly: formatCurrency(annualMonthlyBreakdown),
					yearly: yearlyPriceWithTax,
					price: formatCurrency(prices.current[activePlan]),
					originalPrice: formatCurrency(prices.original[activePlan]),
					cancelAnytimeLink: (...chunks: ReactNode[]) => (
						<a
							href="https://support.calm.com/hc/en-us/articles/115002473607-How-to-turn-off-auto-renewal-or-cancel-my-subscription"
							target="_blank"
							rel="noreferrer"
						>
							{chunks}
						</a>
					),
				})}
			</TermsText>
		</div>
	);
};

export default PlanSkuToggle;
