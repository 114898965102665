import { useState } from 'react';

import { FontSizes, FontWeights, Loader, Text } from '@calm-web/design-system';

import AppleAuthButton from '@/components/authForm/AppleAuthButton';
import AuthNotice from '@/components/authForm/AuthNotice';
import AuthTerms from '@/components/authForm/AuthTermsRefresh';
import EmailAuthButton from '@/components/authForm/EmailAuthButton';
import FacebookAuthButton from '@/components/authForm/FacebookAuthButton';
import GoogleAuthButton from '@/components/authForm/GoogleAuthButton';
import LoginSignupForm from '@/components/authForm/LoginSignupForm';
import MarketingOptIn from '@/components/authForm/MarketingOptIn';
import ModeToggle from '@/components/authForm/ModeToggleRefresh';
import OrDivider from '@/components/authForm/OrDivider';
import PartnerSpecificAuthTerms from '@/components/SimplifiedSignup/Components/PartnershipSpecificAuthTerms';
import { FormStateContextProvider } from '@/context/FormStateContextProvider';
import { useAuth } from '@/hooks/auth/useAuth';
import { useAuthFormInputs } from '@/hooks/forms/useAuthFormInputs';
import { useAuthFormModeState, usePartnerState, useRecaptchaVisibleState } from '@/hooks/store';

import { HeadingWrapper, SocialSignupWrapper, Wrapper } from './styles';
import { AuthFormProps } from './types';

const AuthForm = ({
	heading,
	subheading,
	onAuthSuccess,
	initialValues,
	buttonText,
	loginTermsText,
	signupTermsText,
	partnerTerms,
	customPlaceholders,
	showInputIcons,
	inputIcons,
	showMarketingOptIn = true,
	customEventProps,
	source,
	hideFacebookSignup = false,
	hideGoogleSignup = false,
	hideAppleSignup = false,
	hideAuthModeToggle = false,
	emailSignupCTA,
}: AuthFormProps) => {
	const { isAuth0Enabled, isLoading } = useAuth();
	const recaptchaVisible = useRecaptchaVisibleState();
	const partner = usePartnerState();

	const [inputFields, inputActions] = useAuthFormInputs(
		['name', 'email', 'password'] as const,
		initialValues,
		undefined,
		customEventProps,
		inputIcons,
	);
	const [marketingOptIn, setMarketingOptIn] = useState(true);
	const authFormMode = useAuthFormModeState();

	if (isLoading) {
		return <Loader color="gray8" />;
	}

	const hideAllSocialSignup = hideFacebookSignup && hideGoogleSignup && hideAppleSignup;

	return (
		<FormStateContextProvider>
			<Wrapper>
				<HeadingWrapper>
					{heading && (
						<Text noMargin el="h1" weight={FontWeights.Medium} size={FontSizes['2xl']}>
							{heading}
						</Text>
					)}
					{!hideAuthModeToggle && <ModeToggle />}
					{subheading && (
						<Text noMargin el="p">
							{subheading}
						</Text>
					)}
				</HeadingWrapper>
				<>
					{!isAuth0Enabled ? (
						<>
							<LoginSignupForm
								inputActions={inputActions}
								inputFields={inputFields}
								onAuthSuccess={onAuthSuccess}
								buttonText={buttonText}
								customPlaceholders={customPlaceholders}
								marketingOptIn={marketingOptIn}
								showInputIcons={showInputIcons}
								source={source}
							/>
							<AuthNotice />
							{!hideAllSocialSignup && (
								<>
									<OrDivider />
									<SocialSignupWrapper>
										<li>
											<FacebookAuthButton
												contentAlignment="center"
												onAuthSuccess={onAuthSuccess}
												marketingOptIn={marketingOptIn}
											/>
										</li>
										<li>
											<AppleAuthButton
												contentAlignment="center"
												onAuthSuccess={onAuthSuccess}
												marketingOptIn={marketingOptIn}
											/>
										</li>
										<li>
											<GoogleAuthButton
												contentAlignment="center"
												onAuthSuccess={onAuthSuccess}
												marketingOptIn={marketingOptIn}
											/>
										</li>
									</SocialSignupWrapper>
								</>
							)}
						</>
					) : (
						<SocialSignupWrapper>
							<li>
								<EmailAuthButton
									contentAlignment="center"
									marketingOptIn={marketingOptIn}
									onAuthSuccess={onAuthSuccess}
									buttonCTA={emailSignupCTA}
								/>
							</li>
							{!hideFacebookSignup && (
								<li>
									<FacebookAuthButton
										contentAlignment="center"
										onAuthSuccess={onAuthSuccess}
										marketingOptIn={marketingOptIn}
									/>
								</li>
							)}
							{!hideAppleSignup && (
								<li>
									<AppleAuthButton
										contentAlignment="center"
										onAuthSuccess={onAuthSuccess}
										marketingOptIn={marketingOptIn}
									/>
								</li>
							)}
							{!hideGoogleSignup && (
								<li>
									<GoogleAuthButton
										contentAlignment="center"
										onAuthSuccess={onAuthSuccess}
										marketingOptIn={marketingOptIn}
									/>
								</li>
							)}
						</SocialSignupWrapper>
					)}
					{!recaptchaVisible && (
						<AuthTerms loginTermsText={loginTermsText} signupTermsText={signupTermsText as string} />
					)}
					{authFormMode === 'signup' && showMarketingOptIn && (
						<MarketingOptIn onChange={value => setMarketingOptIn(value)} />
					)}
					{partner && partnerTerms && <PartnerSpecificAuthTerms terms={partnerTerms} />}
				</>
			</Wrapper>
		</FormStateContextProvider>
	);
};

export default AuthForm;
