import type { Dictionary } from 'lodash';
import pickBy from 'lodash/pickBy';
import snakeCase from 'lodash/snakeCase';
import qs, { ParsedQs } from 'qs';

export const PARAMS_WHITELIST: Record<string, boolean> = {
	coupon: true,
	gclid: true,
	lifetime_coupon: true,
	partner: true,
	plan: true,
	promotion: true,
	trial_days: true,
	trial_length: true,
	trial_month: true,
	utm_campaign: true,
	utm_content: true,
	utm_medium: true,
	utm_source: true,
	utm_term: true,
	vanity_url: true,
	yearly_coupon: true,
	pid: true,
	af_channel: true,
	af_c_id: true,
	af_adset_id: true,
	af_ad_id: true,
	af_siteid: true,
	af_sub_siteid: true,
	af_sub3: true,
	af_sub4: true,
	af_sub5: true,
	context: true,
	fbclid: true,
};

/*
 * Grab the query parameters from window and returns the whitelisted params
 *
 * @return Object { query_param: String }
 */
function getPropertiesFromQueryParams(
	search: string,
): Dictionary<string | ParsedQs | string[] | ParsedQs[] | undefined> {
	const queryParamsObject = qs.parse(search.replace('?', ''));
	return pickBy(queryParamsObject, (value, key) => {
		const snakeCasedKey: string = snakeCase(key);
		return PARAMS_WHITELIST[snakeCasedKey];
	});
}

export default { getPropertiesFromQueryParams };
