import { AxiosResponse } from 'axios';
import qs from 'query-string';

import { browserApiRequest } from '@/utils/apiRequest/browserApiRequest';

export function getDependents({ subscriptionId }: { subscriptionId: string | null }): Promise<AxiosResponse> {
	return browserApiRequest({
		endpoint: `b2b/dependents?${qs.stringify({
			b2b_partner_subscription_id: subscriptionId,
		})}`,
		method: 'GET',
	});
}
