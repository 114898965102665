import { noop } from 'lodash';
import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from '@/components/SimplifiedSignup/configs/SubscriptionExtension/messages';
import { ErrorProps } from '@/components/SimplifiedSignup/Error/types';
import { isOfferValid } from '@/components/SimplifiedSignup/utils/partnership';
import { useSimplifiedSignupModalContext } from '@/context/SimplifiedSignupModalContextProvider';
import { useAnalytics, useExperimentClient } from '@/hooks/analytics';
import {
	useDeviceState,
	usePartnerState,
	usePricesState,
	usePurchaseParamsState,
	useUserState,
} from '@/hooks/store';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { getPrices } from '@/server/initState/getPrices';
import { setPrices } from '@/store/prices/actions';
import { setPurchaseParams } from '@/store/purchaseParams/actions';
import { initPurchaseParams } from '@/utils/getInitialState';

import {
	ActiveScreenConfig,
	FlowConfig,
	FlowScreenNames,
	SimplifiedSignupContextValue,
	SimplifiedSignupProviderProps,
} from './types';
import { shapeConfigForParams } from './utils';

export const SimplifiedSignupContext = createContext<SimplifiedSignupContextValue>({
	activeScreenKey: 'account',
	activeScreenConfig: {} as ActiveScreenConfig,
	name: '',
	pageName: '',
	screens: {},
	hasFreeTrial: true,
	isOrganicTraffic: true,
	coupon: '',
	offerCountries: null,
	offerCurrencies: null,
	plan: 'yearly',
	logPageViewEvent: () => {},
	setFlowConfig: () => {},
	setError: () => {},
});

export const useSimplifiedSignupContext = (): SimplifiedSignupContextValue =>
	useContext(SimplifiedSignupContext);

export const SimplifiedSignupContextProvider: React.FC<SimplifiedSignupProviderProps> = ({
	children,
	flowConfig: initFlowConfig,
}) => {
	const [flowConfig, _setFlowConfig] = useState<FlowConfig>(initFlowConfig);

	const {
		activeScreenKey: activeScreenKeyConfig,
		coupon,
		fauxAuth = false,
		plan,
		pageName,
		name,
		screens,
		offerCountries,
		offerCurrencies,
		isWithinModal,
		shouldShowIneligibleOfferScreen,
	} = flowConfig;

	const user = useUserState();
	const prices = usePricesState();

	const purchaseParams = usePurchaseParamsState();
	const device = useDeviceState();
	const { query } = useRouter();
	const { logEvent } = useAnalytics();
	const routerPush = useRouterPush();
	const partner = usePartnerState();
	const experimentClient = useExperimentClient();
	const dispatch = useDispatch();

	const isOrganicTraffic = query?.utm_medium !== 'paid';
	const email = query?.email;

	const [activeScreenKey, setActiveScreenKey] = useState<FlowScreenNames>(activeScreenKeyConfig);
	const activeScreenConfig: ActiveScreenConfig = useMemo(
		() => ({ ...screens[activeScreenKey], isWithinModal }),
		[activeScreenKey, screens, isWithinModal],
	);
	const ActiveScreen = useMemo(() => activeScreenConfig.component, [activeScreenConfig]);
	const showHeader = useMemo(() => screens[activeScreenKey]?.showHeader || true, [activeScreenKey, screens]);
	const { setIsModalOpen } = useSimplifiedSignupModalContext();

	const [error, setError] = useState<ErrorProps | undefined>();

	const coBranded = useMemo(() => flowConfig.coBranded || undefined, [flowConfig.coBranded]);

	/**
	 * Check if the offer is valid for the current user
	 */
	const isOfferCountry = useCallback((): boolean => {
		// setting both of these to null opens up the offer to all countries and currencies
		if (!offerCurrencies && !offerCountries) return true;

		return device?.ip_country && device?.ip_country !== 'unknown'
			? (offerCountries ?? []).includes(device?.ip_country || '')
			: (offerCurrencies ?? []).includes(prices.pricing_format.currency);
	}, [device, offerCountries, offerCurrencies, prices.pricing_format.currency]);

	const setFlowConfig = useCallback(
		async (config: FlowConfig) => {
			const newPrices = await getPrices({ coupon: config.coupon ?? query.coupon });
			const newPurchaseParams = initPurchaseParams({
				query,
				user,
				prices: newPrices,
				routeSpecificPurchaseParams: shapeConfigForParams(config),
			});
			if (newPrices) {
				dispatch(setPrices(newPrices));
			}
			dispatch(setPurchaseParams(newPurchaseParams));
			_setFlowConfig(config);
		},
		[dispatch, query, user],
	);

	/**
	 * Route to the next screen in the flow - this is the main method for navigating the flow
	 */

	const routeToScreen = useCallback((screen: FlowScreenNames | null): void => {
		if (screen) {
			setActiveScreenKey(screen);
		}
	}, []);

	/**
	 * This is where the majority of the pricing logic (and bugs) can be found.
	 *
	 * This is a great area to refactor and clean up the pricing logic. My plan with Sisu 1.5 was to
	 * introduce a middleware pattern to process the configuration and handle the pricing logic on the
	 * next web server. This would allow us to have a single source of truth for pricing and reduce the
	 * complexity of the pricing logic in the client.
	 *
	 */

	/**
	 * This is the main logic for the flow navigation.
	 * It determines where the user should go based on their subscription status
	 */
	useEffect(() => {
		// // If the offer is no longer valid, inform the user and offer 40% off offer
		if (!isOfferValid(partner)) {
			return noop();
		}

		// Logged in and active Calm - send to wherever config dictates
		// Can be a function that triggers a side effect such as SheerID
		if (user?.subscription?.valid) {
			// if the user has a Calm premium account but is trying to claim a
			// partner offer, let the account page handle the error
			if (partner && activeScreenConfig.name === 'account') {
				noop();
			} else if (activeScreenConfig.hasPremiumSubCallback) {
				activeScreenConfig.hasPremiumSubCallback(routerPush, setIsModalOpen);
			} else if (typeof activeScreenConfig.nextScreen === 'function') {
				activeScreenConfig.nextScreen({ routeToScreen, user, _query: query, setFlowConfig, setError });
			} else {
				routeToScreen(activeScreenConfig.nextScreen);
			}
		}

		// Logged in but not active Calm - send to Payment Page
		if (user && !user?.subscription?.valid) {
			if (typeof activeScreenConfig.nextScreen === 'function') {
				const _query = Object.keys(query).length ? query : undefined;
				if (!isOfferCountry()) {
					if (shouldShowIneligibleOfferScreen) {
						setError({
							heading: messages.ineligibleErrorHeading,
							subheading: messages.ineligibleErrorSubheading,
							cta: {
								label: messages.ineligibleErrorCta,
								onClick: async routerPush => {
									await routerPush('/app');
								},
							},
						});
					} else {
						routerPush('/subscribe')
							.then(() => {})
							.catch(() => {});
					}
				} else {
					activeScreenConfig.nextScreen({
						routeToScreen,
						user,
						_query,
						setFlowConfig,
						experimentClient,
						device,
						setError,
					});
				}
			} else {
				routeToScreen('payment');
			}
		}

		/**
		 * This supports the fauxAuth flow where the user is not logged in but we have their email.
		 * Presenting them with a purchase screen and allowing them to claim the offer.
		 */

		if (!user) {
			if (fauxAuth && email) {
				routeToScreen('payment');
			} else {
				routeToScreen('account');
			}
		}
	}, [
		user,
		device,
		activeScreenConfig,
		query,
		routeToScreen,
		isOfferCountry,
		routerPush,
		partner,
		setIsModalOpen,
		email,
		experimentClient,
		fauxAuth,
		shouldShowIneligibleOfferScreen,
		setFlowConfig,
	]);

	useEffect(() => {
		if (error) {
			routeToScreen('error');
		}
	}, [error, routeToScreen]);

	const logPageViewEvent = useCallback(
		(screen: FlowScreenNames | null) => {
			logEvent({
				eventName: 'Page : Viewed',
				eventProps: {
					page_name: `${pageName}-${screen}`,
					source: name,
					...(name === 'spotify' && { content_source: query.spotifyShowUri ?? 'organic' }),
				},
			});
		},
		[logEvent, name, pageName, query],
	);

	const value = useMemo(
		() => ({
			activeScreenKey,
			ActiveScreen,
			activeScreenConfig,
			coBranded,
			name,
			pageName,
			user,
			plan,
			coupon,
			purchaseParams,
			isOfferCountry,
			offerCurrencies: flowConfig.offerCurrencies,
			offerCountries: flowConfig.offerCountries,
			screens: flowConfig.screens,
			hasFreeTrial: flowConfig.hasFreeTrial,
			showHeader,
			isOrganicTraffic,
			routeToScreen,
			logPageViewEvent,
			setFlowConfig,
			flowConfig,
			fauxAuth,
			error,
			setError,
		}),
		[
			routeToScreen,
			activeScreenKey,
			activeScreenConfig,
			ActiveScreen,
			coBranded,
			name,
			pageName,
			user,
			plan,
			coupon,
			purchaseParams,
			isOfferCountry,
			showHeader,
			isOrganicTraffic,
			logPageViewEvent,
			setFlowConfig,
			flowConfig,
			fauxAuth,
			error,
		],
	);

	return <SimplifiedSignupContext.Provider value={value}>{children}</SimplifiedSignupContext.Provider>;
};
