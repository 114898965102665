import styled, { css } from 'styled-components';
import { ifProp, palette, theme } from 'styled-tools';

import { Button, minWidth, units } from '@calm-web/design-system';

export const Wrapper = styled.div<{ isOpen: boolean }>`
	align-items: center;
	background: ${palette('darkBlue')};
	${ifProp(
		'isOpen',
		css`
			background: rgba(0, 0, 0, 0);
		`,
	)}
	display: flex;
	height: ${theme('app.mobileHeaderHeight')};
	justify-content: space-between;
	padding: 20px 16px;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	max-height: 20vh;
	z-index: ${props => ifProp('isOpen', theme('modal.zIndex')(props) + 1, theme('modal.zIndex')(props) - 1)};
	transition: background 0.3s ease-out;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;

	${minWidth('desktop')} {
		display: none;
	}
`;

export const MenuWrapper = styled.ul`
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
	z-index: 1000000;
	overflow-y: auto;
	padding: 0;
	padding: 0 16px 16px 16px;
	li {
		list-style: none;
	}
`;

export const PrivacyPolicy = styled.a`
	color: ${palette('white')};
	margin-top: auto;
	text-decoration: none;
`;

export const RightSideHeader = styled.div`
	display: flex;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 20px;

	button {
		height: 48px;
	}
`;

export const HandoffButton = styled(Button)`
	min-height: 32px !important;
	height: 32px;
	padding-top: 4px;
	padding-bottom: 4px;
	font-size: 1rem !important;
	margin-right: 12px;
`;

export const UpsellButtonWrapper = styled.div`
	margin-bottom: ${units(2.5)};
	max-width: 100%;
	button {
		margin: 0;
	}
`;

export const QuickActionButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 20px;
`;

export const SceneSearchButtonWrapper = styled.div`
	display: flex;
	gap: 12px;
	margin-bottom: 30px;
`;
