import { ReactNode, useEffect } from 'react';

import { useAnalytics } from '@/hooks/analytics';
import { useDeviceState, useUserState } from '@/hooks/store';
import { calmLogger } from '@/utils/calmLogger';
import { checkGPC } from '@/utils/gpc';

import CookiePreferencesBannerProvider from '../CookiePreferencesBannerProvider';

const PrivacyProvider = ({
	children,
	hideAcceptCookiesModal,
}: {
	children: ReactNode;
	hideAcceptCookiesModal?: boolean;
}) => {
	const device = useDeviceState();
	const user = useUserState();
	const { logEvent } = useAnalytics();

	useEffect(() => {
		const checkGPCWrapper = async () => {
			const didEnableGPC = await checkGPC(device, user);
			if (didEnableGPC) {
				logEvent({
					eventName: 'Cookie Preferences : GPC Enabled',
				});
			}
		};
		checkGPCWrapper().catch(error => calmLogger.error('Error in PrivacyProvider checkGPCWrapper', {}, error));
	}, [device, logEvent, user]);

	return (
		<CookiePreferencesBannerProvider hideAcceptCookiesModal={hideAcceptCookiesModal}>
			{children}
		</CookiePreferencesBannerProvider>
	);
};

export default PrivacyProvider;
