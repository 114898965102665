import { FlowConfig } from './types';

export const shapeConfigForParams = (config: FlowConfig) => {
	const { plan, promotion, freeTrialLength, hasFreeTrial, freeTrialLengthUnit, coupon } = config;

	return {
		plan: plan ?? null,
		promotion: promotion ?? null,
		coupon: coupon ?? null,
		hasFreeTrial: hasFreeTrial ?? null,
		freeTrialLength: freeTrialLength ?? null,
		freeTrialLengthUnit: freeTrialLengthUnit ?? null,
	};
};
