import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Button, Modal } from '@calm-web/design-system';

import { useAnalytics } from '@/hooks/analytics';
import { useFormattedCurrency } from '@/hooks/purchase';
import { useShareToken } from '@/hooks/share/useShareToken';
import { useAppLoadedState, usePricesState, useUserState } from '@/hooks/store';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { getPrices } from '@/server/initState/getPrices';
import { setPrices } from '@/store/prices/actions';
import { calmLogger } from '@/utils/calmLogger';
import { getCookie, removeCookie } from '@/utils/cookies';

import messages from './messages';
import { Heading, OriginalPrice, Preheading, PriceContainer, SalePrice, Wrapper } from './styles';

export const FORTY_PERCENT_OFF_COUPON = '40percent_welcome_offer';

const DiscountModal = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const formatCurrency = useFormattedCurrency();
	const appLoaded = useAppLoadedState();
	const user = useUserState();
	const prices = usePricesState();
	const { logEvent } = useAnalytics();
	const { pathname, query } = useRouter();
	const routerPush = useRouterPush();
	const shareToken = useShareToken();

	const [isOpen, setIsOpen] = useState(false);

	const logEventWithProps = useCallback(
		(eventName: string) => {
			logEvent({
				eventName,
				eventProps: {
					offer: 'special_welcome_offer',
					unit_price: 41.99,
					coupon: '40_percent_welcome_offer',
				},
			});
		},
		[logEvent],
	);

	const onConfirmClick = async () => {
		removeCookie('ftue-show-discount-modal');
		logEventWithProps('Discount Intro Modal : Proceeded');
		await routerPush('/new-member-offer', {
			coupon: FORTY_PERCENT_OFF_COUPON,
		});
	};

	const onModalCloseClick = () => {
		removeCookie('ftue-show-discount-modal');
		logEventWithProps('Discount Intro Modal : Dismissed');
		setIsOpen(false);
	};

	useEffect(() => {
		// This should be moved to the server
		const initDiscountFlow = async () => {
			const newPrices = await getPrices({
				...query,
				coupon: FORTY_PERCENT_OFF_COUPON,
			});

			if (newPrices) {
				dispatch(setPrices(newPrices));
			}

			setIsOpen(true);
		};

		const shouldShowDiscountModal = getCookie('ftue-show-discount-modal') && !shareToken;

		if (appLoaded && !user?.subscription?.valid && shouldShowDiscountModal) {
			initDiscountFlow().catch(error =>
				calmLogger.error('Error in DiscountModal initDiscountFlow', {}, error),
			);
		}
	}, [user, appLoaded, shareToken, query.coupon, pathname, query, dispatch]);

	useEffect(() => {
		if (user?.subscription?.valid) {
			removeCookie('ftue-show-discount-modal');
			removeCookie('ftue-show-discount-cta');
			setIsOpen(false);
		}
	}, [user]);

	useEffect(() => {
		if (isOpen) {
			logEventWithProps('Discount Intro Modal : Shown');
		}
	}, [isOpen, logEventWithProps]);

	return (
		<Modal
			canClose
			isOpen={isOpen}
			closeModal={onModalCloseClick}
			showBackButton={false}
			aria-label={formatMessage(messages.heading)}
		>
			<Wrapper>
				<Preheading>{formatMessage(messages.preheading)}</Preheading>
				<Heading>{formatMessage(messages.heading)}</Heading>
				<PriceContainer>
					<OriginalPrice>{formatCurrency(prices?.original?.yearly)}</OriginalPrice>
					<SalePrice>{formatCurrency(prices?.current?.yearly)}</SalePrice>
				</PriceContainer>
				<Button
					fullWidth
					backgroundColor="accessibleBrandGradient"
					textColor="white"
					onPress={onConfirmClick}
				>
					{formatMessage(messages.discountButtonText)}
				</Button>
			</Wrapper>
		</Modal>
	);
};

export default DiscountModal;
