import { MessageDescriptor } from 'react-intl';

import {
	MentalHealthSectionTitle,
	MentalHealthSlide,
} from '@/components/homepageRefresh/homepage/MentalHealthTools/types';
import { CopyItem } from '@/components/valuePropList';

import { stressAndAnxietyMessages } from '../../ConfigData/MessageSets';
import {
	howOftenTiles,
	stressCurrentStateTiles,
	stressIdealStateTiles,
} from '../../ConfigData/QuestionTileSets';
import { SignupScreen, SignupScreens } from '../../PreSignupProvider/types';

const marqueValueProps: Array<MessageDescriptor> = [
	stressAndAnxietyMessages.heroBodyValue0,
	stressAndAnxietyMessages.heroBodyValue1,
	stressAndAnxietyMessages.heroBodyValue2,
	stressAndAnxietyMessages.heroBodyValue3,
];

export const stressValueProps: Array<CopyItem> = [
	{
		id: 'valueProp1',
		header: stressAndAnxietyMessages.valueProp1Head,
		body: stressAndAnxietyMessages.valueProp1Body,
	},
	{
		id: 'valueProp2',
		header: stressAndAnxietyMessages.valueProp2Head,
		body: stressAndAnxietyMessages.valueProp2Body,
	},
	{
		id: 'valueProp3',
		header: stressAndAnxietyMessages.valueProp3Head,
		body: stressAndAnxietyMessages.valueProp3Body,
	},
];

export const sections: Record<MentalHealthSectionTitle, MentalHealthSlide> = {
	stress: {
		title: stressAndAnxietyMessages.slide1Title,
		heading: stressAndAnxietyMessages.slide1Heading,
		copy: stressAndAnxietyMessages.slide1Copy,
		contentTitle: stressAndAnxietyMessages.slide1ContentTitle,
		contentSubheading: stressAndAnxietyMessages.slide1ContentSubheading,
		contentUrl: '/app/player/QgbYQrASjO',
		author: stressAndAnxietyMessages.slide1ContentAuthor,
		imageUrl: '/_n/images/homepage/player-stress.webp',
		audioSrc: 'https://assets.calm.com/7bacc0fc9d6530b171bd6ed7bab4f3e7.m4a',
	},
	sleep: {
		title: stressAndAnxietyMessages.slide2Title,
		heading: stressAndAnxietyMessages.slide2Heading,
		copy: stressAndAnxietyMessages.slide2Copy,
		contentTitle: stressAndAnxietyMessages.slide2ContentTitle,
		contentSubheading: stressAndAnxietyMessages.slide2ContentSubheading,
		contentUrl: '/app/player/2QQEAZ7IsG',
		author: stressAndAnxietyMessages.slide2ContentAuthor,
		imageUrl: '/_n/images/homepage/player-harry.webp',
		audioSrc: '/_n/audio/subpages/SLEP_0184_DreamWithMe_EN_2CH_MK_20230802_HomepageCutdown.mp3',
	},
	focus: {
		title: stressAndAnxietyMessages.slide3Title,
		heading: stressAndAnxietyMessages.slide3Heading,
		copy: stressAndAnxietyMessages.slide3Copy,
		contentTitle: stressAndAnxietyMessages.slide3ContentTitle,
		contentSubheading: stressAndAnxietyMessages.slide3ContentSubheading,
		contentUrl: '/app/player/uvqyM--Byq',
		author: stressAndAnxietyMessages.slide3ContentAuthor,
		imageUrl: '/_n/images/homepage/player-focus.webp',
		audioSrc: 'https://assets.calm.com/7466488786a895624e6d8286b4e3138f.m4a',
	},
	relaxation: {
		title: stressAndAnxietyMessages.slide4Title,
		heading: stressAndAnxietyMessages.slide4Heading,
		copy: stressAndAnxietyMessages.slide4Copy,
		contentTitle: stressAndAnxietyMessages.slide4ContentTitle,
		contentSubheading: stressAndAnxietyMessages.slide4ContentSubheading,
		imageUrl: '/_n/images/homepage/player-relaxation.webp',
		audioSrc: '/_n/audio/subpages/SSPS_0020_RainOnLeaves_2CH_MK_20230802_HomepageCutdown.mp3',
	},
	habits: {
		title: stressAndAnxietyMessages.slide5Title,
		heading: stressAndAnxietyMessages.slide5Heading,
		copy: stressAndAnxietyMessages.slide5Copy,
		contentTitle: stressAndAnxietyMessages.slide5ContentTitle,
		contentSubheading: stressAndAnxietyMessages.slide5ContentSubheading,
		contentUrl: '/app/player/_6cjk5C7D-',
		author: stressAndAnxietyMessages.slide5ContentAuthor,
		imageUrl: '/_n/images/homepage/player-habits.webp',
		audioSrc: '/_n/audio/subpages/DJAY_OwnTheDay_EN_2CH_MK_20230802_HomepageCutdown.mp3',
	},
	wellbeing: {
		title: stressAndAnxietyMessages.slide6Title,
		heading: stressAndAnxietyMessages.slide6Heading,
		copy: stressAndAnxietyMessages.slide6Copy,
		contentTitle: stressAndAnxietyMessages.slide6ContentTitle,
		contentSubheading: stressAndAnxietyMessages.slide6ContentSubheading,
		contentUrl: '/app/player/JLpKB3xLL',
		author: stressAndAnxietyMessages.slide6ContentAuthor,
		imageUrl: '/_n/images/homepage/player-physical.webp',
		audioSrc: 'https://assets.calm.com/ebabec395bc04c115456cb11cc26ecab.m4a',
	},
};

const startingPageConfig: SignupScreen = {
	componentName: 'FocusedLandingPage',
	analyticsName: 'FTUE : Transition',
	transitionType: 'welcome_screen',
	nextScreen: 'q1',
	isFullScreen: true,
	heroBgImage: '/_n/images/subpages/stress-hero.webp',
	heroHeading: stressAndAnxietyMessages.heroHeading,
	heroBody: stressAndAnxietyMessages.heroBody,
	heroHeaderColor: 'blue7',
	showNav: true,
	canExit: false,
	heroCTAButtonProperties: {
		label: stressAndAnxietyMessages.heroPrimaryCTA,
	},
	heroSecondaryCTAButtonProperties: {
		label: stressAndAnxietyMessages.heroSecondaryCTA,
	},
	hasSkip: true,
	skipToEndOverride: 'su',
	URLKey: 'landing',
	marqueValueProps,
	toolSections: sections,
};

export const stressQuestionnaireScreens: SignupScreens = {
	start: { ...startingPageConfig },
	landing: { ...startingPageConfig },
	q1: {
		componentName: 'PollSingleClickUpdated',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'mood_question',
		nextScreen: 'q2',
		tiles: howOftenTiles,
		question: stressAndAnxietyMessages.question1,
		questionSubtitle: stressAndAnxietyMessages.question1sub,
		showNav: true,
		backButtonHidden: true,
		bgColor: 'gray0',
		canExit: false,
		questionNumber: 1,
		totalQuestions: 3,
		hasSkip: true,
		skipToEndOverride: 'su',
		hasPadding: false,
		URLKey: 'q1',
	},
	q2: {
		componentName: 'PollSingleClickUpdated',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'mood_question',
		nextScreen: 'q3',
		tiles: stressCurrentStateTiles,
		question: stressAndAnxietyMessages.question2,
		questionSubtitle: stressAndAnxietyMessages.question2sub,
		showNav: true,
		backButtonHidden: true,
		bgColor: 'gray0',
		canExit: false,
		questionNumber: 2,
		totalQuestions: 3,
		hasSkip: true,
		skipToEndOverride: 'su',
		hasPadding: false,
		URLKey: 'q2',
	},
	q3: {
		componentName: 'PollSingleClickUpdated',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'mood_question',
		nextScreen: 'su',
		tiles: stressIdealStateTiles,
		question: stressAndAnxietyMessages.question3,
		questionSubtitle: stressAndAnxietyMessages.question3sub,
		showNav: true,
		backButtonHidden: true,
		bgColor: 'gray0',
		canExit: false,
		questionNumber: 3,
		totalQuestions: 3,
		hasSkip: true,
		skipToEndOverride: 'su',
		hasPadding: false,
		URLKey: 'q3',
	},
	su: {
		componentName: 'SimplifiedSignup',
		analyticsName: 'SimplifiedSignup',
		backButtonHidden: true,
		showNav: false,
		isFullScreen: true,
		upsellImage: true,
		upsellQuizResultComponent: 'SignUpQuizResult',
		canExit: true,
		URLKey: 'su',
	},
	pay: {
		componentName: 'SimplifiedSignup',
		analyticsName: 'SimplifiedSignup',
		backButtonHidden: true,
		showNav: false,
		isFullScreen: true,
		upsellImage: true,
		upsellQuizResultComponent: 'SignUpQuizResult',
		canExit: true,
		URLKey: 'pay',
	},
};
