import { PropsWithChildren } from 'react';

import AnalyticsProvider from '@/components/app/layout/AnalyticsContextProvider';
import OfferBannerProvider from '@/components/app/layout/OfferBannerProvider';
import PrivacyProvider from '@/components/app/layout/PrivacyProvider';
import AuthProvider from '@/context/auth/AuthContextProvider';

const ClientProviders = ({
	hideAcceptCookiesModal,
	children,
}: PropsWithChildren<{ hideAcceptCookiesModal?: boolean }>) => {
	return (
		<AnalyticsProvider>
			<AuthProvider>
				<PrivacyProvider hideAcceptCookiesModal={hideAcceptCookiesModal}>
					<OfferBannerProvider>{children}</OfferBannerProvider>
				</PrivacyProvider>
			</AuthProvider>
		</AnalyticsProvider>
	);
};

export default ClientProviders;
