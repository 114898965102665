import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import { getIsMobileClient } from '@/components/accessibility/SkipToMain/utils';

import messages from './messages';
import { SkipToMainLink } from './styles';

export const MAIN_CONTENT_ID = 'main-content';

const SkipToMain = ({ href }: { href?: string }) => {
	const { formatMessage } = useIntl();
	const { query } = useRouter();

	const isMobileClient = getIsMobileClient(query);

	if (isMobileClient) {
		return null;
	}

	return (
		<SkipToMainLink href={href || `#${MAIN_CONTENT_ID}`}>
			{formatMessage(messages.skipToMainCta)}
		</SkipToMainLink>
	);
};

export default SkipToMain;
