import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import messages from '@/components/purchase/CreditCardForm/messages';

import { LockIcon, Wrapper } from './styles';

const SecureTransaction = () => {
	const { formatMessage } = useIntl();

	return (
		<Wrapper>
			<LockIcon aria-hidden />
			<Text el="p" size={FontSizes.sm} weight={FontWeights.Medium} color="gray5">
				{formatMessage(messages.secureTransaction)}
			</Text>
		</Wrapper>
	);
};

export default SecureTransaction;
