import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Loader } from '@calm-web/design-system';

import { useAuthModalContext } from '@/context/AuthModalContextProvider';
import { useApi } from '@/hooks/api';
import { setUser } from '@/store/user/actions';
import { User } from '@/store/user/types';
import { setExpiringCalmTokenForTeams } from '@/utils/app/msftTeams';
import { calmLogger, datadogLogger } from '@/utils/calmLogger';
import { removeAllTeamsUserCookies, setTeamsUserCookies } from '@/utils/privacyCookies';
import { getTeamsJwt } from '@/utils/teams-calm';

import { useTeamsUserContext } from '../context/TeamsUserContext';
import TeamsAuthErrorPage from './TeamsAuthErrorPage';

type setToken = (token: string) => void;

export interface TeamsTokenContext {
	calmToken: string | null;
	jwtToken: string | null;
	setJwtToken: setToken;
	setCalmToken: setToken;
}

interface AccessTokenResponse {
	token_type: 'Bearer';
	access_token: string;
	refresh_token: string;
	id_token: string | null;
	expires_in: number;
}

export const TeamsTokenContext = createContext<TeamsTokenContext>({
	calmToken: null,
	jwtToken: null,
	setJwtToken: () => {},
	setCalmToken: () => {},
});

export default function TeamsAuthWrapper({ children }: { children?: ReactNode }) {
	const [isLoading, setIsLoading] = useState(true);
	const [jwtToken, setJwtToken] = useState('');
	const [calmToken, setCalmToken] = useState('');
	const [backoff, setBackoff] = useState(false);
	const dispatch = useDispatch();
	const teamsUserContext = useTeamsUserContext();

	const apiRequest = useApi();
	const injectedOnAuthSuccess = useCallback(
		async (user: User): Promise<void> => {
			if (jwtToken && user) {
				// Because the user token isn't available in a cookie here, we need to pass it along manually
				const customHeaders = user.token ? { 'x-session-token': user.token } : undefined;
				await apiRequest({
					endpoint: 'ms-teams/auth/link',
					method: 'POST',
					customHeaders,
					body: {
						ms_teams_token: jwtToken,
					},
				});
			}
		},
		[jwtToken, apiRequest],
	);
	useAuthModalContext({
		injectedOnAuthSuccess,

		// Default to `teams-app` for Calm Web App on Teams as source
		// and override it if it's any other Teams flow. This is b/c
		// we don't currently have a good way to determine if we're
		// on the App Tab of Teams or not.
		source: 'teams-app',
	});

	useEffect(() => {
		const loadToken = async () => {
			try {
				const jwtToken = await getTeamsJwt();
				setJwtToken(jwtToken);
			} catch (err) {
				setBackoff(true);
				throw err;
			}
		};
		loadToken().catch(error => {
			calmLogger.error('Error in TeamsAuthWrapper loadToken', {}, error);
		});
	}, []);

	useEffect(() => {
		if (backoff) {
			const timeoutId = setTimeout(async () => {
				try {
					calmLogger.info('Error calling getAuthToken, retrying');
					const jwtToken = await getTeamsJwt();
					setJwtToken(jwtToken);
				} catch (err) {
					setIsLoading(false);
					calmLogger.error('Error in TeamsAuthWrapper second loadToken', {}, err);
				}
			}, 3000);

			return () => {
				if (timeoutId) {
					clearTimeout(timeoutId);
				}
			};
		}
	}, [backoff]);

	useEffect(() => {
		if (!jwtToken) {
			return;
		}

		const loadOauthToken = async () => {
			try {
				const tokenResponse = await apiRequest<AccessTokenResponse>({
					endpoint: 'oauth/token',
					method: 'POST',
					body: {
						grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
						intent: 'ms_token_exchange',
						assertion: jwtToken,
					},
				});
				if (tokenResponse.data.access_token) {
					setCalmToken(tokenResponse.data.access_token);
					setExpiringCalmTokenForTeams(tokenResponse.data.access_token);
				}
			} catch (err) {
				removeAllTeamsUserCookies();
				setIsLoading(false);
				if (err.status !== 401) {
					throw err;
				}
			}
		};
		loadOauthToken().catch(error => calmLogger.error('Error in TeamsAuthWrapper loadOauthToken', {}, error));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jwtToken]);

	useEffect(() => {
		if (calmToken === '') {
			return;
		}
		const loadCalmUser = async () => {
			try {
				const userResponse = await apiRequest<User>({
					endpoint: 'v2/me',
					method: 'GET',
				});
				removeAllTeamsUserCookies();
				dispatch(setUser(userResponse.data));
				setTeamsUserCookies(userResponse.data);
			} finally {
				setIsLoading(false);
			}
		};
		loadCalmUser().catch(error => {
			calmLogger.error('Error in TeamsAuthWrapper loadCalmUser', {}, error);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [calmToken]);

	if (isLoading) {
		return (
			<Loader
				context={{ jwtToken, isLoading, location: 'TeamsAuthWrapper', ...teamsUserContext }}
				logger={datadogLogger.warn}
			/>
		);
	}

	if (!jwtToken) {
		return <TeamsAuthErrorPage teamsUserContext={teamsUserContext} />;
	}

	return (
		<TeamsTokenContext.Provider value={{ calmToken, jwtToken, setJwtToken, setCalmToken }}>
			{children}
		</TeamsTokenContext.Provider>
	);
}

export const useCalmToken = () => {
	const { calmToken } = useContext(TeamsTokenContext);
	return calmToken;
};

export const useJwtToken = () => {
	const { jwtToken } = useContext(TeamsTokenContext);
	return jwtToken;
};

export const useSetCalmToken = () => {
	const { setCalmToken } = useContext(TeamsTokenContext);
	return setCalmToken;
};
