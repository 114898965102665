import { AxiosPromise } from 'axios';

import { browserApiRequest } from '@/utils/apiRequest/browserApiRequest';

export function postPartnerLogin(linkToken: string, userId: string): AxiosPromise {
	return browserApiRequest({
		endpoint: 'b2b/users/login',
		method: 'POST',
		body: JSON.stringify({
			user_id: userId,
		}),
		customHeaders: {
			'Content-Type': 'application/json',
			'x-calm-link-token': linkToken,
		},
	});
}
