import { usePricesState, usePurchaseParamsState } from '@/hooks/store';

interface UsePaymentFormValues {
	(): {
		paymentFormPrice: number;
		paymentFormCurrency: string;
		taxablePrice: number;
	};
}

export const usePaymentFormValues: UsePaymentFormValues = () => {
	const prices = usePricesState();
	const { plan, purchaseType, giftPrice, isUpdateBillingScreen } = usePurchaseParamsState();

	const getPaymentMethodPrice = () => {
		if (giftPrice?.price || giftPrice?.originalPrice) {
			return giftPrice.price ?? (giftPrice.originalPrice as number);
		}

		if (purchaseType?.type === 'freetrial' || isUpdateBillingScreen) {
			return 0;
		}

		return prices?.current[plan];
	};

	const getPaymentMethodCurrency = () => {
		if (giftPrice || isUpdateBillingScreen) {
			return 'USD';
		}

		return prices?.current?.currency ? prices.current.currency.toUpperCase() : 'USD';
	};

	const getTaxablePrice = () => {
		if (purchaseType?.type === 'freetrial' && typeof purchaseType.price === 'number') {
			return purchaseType.price;
		}

		return getPaymentMethodPrice();
	};

	return {
		paymentFormPrice: getPaymentMethodPrice(),
		paymentFormCurrency: getPaymentMethodCurrency(),
		taxablePrice: getTaxablePrice(),
	};
};
