import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocalStorage } from 'react-use';

import { Tooltip } from '@calm-web/design-system';

import { ShareContext } from '@/components/app/shareFreeContent/ShareProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import { AmplitudeExperimentNames } from '@/utils/experiments/amplitudeExperiment';
import closeIcon from 'icons/close.svg';
import shareIcon from 'icons/share-ios-filled.svg';

import { messages } from './messages';
import { CloseButton, InfoIcon, ShareIcon, TooltipContent } from './styles';

interface ShareButtonProps {
	isShareModalVisible: (isModalOpen: boolean) => void;
	shouldShowText?: boolean;
}

const ShareButton = ({ isShareModalVisible, shouldShowText }: ShareButtonProps) => {
	const [isTooltipShown, setIsTooltipShown] = useState(false);
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const { shareToken } = useContext(ShareContext);
	const [numTimesTooltipShown = 0, setNumTimesTooltipShown] = useLocalStorage('timesShareTooltipShown', 0);
	const { isEnrolled, isLoading } = useAmplitudeExperiment(AmplitudeExperimentNames.WEB_APP_REDESIGN_2);

	const onPress = () => {
		logEvent({
			eventName: 'Share Button : Clicked',
			eventProps: {
				share_token: shareToken ?? null,
			},
		});
		isShareModalVisible(true);
		setIsTooltipShown(false);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (numTimesTooltipShown < 2) {
				setNumTimesTooltipShown(numTimesTooltipShown + 1);
				logEvent({
					eventName: 'Tooltip : Shown',
					eventProps: {
						id: 'player_share_shown',
					},
				});
				setIsTooltipShown(true);
			}
		}, 1000);

		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Tooltip
			hasInteractiveContent
			backgroundColor="white"
			visible={isTooltipShown}
			textColor="black"
			placement="top"
			maxWidth="560px"
			content={
				<TooltipContent>
					<InfoIcon aria-label="info" />
					{formatMessage(messages.tooltipMessage)}
					<CloseButton onPress={() => setIsTooltipShown(false)} aria-label="close-button" Icon={closeIcon} />
				</TooltipContent>
			}
		>
			<ShareIcon
				aria-label="share-button"
				onPress={onPress}
				Icon={shareIcon}
				$isWebAppRedesign={!isLoading && isEnrolled}
			>
				{shouldShowText && formatMessage(messages.shareCTA)}
			</ShareIcon>
		</Tooltip>
	);
};

export default ShareButton;
