import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text, TextButton } from '@calm-web/design-system';

import Image from '@/components/image/NextImage';
import ValuePropsList from '@/components/valuePropList';
import { useAnalytics } from '@/hooks/analytics';
import { useDeviceState, useUserState } from '@/hooks/store';
import { useIsMobile } from '@/hooks/ui/useIsMobile';
import { useComponentDidMount } from '@/hooks/utils/useComponentDidMount';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import AppleStore from 'icons/apple-store.svg';
import DownloadGooglePlay from 'icons/download-google-play.svg';

import { useSimplifiedSignupContext } from '../Context';
import messages from './messages';
import {
	AppDownloadContainer,
	AppDownloadItemContainer,
	AppImageWrapper,
	CTALink,
	CTAWrapper,
	HandoffSubheading,
	HandoffWelcomeContainer,
	IconButton,
	Line,
	QRCode,
	QRCodeWrapper,
	SimplifiedSignupHandoffContainer,
	SocialWrapper,
	TrueMedQualificationHeading,
	TrueMedQualificationLink,
	ValuePropsWrapper,
} from './styles';
import { HandoffScreenConfig } from './types';

const SimplifiedSignupHandoff = () => {
	const user = useUserState();
	const { formatMessage } = useIntl();
	const routerPush = useRouterPush();
	const device = useDeviceState();
	const { logEvent } = useAnalytics();
	const AppStore = device.isMobile ? (device.os === 'AndroidOS' ? DownloadGooglePlay : AppleStore) : null;
	const { name, activeScreenConfig, logPageViewEvent } = useSimplifiedSignupContext();
	const {
		CTA,
		upsellBullets = [],
		isWithinModal,
		heading,
		subHeading,
		hideQRCode = {
			mobile: false,
			desktop: false,
		},
		hasTrueMedSupport = false,
	} = activeScreenConfig as HandoffScreenConfig;
	const [isMobile] = useIsMobile();

	useComponentDidMount(() => {
		logPageViewEvent('handoff');
	});

	async function _CTA(e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
		e?.preventDefault?.();
		logEvent({
			eventName: `${name} : Return : Clicked`,
			eventProps: {
				source: name,
				platform: device?.os || null,
				referrer: 'Payment',
			},
		});
		if (CTA) CTA.onClick(routerPush);
	}

	async function goToAppStore() {
		logEvent({
			eventName: `${name} : Install : Clicked`,
			eventProps: {
				source: name,
				platform: device?.os || null,
			},
		});
		await routerPush('https://calm.onelink.me/Pf5F/oislygnz');
	}

	const onTrueMedQualificationClick = () => {
		logEvent({
			eventName: 'TrueMed : Survey Link : Clicked',
		});
	};

	const getTrueMedQualificationLink = () => {
		return `https://app.truemed.com/qualify/${process.env.NEXT_PUBLIC_TRUEMED_PUBLIC_QUALIFICATION_ID}?user_id=${user?.id}`;
	};

	const getTrueMedQualificationCopy = () => {
		if (!user || !hasTrueMedSupport) {
			return null;
		}

		return (
			<TrueMedQualificationHeading>
				{formatMessage(messages.trueMedQualification, {
					qualifyHerelink: (...chunks: ReactNode[]) => (
						<TrueMedQualificationLink
							href={getTrueMedQualificationLink()}
							target="_blank"
							rel="noopener noreferrer"
							onClick={onTrueMedQualificationClick}
						>
							{chunks}
						</TrueMedQualificationLink>
					),
				})}
			</TrueMedQualificationHeading>
		);
	};

	return (
		<SimplifiedSignupHandoffContainer isWithinModal={isWithinModal} isCompact={isWithinModal}>
			<HandoffWelcomeContainer>
				<Text role="alert" aria-live="polite" el="h1" size={FontSizes['2xl']} weight={FontWeights.Medium}>
					{formatMessage(heading ? heading() : messages.handoffHeader)}
				</Text>
				<HandoffSubheading align="center" el="p" size={FontSizes.lg} weight={FontWeights.Regular}>
					{formatMessage(subHeading ?? messages.subHeading)}
				</HandoffSubheading>
				{getTrueMedQualificationCopy()}
				{AppStore && (
					<IconButton
						backgroundColor="transparent"
						aria-label={formatMessage(messages.toAppStore)}
						onPress={goToAppStore}
					>
						<AppStore />
					</IconButton>
				)}
			</HandoffWelcomeContainer>
			<AppDownloadContainer>
				<AppDownloadItemContainer isHidden={isMobile ? hideQRCode.mobile : hideQRCode.desktop}>
					<QRCodeWrapper>
						<Text align="center" el="h2" size={FontSizes.lg} weight={FontWeights.Medium}>
							{formatMessage(messages.qrCodeInstructions)}
						</Text>
						<QRCode>
							<Image
								src={'/_n/images/onboarding/handoff_skipped_qr.png'}
								alt="Scan QR code to download Calm app"
								height={148}
								width={148}
							/>
						</QRCode>
						<SocialWrapper>
							<AppleStore />
							<DownloadGooglePlay />
						</SocialWrapper>
					</QRCodeWrapper>
				</AppDownloadItemContainer>
				<AppDownloadItemContainer>
					<AppImageWrapper>
						<Image layout="fill" src={'/_n/images/dtc-partners/spotify-handoff-device.png'} alt="" />
					</AppImageWrapper>
				</AppDownloadItemContainer>
			</AppDownloadContainer>
			{upsellBullets.length > 0 && (
				<ValuePropsWrapper>
					<Text el="h3" size={FontSizes.lg} weight={FontWeights.Medium}>
						{formatMessage(messages.valuePropsHeader, {
							u: (...chunks: ReactNode[]) => <u>{chunks}</u>,
						})}
					</Text>
					<ValuePropsList copy={upsellBullets} buttonColor="blue3" />
					<Line />
				</ValuePropsWrapper>
			)}
			{CTA && (
				<CTAWrapper isWithinModal={isWithinModal}>
					{CTA.description && (
						<Text el="p" size={FontSizes.base}>
							{formatMessage(CTA.description)}
						</Text>
					)}
					{CTA.href ? (
						<CTALink href={CTA.href} onClick={_CTA}>
							{formatMessage(CTA.label)}
						</CTALink>
					) : (
						<TextButton onClick={_CTA}>{formatMessage(CTA.label)}</TextButton>
					)}
				</CTAWrapper>
			)}
		</SimplifiedSignupHandoffContainer>
	);
};

export default SimplifiedSignupHandoff;
