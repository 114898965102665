import PlanSkuItem, { PlanSkuItemProps } from '../PlanSkuItem';
import { PlanSkuListItem, Wrapper } from './styles';

const PlanSkuItems = ({
	skus,
	setActivePlan,
}: {
	skus: PlanSkuItemProps[];
	setActivePlan: (id: PlanSkuItemProps['id']) => void;
}) => {
	return (
		<Wrapper>
			{skus.map(sku => (
				<PlanSkuListItem key={sku.id}>
					<PlanSkuItem
						duration={sku.duration}
						originalPrice={sku.originalPrice || null}
						price={sku.price}
						overridePriceDisplay={sku.overridePriceDisplay || null}
						pricePerMonth={sku.pricePerMonth}
						active={sku.active}
						onClick={setActivePlan}
						id={sku.id}
						badge={sku.badge}
					/>
				</PlanSkuListItem>
			))}
		</Wrapper>
	);
};

export default PlanSkuItems;
