import { IncomingMessage, ServerResponse } from 'http';

import apiRequest from '@/utils/apiRequest';
import { isServerMaintenanceResponse } from '@/utils/apiRequest/isServerMaintenanceResponse';
import { userDataFromBrowser } from '@/utils/apiRequest/userDataFromBrowser';
import { userDataFromRequest } from '@/utils/apiRequest/userDataFromRequest';
import { calmLogger } from '@/utils/calmLogger';

export interface DeviceConsent {
	ip_country: string | undefined | null;
	requires_gdpr: boolean;
	requires_gdpr_choice: boolean;
	was_server_maintenance_error?: boolean;
}

/**
 * This request is always made as part of SSR
 * so it's been selected as the one that should check for
 * our API servers undergoing maintenance during SSR.
 * If this is no longer a request guaranteed to happen during SSR,
 * that check should move elsewhere
 */
export const getDeviceConsentAndCheckForServerMaintenance = async (
	hasAcceptGdprCookiesKey?: string,
	req?: IncomingMessage,
	res?: ServerResponse,
	deviceId?: string,
	calmIdentifier?: string | undefined,
): Promise<DeviceConsent> => {
	const isServer = req && deviceId;
	try {
		const result = await apiRequest<DeviceConsent>({
			endpoint: 'device/consent',
			method: 'GET',
			userData: isServer ? userDataFromRequest({ req, deviceId, calmIdentifier }) : userDataFromBrowser(),
		});
		const data = result?.data;

		return {
			...data,
			requires_gdpr_choice: data.requires_gdpr,
			requires_gdpr: hasAcceptGdprCookiesKey !== undefined ? !hasAcceptGdprCookiesKey : data.requires_gdpr,
		};
	} catch (err) {
		calmLogger.error('Error retrieving device consent via GET /device/consent', {}, err);

		if (res && isServerMaintenanceResponse(err)) {
			res.writeHead(302, {
				Location: '/server-maintenance',
			});
			res.end();
		}

		return {
			ip_country: null,
			requires_gdpr: false,
			requires_gdpr_choice: false,
			was_server_maintenance_error: isServerMaintenanceResponse(err),
		};
	}
};
