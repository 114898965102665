import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FormEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { AccessibleOnChange, FontSizes, FontWeights, FormInput, Text } from '@calm-web/design-system';

import CreditCardFormInputs from '@/components/purchase/CreditCardFormInputs';
import CreditCardIcons from '@/components/purchase/CreditCardIcons';
import {
	BEFORE_PURCHASE_STATE,
	CustomerAddress,
	PURCHASED_STATE,
	PURCHASING_STATE,
} from '@/components/purchase/PurchaseForm/types';
import PurchaseTerms from '@/components/purchase/PurchaseTerms';
import { useAnalytics } from '@/hooks/analytics';
import { usePartnerState, usePurchaseParamsState, useRecaptchaVisibleState } from '@/hooks/store';
import genericMessages from '@/messages/messages';
import { validate } from '@/utils/ui/validator';

import CustomerAddressInputs from '../CustomerAddressInputs';
import { getIsPostalCodeValid } from '../CustomerAddressInputs/utils';
import messages from './messages';
import { LockIcon, SecureTransactionContainer, SubmitButton, Wrapper } from './styles';
import { CreditCardFormProps } from './types';

const CreditCardForm = ({
	ctaCopy,
	hideTerms,
	hidePriceAfterDisclaimer = true,
	centerFormElements = false,
	analyticsPrefix = 'Subscribe : Purchase : Form',
	children,
	externalSubmit = false,
	purchaseState,
	purchaseTerms,
	firstName,
	setFirstName,
	setErrorCopy,
	onPurchaseSubmit,
	requestInfo,
	onPurchaseError,
	onPurchaseSuccess,
	isDisabled,
	setCurrentPaymentType,
	setFormIsValid = () => {},
	hideBillingAddressFields,
}: CreditCardFormProps) => {
	const recaptchaVisible = useRecaptchaVisibleState();
	const partner = usePartnerState();
	const { purchaseType } = usePurchaseParamsState();
	const partnerSpecificAnalytics = {
		partner_id: partner?.id,
		partner_type: [partner?.category?.toLowerCase() || null],
	};

	const { formatMessage } = useIntl();
	const elements = useElements();
	const stripe = useStripe();

	const { logEvent } = useAnalytics();

	const [isCCNumberValid, setIsCCNumberValid] = useState(false);
	const [isCvcValid, setIsCvcValid] = useState(false);
	const [isExpiryValid, setIsExpiryValid] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [customerAddress, setCustomerAddress] = useState<CustomerAddress>({});

	useEffect(() => {
		setIsLoading(purchaseState === PURCHASING_STATE || purchaseState === PURCHASED_STATE);
	}, [purchaseState]);

	const setAndValidateName: AccessibleOnChange = e => {
		const { value } = e.currentTarget;

		setCurrentPaymentType('credit_card');
		setFirstName(value);
	};

	const validateInputs = () => {
		if (!validate('name', firstName)) {
			return 'first name';
		}
		if (!isCCNumberValid) {
			return 'card number';
		}
		if (!isExpiryValid) {
			return 'card expiry';
		}
		if (!isCvcValid) {
			return 'card cvc';
		}

		if (!hideBillingAddressFields && !getIsPostalCodeValid(customerAddress)) {
			return 'postal code';
		}

		return false;
	};

	const onCreditCardSubmit = async (e: FormEvent) => {
		e.stopPropagation();
		e.preventDefault();

		if (purchaseState !== BEFORE_PURCHASE_STATE || validateInputs()) return;

		setCurrentPaymentType('credit_card');

		if (!stripe || !elements) return;
		if (purchaseState !== BEFORE_PURCHASE_STATE) return;

		setErrorCopy(null);

		logEvent({
			eventName: 'Credit Card Button : Clicked',
			eventProps: {
				...(partner ? partnerSpecificAnalytics : {}),
			},
		});

		const invalidInput = validateInputs();

		if (invalidInput) {
			logEvent({
				eventName: `${analyticsPrefix} : Field Error`,
				eventProps: {
					...(invalidInput ? { field: invalidInput } : {}),
				},
			});
			return;
		}

		const cardElement = elements.getElement(CardNumberElement);

		if (!cardElement) return;

		const data = await onPurchaseSubmit({
			cardInfo: cardElement,
			userInfo: { name: firstName },
			requestInfo,
			customerAddress,
		});

		if (data?.error) {
			onPurchaseError(data.error);
		} else {
			await onPurchaseSuccess(data.success?.subscription ?? data.success, 'credit_card');
		}
	};

	const getButtonText = (): string => {
		if (ctaCopy) {
			return ctaCopy;
		}

		if (purchaseType?.type === 'freetrial' && purchaseState === BEFORE_PURCHASE_STATE) {
			return formatMessage(messages.startFreeTrial);
		}
		// All other subscribe cases
		if (purchaseState === BEFORE_PURCHASE_STATE) {
			return formatMessage(messages.useCard);
		}

		if (purchaseState === PURCHASED_STATE) {
			return formatMessage(messages.success);
		}

		return formatMessage(genericMessages.submit);
	};

	useEffect(() => {
		const nameIsValid = Boolean(validate('name', firstName));
		const isZipValid = getIsPostalCodeValid(customerAddress);
		setFormIsValid(isCCNumberValid && isCvcValid && isExpiryValid && nameIsValid && isZipValid);
	}, [isCCNumberValid, isCvcValid, isExpiryValid, firstName, customerAddress, setFormIsValid]);

	return (
		<Wrapper id="credit_card_form" onSubmit={onCreditCardSubmit} centerFormElements={centerFormElements}>
			<CreditCardIcons centerFormElements={centerFormElements} />
			<FormInput
				name="name"
				label={formatMessage(messages.firstName)}
				value={firstName}
				isValid={validate('name', firstName)}
				onChange={setAndValidateName}
				error={formatMessage(messages.firstNameError)}
				autoComplete="cc-name"
			/>
			<CreditCardFormInputs
				analyticsPrefix={analyticsPrefix}
				setIsCCNumberValid={setIsCCNumberValid}
				setIsCvcValid={setIsCvcValid}
				setIsExpiryValid={setIsExpiryValid}
			/>
			{!hideBillingAddressFields && (
				<CustomerAddressInputs setCustomerAddress={setCustomerAddress} analyticsPrefix={analyticsPrefix} />
			)}
			{!hideTerms && (
				<PurchaseTerms hidePriceAfterDisclaimer={hidePriceAfterDisclaimer} purchaseTerms={purchaseTerms} />
			)}
			{children}
			{!externalSubmit && (
				<>
					<SubmitButton
						fullWidth
						backgroundColor="buttonBlue"
						textColor="white"
						type="submit"
						isLoading={isLoading}
						isDisabled={isDisabled || Boolean(validateInputs()) || !stripe || recaptchaVisible}
						data-testid="subscribe-submit-btn"
					>
						{getButtonText()}
					</SubmitButton>
					<SecureTransactionContainer>
						<LockIcon aria-hidden data-testid="lock-icon" />
						<Text size={FontSizes.sm} weight={FontWeights.Medium} color="gray5">
							{formatMessage(messages.secureTransaction)}
						</Text>
					</SecureTransactionContainer>
				</>
			)}
		</Wrapper>
	);
};

export default CreditCardForm;
