import {
	stressDailyCommitmentMessages as dailyCommitmentMessages,
	experienceStressMessages,
	meditationExperienceMessages,
	moodPulseMessages,
	onboardingMessages,
	pacingMessages,
	qualityOfLifeMessages,
	stateOfMindMessages,
	stressSourceMessages,
	timePreferenceMessages,
} from '../../ConfigData/MessageSets';
import { meditationExperienceChildren, moodPulseResponse } from '../../ConfigData/PollSets';
import {
	experienceStressTiles,
	meditationExperienceTiles,
	moodPulseTiles,
	pacingTiles,
	stateOfMindTiles,
	stressSourceTiles,
	timePreferenceTiles,
} from '../../ConfigData/QuestionTileSets';
import { SignupScreens } from '../../PreSignupProvider/types';

export const stressPreSignupScreens: SignupScreens = {
	start: {
		componentName: 'WelcomeScreen',
		analyticsName: 'FTUE : Transition',
		transitionType: 'welcome_screen',
		nextScreen: 'moodPulse',
		hasSkip: true,
	},
	moodPulse: {
		componentName: 'PollWithResponse',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'mood_question',
		nextScreen: 'moodPulseSelected',
		tiles: moodPulseTiles,
		question: moodPulseMessages.question,
	},
	moodPulseSelected: {
		componentName: 'PollWithResponse',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'mood_question',
		nextScreen: 'stressSource',
		tiles: moodPulseTiles,
		question: moodPulseMessages.question,
		isSelectedScreen: true,
		createResponse: moodPulseResponse,
	},
	stressSource: {
		componentName: 'PollSingleClick',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'stress_source',
		nextScreen: 'experienceStress',
		question: stressSourceMessages.question,
		tiles: stressSourceTiles,
	},
	experienceStress: {
		componentName: 'PollMultiClick',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'experience_stress',
		nextScreen: 'qualityOfLife',
		question: experienceStressMessages.question,
		tiles: experienceStressTiles,
		cta: onboardingMessages.cta,
	},
	qualityOfLife: {
		componentName: 'TestimonialScreen',
		analyticsName: 'FTUE : Transition',
		transitionType: 'quality_of_life_testimonial',
		nextScreen: 'meditationExperience',
		quote: qualityOfLifeMessages.quote,
		author: qualityOfLifeMessages.author,
		isDark: true,
	},
	meditationExperience: {
		componentName: 'PollWithResponse',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'meditation_experience',
		nextScreen: 'meditationExperienceSelected',
		question: meditationExperienceMessages.question,
		tiles: meditationExperienceTiles,
	},
	meditationExperienceSelected: {
		componentName: 'PollWithResponse',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'meditation_experience',
		nextScreen: 'pacing',
		question: meditationExperienceMessages.question,
		tiles: meditationExperienceTiles,
		isSelectedScreen: true,
		createResponse: meditationExperienceChildren,
	},
	pacing: {
		componentName: 'PollSingleClick',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'pacing',
		nextScreen: 'stateOfMind',
		question: pacingMessages.question,
		tiles: pacingTiles,
		cta: pacingMessages.cta,
	},
	stateOfMind: {
		componentName: 'PollSingleClick',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'state_of_mind',
		nextScreen: 'timePreference',
		question: stateOfMindMessages.question,
		tiles: stateOfMindTiles,
		cta: stateOfMindMessages.cta,
	},
	timePreference: {
		componentName: 'PollSingleClick',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'meditation_time_preference',
		nextScreen: 'dailyCommitment',
		question: timePreferenceMessages.question,
		tiles: timePreferenceTiles,
		cta: timePreferenceMessages.cta,
	},
	dailyCommitment: {
		componentName: 'TestimonialScreen',
		analyticsName: 'FTUE : Transition',
		transitionType: 'daily_commitment_testimonial',
		quote: dailyCommitmentMessages.quote,
		author: dailyCommitmentMessages.author,
	},
	ineligible: {
		componentName: 'Ineligible',
		analyticsName: 'Free Access - Ineligible',
		backButtonHidden: true,
	},
};
