import { useRouter } from 'next/router';
import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, FontSizes, FontWeights, FormInput, Loader, Text } from '@calm-web/design-system';

import { useDTCPartners } from '@/hooks/dtcLeadGenPartnership/useDTCPartners';
import { useAuthFormInputs } from '@/hooks/forms/useAuthFormInputs';
import { usePartnerState } from '@/hooks/store';
import genericMessages from '@/messages/messages';

import messages from './messages';
import { PartnerValidationWrapper } from './styles';

const PartnershipValidation = ({
	isVerified,
	setIsVerified,
	setPartnerUserId,
}: {
	isVerified: boolean;
	setIsVerified: Dispatch<SetStateAction<boolean>>;
	setPartnerUserId: Dispatch<SetStateAction<string>>;
}) => {
	const [inputFields, inputActions] = useAuthFormInputs(['uniqueId'] as const);
	const partner = usePartnerState();
	const { checkPartnerUserEligibility } = useDTCPartners();
	const { formatMessage } = useIntl();
	const isAARP = partner?.name.toLowerCase() === 'aarp';
	const { query } = useRouter();

	const inputLabel = isAARP ? messages.validationInputLabel_AARP : messages.validationInputLabel;
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | undefined>();
	const [isLoadingURLValidation, setIsLoadingURLValidation] = useState(true);
	const [ranIsLoadingURLValidation, setRanIsLoadingURLValidation] = useState(false);

	const validatePartnerUserEligibility = useCallback(
		async (id: string) => {
			setIsLoading(true);
			try {
				const isEligible = await checkPartnerUserEligibility(partner, id);
				setIsVerified(isEligible);
				setPartnerUserId(id);
				setError(undefined);
			} catch (error) {
				setIsVerified(false);
				if (isAARP) {
					setError(formatMessage(messages.validationError_AARP));
				} else {
					setError(
						formatMessage(messages.validationError, {
							partnerName: partner?.name,
						}),
					);
				}
			} finally {
				setIsLoading(false);
			}
		},
		[checkPartnerUserEligibility, formatMessage, isAARP, partner, setPartnerUserId, setIsVerified],
	);

	useEffect(() => {
		const alreadyChecked = inputFields.uniqueId.value === query?.partner_user_id;
		if (query?.partner_user_id && !alreadyChecked) {
			inputFields.uniqueId.value = query?.partner_user_id as string;
			setIsLoadingURLValidation(true);
			setRanIsLoadingURLValidation(true);
			validatePartnerUserEligibility(query?.partner_user_id as string)
				.then(() => setIsLoadingURLValidation(false))
				.catch(() => setIsLoadingURLValidation(false));
		} else {
			setIsLoadingURLValidation(false);
		}
	}, [query, inputFields.uniqueId, validatePartnerUserEligibility]);

	return (
		<PartnerValidationWrapper>
			{isLoadingURLValidation ? (
				<Loader color="blue4" />
			) : (
				<>
					{ranIsLoadingURLValidation && !isVerified ? (
						<Text el="h2" size={FontSizes.lg} weight={FontWeights.Medium}>
							{formatMessage(messages.invalidOfferCodeId)}
						</Text>
					) : (
						<>
							<Text el="h2" size={FontSizes.lg} weight={FontWeights.Medium}>
								{formatMessage(inputLabel)}
							</Text>
							<form
								onSubmit={async e => {
									e.preventDefault();
									await validatePartnerUserEligibility(inputFields.uniqueId.value);
								}}
								id="submit-eligibility-form"
							>
								<FormInput
									name="uniqueId"
									label={
										isAARP
											? formatMessage(messages.validationInputPlaceholder_AARP)
											: formatMessage(messages.validationInputPlaceholder)
									}
									onBlur={inputActions.onBlur}
									onChange={inputActions.onChange}
									onFocus={inputActions.onFocus}
									value={inputFields.uniqueId?.value}
									isValid={Boolean(inputFields.uniqueId?.value) && !error}
									error={error}
								/>
								<Button
									backgroundColor="buttonPrimaryBg"
									type="submit"
									isDisabled={!inputFields.uniqueId?.value}
									isLoading={isLoading}
								>
									{formatMessage(genericMessages.submit)}
								</Button>
							</form>
							{isAARP && (
								<>
									<br />
									<Text size={FontSizes.sm} weight={FontWeights.Light}>
										{formatMessage(messages.linkToAARP, {
											join: (...chunks: ReactNode[]) => (
												<a
													href="https://appsec.aarp.org/mem/join?campaignId=FFPUCALL&cmp=ASI_P_MU_JN_CALM"
													target="_blank"
													rel="noreferrer"
												>
													{chunks}
												</a>
											),
											renew: (...chunks: ReactNode[]) => (
												<a
													href="https://appsec.aarp.org/mem/renew?campaignId=FFPUCALL&cmp=ASI_P_MU_RN_CALM"
													target="_blank"
													rel="noreferrer"
												>
													{chunks}
												</a>
											),
										})}
									</Text>
								</>
							)}
						</>
					)}
				</>
			)}
		</PartnerValidationWrapper>
	);
};

export default PartnershipValidation;
