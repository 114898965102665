import { IncomingMessage } from 'http';

import { Device } from '@/store/device/types';
import apiRequest from '@/utils/apiRequest';
import { userDataFromBrowser } from '@/utils/apiRequest/userDataFromBrowser';
import { userDataFromRequest } from '@/utils/apiRequest/userDataFromRequest';
import { calmLogger } from '@/utils/calmLogger';
import { getDeviceDataFromUserAgentString } from '@/utils/device';

export const getDevice = async (
	userAgent?: string,
	req?: IncomingMessage,
	deviceId?: string,
	calmIdentifier?: string | undefined,
): Promise<Device> => {
	const isServer = req && deviceId;
	const isZoomApp = userAgent?.includes('ZoomApps');
	const deviceBasic = {
		deviceId,
		isZoomApp,
		...getDeviceDataFromUserAgentString(userAgent),
	};

	if (!userAgent) {
		return deviceBasic;
	}

	try {
		const result = await apiRequest({
			endpoint: 'device',
			method: 'GET',
			userData: isServer ? userDataFromRequest({ req, deviceId, calmIdentifier }) : userDataFromBrowser(),
		});
		const data = result?.data;
		const device = {
			...data,
			...deviceBasic,
		};

		return !data ? deviceBasic : device;
	} catch (err) {
		calmLogger.error('Error retrieving device via GET /device', {}, err);
		return deviceBasic;
	}
};
