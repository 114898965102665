import { defineMessages } from 'react-intl';

export default defineMessages({
	confirmPlan: {
		id: 'purchase.sku.toggle.header',
		defaultMessage: 'Confirm Your Plan',
		description: 'Instructions for the user to select which plan they want',
	},
	confirmSubtitle: {
		id: 'purchase.sku.toggle.subheader',
		defaultMessage: "Don't worry, you can cancel at any time.",
		description: 'Instructions informing the user they can cancel their sub',
	},
	totalDue: {
		id: 'creditCardForm.totalDue',
		defaultMessage: 'Total due today',
		description: "A line which adds up a user's purchase total",
	},
	terms: {
		id: 'purchase.sku.toggle.terms',
		defaultMessage:
			'{amount} days free, then {monthly}/month, billed annually at {yearly}/year. <cancelAnytimeLink>Cancel anytime.</cancelAnytimeLink>',
		description: 'A line to inform the user of the terms of their trial',
	},
	amountPerPersonIndividual: {
		id: 'purchase.sku.amountPerPersonIndividual',
		defaultMessage: '{amount}/person',
		description: 'The price per person for an individual plan',
	},
	amountPerPersonFamily: {
		id: 'purchase.sku.amountPerPersonFamily',
		defaultMessage: 'as low as {amount}/person',
		description: 'The price per person for a family plan',
	},
	numPeopleIndividual: {
		id: 'purchase.sku.numPeopleIndividual',
		defaultMessage: '1 person',
		description: 'The number of people for an individual plan',
	},
	numPeopleFamily: {
		id: 'purchase.sku.numPeopleFamily',
		defaultMessage: 'Up to 6 people',
		description: 'The number of people for a family plan',
	},
	trialLabel: {
		id: 'paymentForm.year.trialLabel',
		defaultMessage: '14-Day Free Trial',
		description: 'A label for the sku that offers a 14 day trial',
	},
	customTrialLabel: {
		id: 'paymentForm.year.customTrialLabel',
		defaultMessage: '{amount}-Day Free Trial',
		description: 'A label for the sku that offers a custom day trial',
	},
	couponLabel: {
		id: 'paymentForm.year.couponLabel',
		defaultMessage: '{amount}% off',
		description: 'A label for the sku that offers a coupon',
	},
	greatDealBadge: {
		id: 'purchase.sku.greatDealBadge',
		defaultMessage: 'Great Deal',
		description: 'A badge that indicates a great deal',
	},
	yearDuration: {
		id: 'purchase.sku.yearlyDuration',
		defaultMessage: '12 Months',
		description: 'Text indicating 12 months duration',
	},
	quarterDuration: {
		id: 'purchase.sku.quarterlyDuration',
		defaultMessage: '3 Months',
		description: 'Text indicating 3 months duration',
	},
});
