import {
	Description,
	Image,
	ImageOverlay,
	Subtitle,
	Title,
	TitleSection,
	WhiteTile,
	Wrapper,
} from './styles';

interface Props {
	imageUrl: string;
	title?: string;
	className?: string;
	description?: string;
	subtitle?: string;
}

function Thumbnail({ imageUrl, title, className, description, subtitle }: Props): JSX.Element {
	return (
		<Wrapper className={className}>
			<WhiteTile />
			<WhiteTile />
			<Image src={imageUrl}>
				<ImageOverlay />
				<TitleSection>
					{description && <Description>{description}</Description>}
					{title && <Title>{title}</Title>}
				</TitleSection>
				{subtitle && <Subtitle>{subtitle}</Subtitle>}
			</Image>
		</Wrapper>
	);
}

export default Thumbnail;
