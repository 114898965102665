import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, palette } from 'styled-tools';

import { Card as _Card, Collapsible as _Collapsible, minWidth } from '@calm-web/design-system';

import ValuePropsList from '@/components/valuePropList';

import { PageWrap } from '../styles';

export const AuthColumnWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 700px;
	h1 {
		margin-bottom: 8px;
	}
`;

export const Collapsible = styled(_Collapsible)`
	> span > div {
		flex-direction: row-reverse;
		justify-content: space-between;

		span > svg {
			transform: rotate(0deg);
		}
	}

	> span.is-open > div > span > svg {
		transform: rotate(180deg);
	}
`;

export const Card = styled(_Card)<{
	isWithinModal?: boolean;
}>`
	border-radius: 20px !important;
	border-color: ${palette('white')};
	text-align: center;

	${minWidth('desktop')} {
		padding: 48px !important;
	}
`;

export const ValuePropsListWrapper = styled.div<{
	showHeader?: boolean;
	isWithinModal?: boolean;
}>`
	text-align: center;
	width: 100%;

	${minWidth('tablet')} {
		margin-top: ${ifProp('showHeader', 0, ifProp('isWithinModal', 0, '110px'))};
	}

	${minWidth('desktop')} {
		max-width: 420px;
	}

	h3 {
		text-align: center;
	}
`;

export const AccountValuePropsList = styled(ValuePropsList)`
	margin-top: 0;
	padding-top: 0;
	padding-bottom: 40px;

	${minWidth('desktop')} {
		padding-bottom: 0;
	}
`;

export const FAQWrapper = styled.div<{ isCompact?: boolean }>`
	background: ${palette('gray0')};
	padding-bottom: 150px;
	min-height: 100vh;

	${minWidth('tablet')} {
		${ifNotProp(
			'isCompact',
			css`
				padding-top: 80px;
			`,
		)}
	}
`;

export const FAQContentWrapper = styled.div`
	max-width: 800px;
	margin: auto;
	padding-top: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;

	${minWidth('tablet')} {
		padding-top: 80px;
	}
`;

export const HorizontalLine = styled.div`
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	margin-bottom: 20px;
	margin-top: 20px;
`;

export const AccountPageWrap = styled(PageWrap)<{
	backgroundKey?: string;
}>`
	background: ${({ backgroundKey }) => palette(backgroundKey ?? 'gradientCalmBrandDark')};
`;
