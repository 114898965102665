import { Tile } from '@/components/ui/QuestionnaireTile/types';

import {
	contentPreferencesMessages,
	experienceStressMessages,
	fallStayAsleepMessages,
	meditationExperienceMessages,
	moodPulseMessages,
	pacingMessages,
	sleepNowLaterMessages,
	sleepPollAnswers,
	stateOfMindMessages,
	stopsSleepingMessages,
	stressAnxietyPollAnswers,
	stressSourceMessages,
	timePreferenceMessages,
	troubleSleepingMessages,
} from './MessageSets';

//**************** Stress Flow Tiles

export const pacingTiles: Tile[] = [
	{
		id: 'fast',
		title: pacingMessages.fast,
	},
	{
		id: 'slow_steady',
		title: pacingMessages.slowSteady,
	},
	{
		id: 'somewhere_in_between',
		title: pacingMessages.somewhereInBetween,
	},
];

export const stateOfMindTiles: Tile[] = [
	{
		id: 'ready',
		title: stateOfMindMessages.ready,
	},
	{
		id: 'hopeful',
		title: stateOfMindMessages.hopeful,
	},
	{
		id: 'cautious',
		title: stateOfMindMessages.cautious,
	},
];

export const stressSourceTiles: Tile[] = [
	{
		id: 'money',
		title: stressSourceMessages.answerMoney,
	},
	{
		id: 'work_or_school',
		title: stressSourceMessages.answerWork,
	},
	{
		id: 'health',
		title: stressSourceMessages.answerHealth,
	},
	{
		id: 'relationships',
		title: stressSourceMessages.answerRelationships,
	},
	{
		id: 'family_responsibilities',
		title: stressSourceMessages.answerFamily,
	},
	{
		id: 'other',
		title: stressSourceMessages.answerOther,
	},
];

export const timePreferenceTiles: Tile[] = [
	{
		id: 'morning',
		title: timePreferenceMessages.answerMorning,
	},
	{
		id: 'afternoon',
		title: timePreferenceMessages.answerAfternoon,
	},
	{
		id: 'night',
		title: timePreferenceMessages.answerNight,
	},
];

export const experienceStressTiles: Tile[] = [
	{
		id: 'anxious_thoughts',
		title: experienceStressMessages.answerAnxious,
	},
	{
		id: 'physical_discomfort',
		title: experienceStressMessages.answerDiscomfort,
	},
	{
		id: 'moodiness',
		title: experienceStressMessages.answerMoodiness,
	},
	{
		id: 'difficulty_sleeping',
		title: experienceStressMessages.answerSleeping,
	},
];

export const meditationExperienceTiles: Tile[] = [
	{
		id: 'none',
		title: meditationExperienceMessages.answerNone,
	},
	{
		id: 'tried',
		title: meditationExperienceMessages.answerFew,
	},
	{
		id: 'a_lot',
		title: meditationExperienceMessages.answerLot,
	},
];

export const moodPulseTiles: Tile[] = [
	{
		id: 'good',
		title: moodPulseMessages.answerGood,
	},
	{
		id: 'stressed',
		title: moodPulseMessages.answerStressed,
	},
	{
		id: 'sad',
		title: moodPulseMessages.answerSad,
	},
	{
		id: 'indifferent',
		title: moodPulseMessages.answerIndifferent,
	},
];
//**************** End Stress Flow Tiles

//**************** Sleep Flow Tiles
export const troubleSleepingTiles: Tile[] = [
	{
		id: 'occasionally',
		title: troubleSleepingMessages.answerOccasionally,
	},
	{
		id: 'frequently',
		title: troubleSleepingMessages.answerFrequently,
	},
	{
		id: 'every_night',
		title: troubleSleepingMessages.answerEveryNight,
	},
];

export const fallStayAsleepTiles: Tile[] = [
	{
		id: 'falling_asleep',
		title: fallStayAsleepMessages.answerFalling,
	},
	{
		id: 'staying_asleep',
		title: fallStayAsleepMessages.answerStaying,
	},
	{
		id: 'both',
		title: fallStayAsleepMessages.answerBoth,
	},
];

export const stopsSleepingTiles: Tile[] = [
	{
		id: 'worries_future',
		title: stopsSleepingMessages.answerEvents,
	},
	{
		id: 'worries_not_sleeping',
		title: stopsSleepingMessages.answerSleeping,
	},
	{
		id: 'body_discomfort',
		title: stopsSleepingMessages.answerDiscomfort,
	},
	{
		id: 'stress',
		title: stopsSleepingMessages.answerStress,
	},
	{
		id: 'noise',
		title: stopsSleepingMessages.answerNoise,
	},
	{
		id: 'other',
		title: stopsSleepingMessages.answerOther,
	},
];

export const sleepNowLaterTiles: Tile[] = [
	{
		id: 'ready_to_sleep',
		title: sleepNowLaterMessages.answerYes,
	},
	{
		id: 'sleep_later',
		title: sleepNowLaterMessages.answerNo,
	},
];

export const contentPreferencesTiles: Tile[] = [
	{
		id: 'bedtime_stories',
		title: contentPreferencesMessages.answerStories,
	},
	{
		id: 'nature_sounds',
		title: contentPreferencesMessages.answerNature,
	},
	{
		id: 'sleep_soundscapes',
		title: contentPreferencesMessages.answerSoundscapes,
	},
	{
		id: 'relaxing_music',
		title: contentPreferencesMessages.answerMusic,
	},
	{
		id: 'sleep_meditations',
		title: contentPreferencesMessages.answerMeditations,
	},
];
// **************** End Sleep Flow Tiles

//****************** UA Custom Flows
//***** Common Tiles
export const howOftenTiles: Tile[] = [
	{
		id: 'occasionally',
		title: stressAnxietyPollAnswers.answerOccasionally,
	},
	{
		id: 'frequently',
		title: stressAnxietyPollAnswers.answerFrequently,
	},
	{
		id: 'every_night',
		title: stressAnxietyPollAnswers.answerEveryDay,
	},
	{
		id: 'never',
		title: stressAnxietyPollAnswers.never,
	},
];

//** Stress and Anxiety Tiles */
export const stressCurrentStateTiles: Tile[] = [
	{
		id: 'dont_know_how',
		title: stressAnxietyPollAnswers.dontKnowHow,
	},
	{
		id: 'try_different_things',
		title: stressAnxietyPollAnswers.tryDifferentThings,
	},
	{
		id: 'distract',
		title: stressAnxietyPollAnswers.distract,
	},
	{
		id: 'none_of_the_above',
		title: stressAnxietyPollAnswers.noneOfTheAbove,
	},
];

export const stressIdealStateTiles: Tile[] = [
	{
		id: 'calm_and_happy',
		title: stressAnxietyPollAnswers.calmAndHappy,
	},
	{
		id: 'in_control',
		title: stressAnxietyPollAnswers.inControl,
	},
	{
		id: 'more_present',
		title: stressAnxietyPollAnswers.morePresent,
	},
	{
		id: 'all_of_the_above',
		title: stressAnxietyPollAnswers.allOfTheAbove,
	},
];

//** Sleep Tiles */
export const sleepCurrentStateTiles: Tile[] = [
	{
		id: 'dont_know_how',
		title: sleepPollAnswers.dontKnowHow,
	},
	{
		id: 'try_different_things',
		title: sleepPollAnswers.tryDifferentThings,
	},
	{
		id: 'sleeping_aids',
		title: sleepPollAnswers.sleepingAids,
	},
	{
		id: 'none_of_the_above',
		title: sleepPollAnswers.noneOfTheAbove,
	},
];

export const sleepIdealStateTiles: Tile[] = [
	{
		id: 'calm_and_happy',
		title: sleepPollAnswers.energized,
	},
	{
		id: 'in_control',
		title: sleepPollAnswers.betterMood,
	},
	{
		id: 'more_present',
		title: sleepPollAnswers.lessStressed,
	},
	{
		id: 'all_of_the_above',
		title: sleepPollAnswers.allOfTheAbove,
	},
];
