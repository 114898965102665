import uuid from 'uuid/v4';

import { getCookie, setCookie } from '../cookies';
import { RequestUserData } from './types';

export function userDataFromBrowser(): RequestUserData {
	const deviceIdCookieValue = getCookie('x-device-id');
	const userToken = getCookie('calm-user-token');
	const calmIdentifier = getCookie('calm-id');

	const deviceId: string = deviceIdCookieValue || uuid();
	if (deviceId !== deviceIdCookieValue) {
		setCookie('x-device-id', deviceId);
	}

	return {
		deviceId,
		userToken,
		calmIdentifier,
		path: window?.location?.href,
		cookies: undefined,
	};
}
