import { AxiosResponse } from 'axios';
import qs from 'query-string';

import { browserApiRequest } from '../apiRequest/browserApiRequest';

export async function getPartnerInfo(
	partnerIdentifiers: { slug: string; id?: string } | { slug?: string; id: string },
): Promise<AxiosResponse> {
	if (partnerIdentifiers.id) {
		return browserApiRequest({
			endpoint: `b2b/partners/${partnerIdentifiers.id}`,
			method: 'GET',
		});
	}
	const response = await browserApiRequest({
		endpoint: `b2b/partners?${qs.stringify({
			'filter[slug]': partnerIdentifiers.slug,
			show_offer_details: true,
		})}`,
		method: 'GET',
	});
	// To the caller of this function, just represent this as if only one partner was fetched
	if (response.data.partners && Array.isArray(response.data.partners) && response.data.partners.length > 0) {
		// eslint-disable-next-line prefer-destructuring
		response.data.partner = response.data.partners[0];
	} else {
		// To the caller of this function, just no found partners as a 404
		response.data.error = { code: 'b2b_partner_not_found' };
		// We throw errors, not return them
		throw response;
	}
	return response;
}
