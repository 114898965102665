import styled from 'styled-components';
import { palette } from 'styled-tools';

import {
	fontSize,
	FontSizes,
	FontWeights,
	minWidth,
	Text,
	TextElements,
	units,
} from '@calm-web/design-system';

import FullscreenBackground from '@/components/image/FullscreenBackground';
import FullscreenOverlay from '@/components/image/FullscreenOverlay';
import GridWrapper from '@/components/ui/GridWrapper';

export const Wrapper = styled.div`
	position: relative;
`;

export const Grid = styled(GridWrapper)`
	min-height: 100vh;
	max-width: 480px;

	${minWidth('tablet')} {
		grid-template-columns: 1fr;
	}

	${minWidth('desktop')} {
		grid-template-columns: 1fr;
		max-width: 960px;
	}

	${minWidth('desktopLarge')} {
		max-width: 1168px;
	}
`;

export const Background = styled(FullscreenBackground)`
	z-index: -1;
`;

export const Overlay = styled(FullscreenOverlay)`
	z-index: 0;
`;

export const ContentWrapper = styled.div<{
	$centerContent: boolean;
}>`
	display: flex;
	flex-flow: column wrap;
	width: 100%;
	-webkit-box-pack: center;
	max-width: 1040px;
	padding-left: ${units(2.5)};
	padding-right: ${units(2.5)};
	box-sizing: border-box;
	align-items: center;
	color: ${palette('white')};
	flex-direction: column;
	margin: 0 auto ${units(3)};
	padding-top: 136px;
	position: relative;
	text-align: center;
	z-index: 1;

	${minWidth('desktop')} {
		align-items: flex-start;
		justify-content: ${p => (p.$centerContent ? 'center' : 'flex-start')};
		margin-bottom: ${units(8.5)};
		text-align: left;
		padding-top: 0;
	}
`;

export const HeroText = styled(Text).attrs({
	el: TextElements.H2,
	weight: FontWeights.Light,
})<{
	$textSize: 'large' | 'small';
}>`
	${p => fontSize(p.$textSize === 'small' ? FontSizes.base : FontSizes['3xl'])};
	margin-bottom: ${units(1)};

	${minWidth('tablet')} {
		${p => fontSize(p.$textSize === 'small' ? FontSizes['3xl'] : FontSizes['4xl'])};
	}
`;

export const HeroSubText = styled(Text).attrs({
	el: TextElements.H4,
	size: FontSizes.base,
	weight: FontWeights.Regular,
})`
	margin-bottom: ${units(3)};

	${minWidth('tablet')} {
		${fontSize(FontSizes.xl)};
	}
`;
