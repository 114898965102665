import styled from 'styled-components';
import { theme } from 'styled-tools';

import {
	ButtonSizes,
	Button as DesignSystemButton,
	fontSize,
	FontSizes,
	minWidth,
} from '@calm-web/design-system';

import { NavButtonProps } from '@/components/header/DesktopNav/types';

export const Wrapper = styled.div`
	align-items: center;
	display: flex;
	margin-left: auto;

	${minWidth('tablet')} {
		display: none;
	}

	ul {
		padding: 0;
		align-items: center;
		display: flex;
	}
`;

export const NavButton = styled(DesignSystemButton).attrs({
	size: ButtonSizes.Sm,
})<NavButtonProps>`
	border: ${({ isDark }): string => `2px ${isDark ? '#fff' : '#000'} solid`};
	&:focus {
		border: 2px solid transparent;
	}
	${fontSize(FontSizes.base)}
	line-height: 125%;
	margin-right: 8px;
	padding: 0 18px;
	height: 48px;
	transition: ${theme('animation.transition')};
`;

export const NavItem = styled.a`
	font-size: 28px;
	text-decoration: none;
`;
