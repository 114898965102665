import { GiftPrice } from '@/store/purchaseParams/types';

const annualGiftCoupons: string[] = [
	'holiday_annual_gift_2024',
	'annualgift_20_2025',
	'annualgift_30_2025',
	'annualgift_40_2025',
];

const ORIGINAL_ANNUAL_PRICE = 6999;
const DISCOUNT_40PERCENT_ANNUAL_PRICE = 4199;
const DISCOUNT_30PERCENT_ANNUAL_PRICE = 4899;
const DISCOUNT_20PERCENT_ANNUAL_PRICE = 5599;

const lifetimeGiftCoupons: string[] = [];

const ORIGINAL_LIFETIME_PRICE = 39999;
const DISCOUNT_LIFETIME_PRICE = 15999;

const ORIGINAL_THREE_MONTHS_PRICE = 3499;

const discountAnnnualPrice = (coupon: string) => {
	switch (coupon) {
		case 'annualgift_40_2025':
		case 'holiday_annual_gift_2024':
			return DISCOUNT_40PERCENT_ANNUAL_PRICE;
		case 'annualgift_30_2025':
			return DISCOUNT_30PERCENT_ANNUAL_PRICE;
		case 'annualgift_20_2025':
			return DISCOUNT_20PERCENT_ANNUAL_PRICE;
		default:
			return ORIGINAL_ANNUAL_PRICE;
	}
};

export const isGiftCoupon = (coupon: string): boolean => {
	return annualGiftCoupons.includes(coupon) || lifetimeGiftCoupons.includes(coupon);
};

export const initGiftPricing = (
	type: 'annual' | 'lifetime' | 'quarter',
	coupon?: string | null,
): GiftPrice => {
	if (type === 'quarter') {
		return {
			originalPrice: ORIGINAL_THREE_MONTHS_PRICE,
			price: ORIGINAL_THREE_MONTHS_PRICE,
			currency: 'USD',
		};
	}

	if (type === 'annual') {
		const hasAnnualCoupon = coupon && annualGiftCoupons.includes(coupon);

		return {
			originalPrice: ORIGINAL_ANNUAL_PRICE,
			price: hasAnnualCoupon ? discountAnnnualPrice(coupon) : ORIGINAL_ANNUAL_PRICE,
			currency: 'USD',
		};
	}

	if (type === 'lifetime') {
		const hasLifetimeCoupon = coupon && lifetimeGiftCoupons.includes(coupon);

		return {
			originalPrice: ORIGINAL_LIFETIME_PRICE,
			price: hasLifetimeCoupon ? DISCOUNT_LIFETIME_PRICE : ORIGINAL_LIFETIME_PRICE,
			currency: 'USD',
		};
	}

	return null;
};
