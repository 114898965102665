import {
	createContext,
	Dispatch,
	ReactElement,
	Reducer,
	ReducerAction,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { FORM_STATES, useFormState } from '@/hooks/forms';
import { useAuthFormModeState } from '@/hooks/store';

interface FormStateContextState {
	formState: string;
	formDispatch: Dispatch<ReducerAction<Reducer<string, { type: string }>>>;
	formSuccess: string | null;
	setFormSuccess: Dispatch<SetStateAction<string | null>>;
	formError: string | null;
	setFormError: Dispatch<SetStateAction<string | null>>;
}

export const FormStateContext = createContext<FormStateContextState>({
	formState: FORM_STATES.idle,
	formDispatch: () => {},
	formSuccess: null,
	setFormSuccess: () => {},
	formError: null,
	setFormError: () => {},
});

export const FormStateContextProvider = ({ children }: { children?: ReactElement }) => {
	const authFormMode = useAuthFormModeState();

	const [formState, formDispatch] = useFormState();
	const [formSuccess, setFormSuccess] = useState<string | null>(null);
	const [formError, setFormError] = useState<string | null>(null);

	useEffect(() => {
		setFormError(null);
		return () => {
			setFormError(null);
		};
	}, [authFormMode]);

	const value = useMemo(
		() => ({
			formState,
			formDispatch,
			formSuccess,
			setFormSuccess,
			formError,
			setFormError,
		}),
		[formDispatch, formError, formState, formSuccess],
	);

	return <FormStateContext.Provider value={value}>{children}</FormStateContext.Provider>;
};

export const useFormStateContext = (): FormStateContextState => {
	const value = useContext(FormStateContext);
	return value;
};
