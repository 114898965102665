import 'css/fonts.css';

import { AppProps } from 'next/app';
import Head from 'next/head';

import AppLayout from '@/components/app/layout/AppLayout';
import { AppHeaderProps } from '@/components/app/layout/AppLayout/types';
import AppProviders from '@/components/app/layout/AppProviders';
import PageWrapper from '@/components/layout/PageWrapper';
import ServerProviders from '@/components/layout/ServerProviders';
import { AppInitialState } from '@/store';

const MyApp = ({
	Component,
	pageProps,
}: AppProps<{
	initialState: AppInitialState;
	appHeaderProps: AppHeaderProps;
	hideAcceptCookiesModal?: boolean;
	customMainId?: string;
}>) => {
	return (
		<>
			<Head>
				<link rel="shortcut icon" type="image/x-icon" href="/_n/favicon.ico" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Head>
			<ServerProviders initialState={pageProps.initialState}>
				<PageWrapper customMainId={pageProps.customMainId}>
					<AppProviders>
						<AppLayout appHeaderProps={pageProps.appHeaderProps}>
							<Component {...pageProps} />
						</AppLayout>
					</AppProviders>
				</PageWrapper>
			</ServerProviders>
		</>
	);
};

export default MyApp;
