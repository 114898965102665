import { CopyItem } from '@/components/valuePropList';

import { sleepMessages } from '../../ConfigData/MessageSets';
import {
	howOftenTiles,
	sleepCurrentStateTiles,
	sleepIdealStateTiles,
} from '../../ConfigData/QuestionTileSets';
import { SignupScreens } from '../../PreSignupProvider/types';

export const sleepValueProps: Array<CopyItem> = [
	{
		id: 'valueProp1',
		header: sleepMessages.valueProp1Head,
		body: sleepMessages.valueProp1Body,
	},
	{
		id: 'valueProp2',
		header: sleepMessages.valueProp2Head,
		body: sleepMessages.valueProp2Body,
	},
	{
		id: 'valueProp3',
		header: sleepMessages.valueProp3Head,
		body: sleepMessages.valueProp3Body,
	},
];

export const sleepQuestionnaireScreens_NoLanding: SignupScreens = {
	start: {
		componentName: 'PollSingleClickUpdated',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'mood_question',
		nextScreen: 'q2',
		tiles: howOftenTiles,
		question: sleepMessages.question1,
		questionSubtitle: sleepMessages.question1sub,
		showNav: true,
		backButtonHidden: true,
		bgColor: 'gray0',
		canExit: false,
		questionNumber: 1,
		totalQuestions: 3,
		hasSkip: true,
		skipToEndOverride: 'su',
		hasPadding: false,
		URLKey: 'q1',
	},
	q1: {
		componentName: 'PollSingleClickUpdated',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'mood_question',
		nextScreen: 'q2',
		tiles: howOftenTiles,
		question: sleepMessages.question1,
		questionSubtitle: sleepMessages.question1sub,
		showNav: true,
		backButtonHidden: true,
		bgColor: 'gray0',
		canExit: false,
		questionNumber: 1,
		totalQuestions: 3,
		hasSkip: true,
		skipToEndOverride: 'su',
		hasPadding: false,
		URLKey: 'q1',
	},
	q2: {
		componentName: 'PollSingleClickUpdated',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'mood_question',
		nextScreen: 'q3',
		tiles: sleepCurrentStateTiles,
		question: sleepMessages.question2,
		questionSubtitle: sleepMessages.question2sub,
		showNav: true,
		backButtonHidden: true,
		bgColor: 'gray0',
		canExit: false,
		questionNumber: 2,
		totalQuestions: 3,
		hasSkip: true,
		skipToEndOverride: 'su',
		hasPadding: false,
		URLKey: 'q2',
	},
	q3: {
		componentName: 'PollSingleClickUpdated',
		analyticsName: 'FTUE : Intro Questions',
		questionType: 'mood_question',
		tiles: sleepIdealStateTiles,
		question: sleepMessages.question3,
		questionSubtitle: sleepMessages.question3sub,
		showNav: true,
		backButtonHidden: true,
		bgColor: 'gray0',
		canExit: false,
		questionNumber: 3,
		totalQuestions: 3,
		hasSkip: true,
		skipToEndOverride: 'su',
		hasPadding: false,
		nextScreen: 'su',
		URLKey: 'q3',
	},
	su: {
		componentName: 'SimplifiedSignup',
		analyticsName: 'SimplifiedSignup',
		backButtonHidden: true,
		showNav: false,
		isFullScreen: true,
		upsellImage: true,
		upsellQuizResultComponent: 'SignUpQuizResult',
		canExit: true,
		URLKey: 'su',
	},
	pay: {
		componentName: 'SimplifiedSignup',
		analyticsName: 'SimplifiedSignup',
		backButtonHidden: true,
		showNav: false,
		isFullScreen: true,
		upsellImage: true,
		upsellQuizResultComponent: 'SignUpQuizResult',
		canExit: true,
		URLKey: 'pay',
	},
};
