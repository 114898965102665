import { Elements, useElements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { useWindowSize } from 'react-use';

import { usePricesState, usePurchaseParamsState } from '@/hooks/store';
import { getStripe } from '@/utils/getStripe';

import { desktopStripeAppearanceOptions, stripeAppearanceOptions } from './utils';

export const StripeElementProviderContext = createContext<{ isStripeNextEnabled: boolean }>({
	isStripeNextEnabled: false,
});

// This is a temporary solution to update the  Stripe Elements' options if these values change
// during the lifetime of the component. This is necessary because the `Elements` component does not
// update its options when they change.
// Eventually, we should ensure purchase params are only set on the server so that these values don't update.
const ElementsUpdater: React.FC<{
	children: ReactNode;
	amount: number;
	mode: 'setup' | 'payment';
	currency: string;
}> = ({ children, amount, mode, currency }) => {
	const elements = useElements();

	useEffect(() => {
		elements?.update?.({ amount, mode, currency });
	}, [amount, elements, mode, currency]);

	return <>{children}</>;
};

const StripeElementsProvider: React.FC<{
	children: ReactNode;
	isStripeNext?: boolean;
	amount?: number;
	currency?: string;
	mode?: StripeElementsOptions['mode'];
}> = ({
	children,
	isStripeNext = false,
	amount: amountOverride,
	currency: currencyOverride,
	mode: modeOverride,
}) => {
	const prices = usePricesState();
	const { plan, purchaseType } = usePurchaseParamsState();
	const { width: winWidth } = useWindowSize();
	const isStripeNextEnabled = Boolean(isStripeNext);

	const amount = amountOverride ?? prices?.current[plan];

	const currency = (currencyOverride ?? prices?.current?.currency ?? 'usd').toLowerCase();
	const isFreeTrial = purchaseType?.type === 'freetrial';
	const mode = modeOverride ?? isFreeTrial ? 'setup' : 'payment';

	const stripeElementsOptions = winWidth > 481 ? desktopStripeAppearanceOptions : stripeAppearanceOptions;

	const options: StripeElementsOptions = useMemo(
		() =>
			isStripeNextEnabled
				? {
						amount: isFreeTrial ? 0 : amount,
						currency,
						appearance: stripeElementsOptions,
						mode,
						paymentMethodCreation: 'manual',
						paymentMethodTypes: ['card'],
				  }
				: {},
		[isStripeNextEnabled, isFreeTrial, amount, currency, stripeElementsOptions, mode],
	);

	return (
		<StripeElementProviderContext.Provider value={{ isStripeNextEnabled }}>
			<Elements stripe={getStripe()} options={options}>
				<ElementsUpdater mode={mode} amount={isFreeTrial ? 0 : amount} currency={currency}>
					{children}
				</ElementsUpdater>
			</Elements>
		</StripeElementProviderContext.Provider>
	);
};

export default StripeElementsProvider;

export const useStripeElements = () => {
	return useContext(StripeElementProviderContext);
};
