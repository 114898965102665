import { IncomingMessage } from 'http';
import { parse } from 'url';
import nookies from 'nookies';

import { getClientIp } from '@/server/utils/getClientIp';
import initBrowserLanguage from '@/server/utils/initBrowserLanguage';

import { getUserToken } from './getUserTokenFromRequest';
import { RequestUserData } from './types';

export function userDataFromRequest({
	req,
	deviceId,
	calmIdentifier,
	calmUserToken,
	platform,
}: {
	req: IncomingMessage;
	deviceId: string;
	calmIdentifier?: string;
	calmUserToken?: string;
	platform?: 'www' | 'msft-teams';
}): RequestUserData {
	const cookies = nookies.get({ req });

	const userToken = getUserToken(cookies, req, calmUserToken);
	const userIp = getClientIp(req);
	const browserLanguage = initBrowserLanguage(req);

	const { pathname } = parse(req.url as string, true);

	return {
		deviceId,
		calmIdentifier,
		userToken,
		userIp,
		browserLanguage,
		platform,
		cookies,
		path: pathname ?? undefined,
	};
}
