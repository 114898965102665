import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import { useFormattedCurrency } from '@/hooks/purchase';
import { PlanSku } from '@/hooks/purchase/usePlanSkuToggle';

import messages from './messages';
import { PlanSkuInterior, PlanSkuLeft, PlanSkuRight, StrikethruPrice, TrialBadge, Wrapper } from './styles';

export type PlanSkuItemProps = PlanSku & {
	onClick?: (id: PlanSkuItemProps['id']) => void;
};

const PlanSkuItemFamily = ({
	duration,
	id,
	originalPrice,
	price,
	active,
	onClick = () => {},
	subheading,
	numPeople,
	badge,
	badgeBg,
}: PlanSkuItemProps) => {
	const formatCurrency = useFormattedCurrency();
	const { formatMessage } = useIntl();

	return (
		<Wrapper
			role="radio"
			name="plan sku"
			isActive={active}
			onPress={() => onClick(id)}
			activeBackgroundColor="white"
			inactiveBackgroundColor="white"
			aria-checked={active}
			focusRingVariant="dark"
			borderWidth={2}
			borderColor={active ? 'focusRingDark' : 'gray1'}
		>
			<PlanSkuInterior>
				<PlanSkuLeft>
					<Text noMargin el="p" size={FontSizes.base} weight={FontWeights.Demi}>
						{duration}
					</Text>
					<Text noMargin el="p" size={FontSizes.base} weight={FontWeights.Regular}>
						{numPeople}
					</Text>
				</PlanSkuLeft>
				<PlanSkuRight>
					<Text noMargin el="p" size={FontSizes.base} weight={FontWeights.Demi}>
						{originalPrice !== price && (
							<>
								<StrikethruPrice>{originalPrice && formatCurrency(originalPrice)}</StrikethruPrice>&nbsp;
							</>
						)}
						{formatCurrency(price)}/{formatMessage(messages.yearlyAbbreviation)}
					</Text>
					<Text noMargin el="p" weight={FontWeights.Regular}>
						{subheading}
					</Text>
				</PlanSkuRight>
			</PlanSkuInterior>
			{badge && (
				<TrialBadge $bgColor={badgeBg ?? 'gradientRainbow6_dark'}>
					<Text size={FontSizes.sm} color="white" weight={FontWeights.Medium}>
						{badge}
					</Text>
				</TrialBadge>
			)}
		</Wrapper>
	);
};

export default PlanSkuItemFamily;
