import apiRequest from '.';
import { JSONObject } from '../types';
import { ApiRequestArgsOptionalUserData } from './types';
import { userDataFromBrowser } from './userDataFromBrowser';

export const browserApiRequest = <T = JSONObject>(args: ApiRequestArgsOptionalUserData) => {
	return apiRequest<T>({
		userData: userDataFromBrowser(),
		...args,
	});
};
