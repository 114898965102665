import { AxiosPromise } from 'axios';

import apiRequest from '@/utils/apiRequest';

import { RequestUserData } from '../apiRequest/types';
import { userDataFromBrowser } from '../apiRequest/userDataFromBrowser';

type PostDeviceReturn = {
	id: string;
	is_new: boolean;
	platform: string;
	version: string;
	calm_identifier: string;
};

export function postDevice(
	{
		userData,
		excludedHeaders,
	}: {
		userData: RequestUserData | null;
		excludedHeaders?: string[];
	} = { userData: null },
): AxiosPromise<PostDeviceReturn> {
	return apiRequest<PostDeviceReturn>({
		endpoint: 'device',
		method: 'POST',
		userData: userData ?? userDataFromBrowser(),
		customHeaders: {
			'x-device-capabilities': 'v1;signedCookie;streamOnly',
		},
		excludedHeaders,
	});
}
