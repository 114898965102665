import styled from 'styled-components';
import { ifNotProp, ifProp, palette, theme } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, minWidth, units } from '@calm-web/design-system';

export const Wrapper = styled.div<{ $lightCondensed?: boolean }>`
	color: ${ifProp('$lightCondensed', palette('gray8'), palette('white'))};
`;

export const Heading = styled.h1`
	margin-bottom: ${units(2)};
	${fontSize(FontSizes.lg)};
`;

export const BodyText = styled.p<{ $full?: boolean }>`
	margin-bottom: ${units(4)};
	max-width: ${ifNotProp('$full', `${theme('breakpoints.maxWidth')}px`)};

	& b {
		${fontWeight(FontWeights.Demi)};
	}
`;

export const BodyTextSmall = styled.small<{ $topSpace?: boolean }>`
	display: block;
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)};
	margin: ${ifProp('$topSpace', units(4, 0, 0, 0), units(0, 0, 4, 0))};
`;

export const ListWrapper = styled.div<{
	$itemCount: number;
	$isFullWidth?: boolean;
	$noMargin?: boolean;
}>`
	margin-bottom: ${ifNotProp('$noMargin', units(6))};
	display: grid;
	word-break: break-word;

	${minWidth('desktop')} {
		grid-template-columns: ${props =>
			props.$itemCount > 5 ? ifProp('$isFullWidth', '1fr', 'repeat(2, minmax(0, 1fr))') : '1fr'};
	}
`;

export const HalfWidthOnDesktop = styled.div`
	${minWidth('desktop')} {
		width: 50%;
	}
`;

export const OrDivider = styled.div`
	align-items: center;
	color: ${palette('gray8')};
	display: flex;
	${fontWeight(FontWeights.Medium)}
	${fontSize(FontSizes.sm)}
  justify-content: center;
	margin: ${units(1.5)} auto;
	position: relative;
	width: 100%;

	&:before {
		background: ${palette('white')};
		border-radius: 4px;
		content: '';
		height: 24px;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		width: 32px;
		z-index: -1;
	}

	&:after {
		background: ${palette('white')};
		content: '';
		height: 1px;
		left: 0;
		position: absolute;
		right: 0;
		z-index: -1;
	}
`;
