import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, Text, TextAlign, TextElements, units } from '@calm-web/design-system';

export const StyledMessageBannerRow = styled.div`
	color: ${palette('gray8')};
	padding-left: ${units(2)};
	padding-right: ${units(2)};
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	margin-bottom: ${units(7.5)};
	text-align: center;
`;

export const TextWrap = styled.div`
	width: 100%;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 8px;
	padding: ${units(2)};
	box-sizing: border-box;
`;

export const BannerText = styled(Text).attrs({
	el: TextElements.Span,
	size: FontSizes.lg,
	align: TextAlign.Center,
})`
	text-align: center;
`;

export const PartnerLogo = styled.img`
	height: 45px;
	margin-bottom: 1px;
	margin-right: ${units(2)};
`;
