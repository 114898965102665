import axios, { AxiosResponse } from 'axios';

import { httpAgent, httpsAgent } from '@/server/utils/httpAgent';
import getUrl from '@/utils/getUrl';

import { JSONObject } from '../../utils/types';
import createReqHeaders from './createReqHeaders';
import { ApiRequestArgs } from './types';

// Throw an error on any response 400 or above
// We consider these to be fatal errors from the server
axios.interceptors.response.use(
	response => response,
	error => {
		const isNested = Boolean(error?.response?.status);
		const httpStatus = isNested ? error?.response?.status : error?.status;

		if (httpStatus && httpStatus >= 400) {
			return Promise.reject(isNested ? error.response : error);
		}

		return Promise.resolve(isNested ? error.response : error);
	},
);

export const apiProxyPrefix = '/webapi/authproxy';

// withCredentials is set to true so that we send cookies along with requests in headers
// which is needed for _px3 cookie used by perimeterX
// See https://codewithhugo.com/pass-cookies-axios-fetch-requests/#pass-cookies-with-requests-in-axios
const apiRequest = <T = JSONObject>({
	endpoint,
	method,
	body,
	customHeaders,
	userData,
	service = 'api',
	timeout = 15000,
	noCache = false,
	excludedHeaders = [],
}: ApiRequestArgs): Promise<AxiosResponse<T>> => {
	const url = `${getUrl(service ?? 'api')}/${endpoint}`;
	const headers = createReqHeaders({
		customHeaders,
		deviceId: userData?.deviceId,
		userToken: userData?.userToken,
		platform: userData?.platform,
		calmIdentifier: userData?.calmIdentifier,
		path: userData?.path,
		userIp: userData?.userIp,
		browserLanguage: userData?.browserLanguage,
		cookies: userData?.cookies,
		noCache,
		excludedHeaders,
	});

	const timestampParams = noCache ? { timestamp: new Date().getTime() } : {};

	return axios<T>({
		method,
		url,
		timeout,
		headers,
		data: body,
		withCredentials: true,
		params: { ...timestampParams },
		httpAgent,
		httpsAgent,
	});
};

export default apiRequest;
