import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontWeight, FontWeights, FormInput } from '@calm-web/design-system';

export const Wrapper = styled.div`
	text-align: left;
`;

export const Label = styled.label<{ $colorTheme: 'light' | 'dark' }>`
	color: ${p => (p.$colorTheme === 'light' ? palette('gray6') : palette('white'))};
	display: block;
	${fontWeight(FontWeights.Demi)};
	font-size: 18px;
	margin-bottom: 16px;
`;

export const Input = styled(FormInput)`
	cursor: pointer;

	& > div {
		pointer-events: none;
	}

	& input {
		border: 1px ${palette('gray3')} solid;
		color: ${palette('gray4')};

		&:focus {
			border: 1px ${palette('gray3')} solid;
		}
	}
`;
