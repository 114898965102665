import { useState } from 'react';
import { useIntl } from 'react-intl';

import { AccessibleOnChange } from '@calm-web/design-system';

import messages from '@/components/authForm/AuthNotice/messages';
import { useAnalytics } from '@/hooks/analytics';
import { useAuthFormModeState } from '@/hooks/store';
import { JSONObject } from '@/utils/types';
import { validate } from '@/utils/ui/validator';

import { InitialValues, InputActions, InputField, InputFields, InputIcons, InputNames } from './types';

/**
 * The type constraints on setupInputs and useInputs
 * require that
 * a) inputNames is a constant array of strings
 * b) initialValues only has keys who are members of inputNames (and values which are strings)
 */

function setupInputs<T extends InputNames & string extends T[number] ? never : InputNames>(
	inputNames: T,
	initialValues: InitialValues<T[number]>,
	validateOnSetup: boolean,
	inputIcons: InputIcons,
): InputFields {
	return inputNames.reduce((accu, curr) => {
		const value = initialValues[curr as T[number]] ?? '';
		return {
			...accu,
			[curr]: {
				value,
				isTouched: false,
				isValid: validateOnSetup ? validate(curr, value) : true,
				Icon: inputIcons[curr],
			},
		};
	}, {});
}

export function useAuthFormInputs<T extends InputNames & string extends T[number] ? never : InputNames>(
	inputNames: T,
	initialValues: InitialValues<T[number]> = {},
	validateOnSetup = true,
	customFormEventProps: JSONObject = {},
	inputIcons: InputIcons = {},
): [InputFields, InputActions] {
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const authFormMode = useAuthFormModeState();

	const [inputFields, setInputFields] = useState(
		setupInputs(inputNames, initialValues, validateOnSetup, inputIcons),
	);

	const getErrorMessage = (fieldName: string) => {
		if (fieldName === 'name') {
			return formatMessage(messages.nameInvalid);
		}
		if (fieldName === 'newPassword') {
			return formatMessage(messages.passwordShortError);
		}
		if (fieldName === 'password') {
			return formatMessage(messages.passwordInvalid);
		}
		if (fieldName === 'email') {
			return formatMessage(messages.emailInvalid);
		}
	};

	const getFieldNameForValidation = (fieldName: string): string => {
		if (fieldName === 'password' && authFormMode === 'signup') return 'newPassword';
		return fieldName;
	};

	const getValidation = (fieldName: string, value: string): { isValid: boolean; errorMessage?: string } => {
		const isValid = validate(getFieldNameForValidation(fieldName), value);
		const errorMessage = !isValid ? getErrorMessage(fieldName) : undefined;

		return {
			isValid,
			errorMessage,
		};
	};

	const updateInputFields = (fieldName: string, updatedProperties: Partial<InputField>) => {
		setInputFields(inputFields => {
			return {
				...inputFields,
				[fieldName]: {
					...inputFields[fieldName],
					...updatedProperties,
				},
			};
		});
	};

	const onChange: AccessibleOnChange = e => {
		const { value, name: fieldName } = e?.currentTarget as HTMLInputElement;
		const { isValid, errorMessage } = getValidation(fieldName, value);

		updateInputFields(fieldName, {
			value,
			isValid,
			errorMessage,
			isTouched: true,
			isBlurred: false,
		});
	};

	const onBlur = (e: React.FocusEvent<HTMLInputElement>, eventProps?: JSONObject) => {
		const fieldName = e?.target?.name;
		const fieldValue = e?.target?.value ?? inputFields[fieldName].value;
		const inputName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
		const { isValid, errorMessage } = getValidation(getFieldNameForValidation(fieldName), fieldValue);

		logEvent({
			eventName: `Login Form : ${inputName} : Entered`,
			eventProps: {
				is_valid: isValid,
				...eventProps,
				...customFormEventProps,
				mode: authFormMode,
			},
		});
		// updateInputFields async, so that we don't cause a rerender during onBlur
		setTimeout(() => {
			updateInputFields(fieldName, { isBlurred: true, isValid, errorMessage });
		}, 0);
	};

	const onFocus: React.FocusEventHandler<HTMLInputElement> = e => {
		const fieldName = e?.target?.name;
		updateInputFields(fieldName, { isTouched: true });
	};

	function onClear(): void {
		setInputFields(setupInputs(inputNames, {}, validateOnSetup, inputIcons));
	}

	return [inputFields, { onChange, onBlur, onFocus, onClear }];
}
