import { StaticImageData } from 'next/image';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

import Image from '@/components/image/NextImage';
import { PreSignupFlowContext } from '@/components/PreSignupFlow/PreSignupProvider';

import messages from '../QuizResults/messages';
import { ImageWrapper, ThumbnailWrapper } from './styles';

export type ContentBasedImageProps = {
	src?: StaticImageData | string;
	title?: string;
	subtitle?: string;
	description?: string;
	isThumbnail?: boolean;
};

const ContentBasedImage = ({ src, isThumbnail, title, subtitle, description }: ContentBasedImageProps) => {
	const { focusedQuizMap = {} } = useContext(PreSignupFlowContext);
	const { query } = useRouter();
	const focus = (query?.focus as string) || 'stress_questionnaire';
	const { formatMessage } = useIntl();

	const imageSrc = src ?? focusedQuizMap[focus]?.upsellImage;

	if (!imageSrc) {
		return null;
	}

	return isThumbnail ? (
		<ThumbnailWrapper
			imageUrl={imageSrc as string}
			subtitle={subtitle}
			description={description}
			title={title}
		/>
	) : (
		<ImageWrapper>
			<Image src={imageSrc} alt={formatMessage(messages.recImage)} layout="fill" objectFit="contain" />
		</ImageWrapper>
	);
};

export default ContentBasedImage;
