import styled from 'styled-components';
import { palette } from 'styled-tools';

import { IconButton } from '@calm-web/design-system';

import { DAILY_MOVE_INDEX } from '@/utils/ui/zIndices';
import chevronLeft from 'icons/chevron-left.svg';

export const Wrapper = styled.div`
	background: ${palette('black')};
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	top: 0;
	z-index: ${DAILY_MOVE_INDEX};

	& video {
		width: 100%;
		max-height: 100%;

		&:focus {
			outline: none;
		}
	}
`;

export const BackButton = styled(IconButton).attrs({
	Icon: chevronLeft,
	color: 'white',
	backgroundColor: 'transparentBlackBackground',
})`
	cursor: pointer;
	left: 24px;
	padding: 0;
	position: fixed;
	top: 32px;
	z-index: 1001;

	& > div,
	svg {
		width: 32px;
		height: 32px;
	}

	// Center the icon in the focus ring
	svg {
		position: relative;
		left: -2px;
	}
`;

export const VJSPlayer = styled.div`
	width: 100%;
	height: 100vh;
`;
