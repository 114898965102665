import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Collapsible, FontWeights, minWidth, Text } from '@calm-web/design-system';

export const Wrapper = styled.section`
	margin: 0 auto;
	max-width: 1440px;
	padding: 0 20px 60px;
	text-align: center;

	${minWidth('desktop')} {
		padding: 0 136px 100px;
	}
`;

export const Heading = styled(Text).attrs({
	el: 'h2',
	color: 'bannerText',
	noMargin: true,
})`
	&&& {
		font-size: 24px;
		margin-bottom: 32px;

		${minWidth('desktop')} {
			font-size: 36px;
		}
	}
`;

export const FaqList = styled.div`
	list-style: none;
	padding-left: 0;

	> h3 {
		color: ${palette('bannerText')};
		font-size: 20px;
		margin: 40px 0 16px;
		text-align: left;

		${minWidth('desktop')} {
			font-size: 22.5px;
			margin: 40px 0 20px;
		}

		&:first-of-type {
			margin-top: 0;
		}
	}
`;

export const FaqItem = styled(Collapsible)<{ $hideBorder?: boolean }>`
	border-top: ${p => (p.$hideBorder ? '0px' : '1px rgba(0, 0, 0, 0.2) solid')};
	padding: 6px 0;
	text-align: left;

	${minWidth('desktop')} {
		padding: 10px 0;
	}

	> span > div {
		flex-direction: row-reverse;
		justify-content: space-between;

		span > svg {
			transform: rotate(0deg);
		}
	}

	> span.is-open > div > span > svg {
		transform: rotate(180deg);
	}

	& span:nth-child(2) {
		color: ${palette('gray8')};
		font-size: 1.125rem;
		font-weight: ${FontWeights.Demi};
		padding-right: 1rem;
	}

	& p {
		color: ${palette('gray8')};
		font-size: 1.125rem;
		font-weight: ${FontWeights.Regular};
		margin-bottom: 1rem;
	}

	& li {
		color: ${palette('gray8')};
		font-size: 1.125rem;
		font-weight: ${FontWeights.Regular};
	}

	& a {
		color: ${palette('bannerText')};
	}

	& ol,
	ul {
		margin-bottom: 1rem;
	}

	&:hover span:nth-child(2) {
		color: ${palette('bannerText')};
	}
`;
