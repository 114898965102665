import { useState } from 'react';
import useSWR from 'swr';

import { useApi } from '../api';

interface TokenVerificationResponse {
	status?: 'valid';
	url?: string | null;
	share_token?: string | null;
}

type UseShareRequestToken = (content_identifier?: string | null) => {
	isFetchingShareRequest: boolean;
	shareUrl: string | null;
	senderShareToken: string | null;
};

export const useShareRequestToken: UseShareRequestToken = (contentIdentifier = null) => {
	const [isFetching, setIsFetching] = useState(false);
	const apiRequest = useApi();

	const tokenCreateEndpoint = 'content/share';

	const { data } = useSWR<TokenVerificationResponse>(
		contentIdentifier ? [tokenCreateEndpoint, contentIdentifier] : null,
		async ([endpoint, contentIdentifier]: [endpoint: string, contentIdentifier: string]) => {
			setIsFetching(true);
			const response = await apiRequest<TokenVerificationResponse>({
				endpoint,
				method: 'POST',
				body: {
					content_identifier: contentIdentifier,
				},
			});
			setIsFetching(false);
			return response.data;
		},
		{ revalidateOnFocus: false, errorRetryCount: 0 },
	);

	return {
		isFetchingShareRequest: isFetching,
		shareUrl: data?.url || null,
		senderShareToken: data?.share_token || null,
	};
};
