import * as amplitude from '@amplitude/analytics-browser';
import { ExperimentClient } from '@amplitude/experiment-js-client';
import SHA1 from 'crypto-js/sha1';
import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { initDatadogLogs } from '@calm-web/logger';

import { LogEventArgs, LogPurchaseArgs } from '@/hooks/analytics/types';
import { useShareToken } from '@/hooks/share/useShareToken';
import {
	useCampaignState,
	useComplianceLevelState,
	useDeviceState,
	useFeatureFlagsState,
	usePurchaseParamsState,
	useUserState,
	useZoomState,
} from '@/hooks/store';
import { NO_RESTRICTIONS, REQUIRE_GDPR_MODAL } from '@/store/complianceLevel/types';
import { FEATURE_FLAGS } from '@/store/feature-flags/types';
import {
	initAmplitude,
	logAmplitudeEvent,
	logAmplitudePurchase,
	setAmplitudeDeviceProperties,
	setAmplitudeUserId,
	setAmplitudeUserProperties,
} from '@/utils/analytics/amplitude';
import { initDatadogRum } from '@/utils/analytics/datadogRum';
import { logGtmEvent } from '@/utils/analytics/gtm';
import { initSegmentUser, logSegmentEvent } from '@/utils/analytics/segment';
import setupEventProps from '@/utils/analytics/setupEventProps';
import { useGetIsTeamsApp } from '@/utils/app/msftTeams';
import { parsePartnerDetails } from '@/utils/b2b/partners';
import { calmLogger } from '@/utils/calmLogger';
import { getCookie } from '@/utils/cookies';
import { postDevice } from '@/utils/endpoints';
import {
	AMPLITUDE_EXPERIMENTS_UNINITIALIZED_STATE,
	AmplitudeExperimentClient,
	initAmplitudeExperimentClient,
	setupAmplitudeExperimentUser,
} from '@/utils/experiments/amplitudeExperiment';
import {
	CALM_DEVICE_ID,
	CALM_ID,
	CALM_LIMITED_DATA_USE,
	CALM_USER_ID,
	DECLINE_GDPR_COOKIES_KEY,
} from '@/utils/privacyCookies';

export const forciblyLoggedEventNames = [
	'Cookie Preferences : Essential Only',
	'Cookie Preferences : Accept All',
];

export type LogEventResponse = amplitude.Types.Result | string | void | null;

export type LogEventAsync = ({ eventName, eventProps }: LogEventArgs) => Promise<LogEventResponse>;
export type LogEvent = ({ eventName, eventProps }: LogEventArgs) => LogEventResponse;

export interface AnalyticsContextProps {
	logEvent: LogEvent;
	logEventAsync: LogEventAsync;
	logPurchase: ({ productInfo, transactionInfo }: LogPurchaseArgs) => LogEventResponse;
	experimentClient?: AmplitudeExperimentClient;
	isAnalyticsInitialized: boolean;
}

export const AnalyticsContext = createContext<AnalyticsContextProps>({
	logEvent: () => {},
	logEventAsync: async () => {},
	logPurchase: () => {},
	isAnalyticsInitialized: false,
});

function useTrackPartnerDetails(): void {
	const user = useUserState();
	const complianceLevel = useComplianceLevelState();

	const canLogEvent = complianceLevel !== REQUIRE_GDPR_MODAL;
	const { partnerId, partnerName } = useMemo(() => parsePartnerDetails(user), [user]);

	useEffect(() => {
		if (canLogEvent && (partnerId || partnerName)) {
			setAmplitudeUserProperties({
				...(partnerId && { partner_id: partnerId }),
				...(partnerName && { partner_name: partnerName }),
			});
		}
	}, [canLogEvent, partnerId, partnerName]);
}

const AnalyticsContextProvider = ({ children }: { children?: ReactNode }) => {
	const device = useDeviceState();
	const user = useUserState();
	const complianceLevel = useComplianceLevelState();
	const purchaseParams = usePurchaseParamsState();
	const zoom = useZoomState();
	const { isTeamsApp } = useGetIsTeamsApp();
	const featureFlags = useFeatureFlagsState();
	const campaign = useCampaignState();
	const shareToken = useShareToken();
	const isGdprOptOutByDefaultEnabled = featureFlags[FEATURE_FLAGS.GDPR_OPT_OUT_BY_DEFAULT_FLAG];

	const canLogEvent = complianceLevel !== REQUIRE_GDPR_MODAL;
	const calmUserId = user?.id ?? getCookie(CALM_USER_ID);
	const calmIdentifier = user?.calm_identifier ?? device?.calm_identifier ?? getCookie(CALM_ID);
	const deviceId = device?.deviceId ?? getCookie(CALM_DEVICE_ID);
	const userEmail = user?.email;

	const [experimentClient, setExperimentClient] = useState<AmplitudeExperimentClient>();
	const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState<boolean>(false);

	const initExperiments = async () => {
		const amplitudeExperimentClient = await initAmplitudeExperimentClient();
		setExperimentClient(amplitudeExperimentClient);
	};

	useEffect(() => {
		if (isAnalyticsInitialized) return;
		if (!canLogEvent) {
			setExperimentClient(AMPLITUDE_EXPERIMENTS_UNINITIALIZED_STATE);
			return;
		}

		const initLibs = async () => {
			await initAmplitude();
			await initExperiments();
			initDatadogRum();
			initDatadogLogs({
				service: 'web-www',
				version: process.env.BUILD_ID,
				env: process.env.NEXT_PUBLIC_LOG_ENV ?? 'local',
			});
			// Segment is initialized via 3rd party link in `components/layout/LayoutScripts/index.tsx`
			setIsAnalyticsInitialized(true);
		};

		const initialPostDevice = async () => {
			await postDevice();
		};

		initLibs().catch(error => calmLogger.error('Error in AnalyticsContextProvider initLibs', {}, error));
		initialPostDevice().catch(error =>
			calmLogger.error('Error in AnalyticsContextProvider initialPostDevice', {}, error),
		);
	}, [canLogEvent, isAnalyticsInitialized]);

	useEffect(() => {
		async function fetchExperimentInfo(): Promise<ExperimentClient | null> {
			if (experimentClient && experimentClient !== AMPLITUDE_EXPERIMENTS_UNINITIALIZED_STATE) {
				const experimentUser = setupAmplitudeExperimentUser(user, device);
				const newClient = await experimentClient.fetch(experimentUser);
				return newClient;
			}
			return Promise.resolve(null);
		}
		fetchExperimentInfo()
			.then((refreshedExperimentClient: ExperimentClient | null) => {
				if (refreshedExperimentClient) {
					setExperimentClient(refreshedExperimentClient);
				}
			})
			.catch(error => {
				return calmLogger.error('Error in AnalyticsContextProvider fetchExperimentInfo', {}, error);
			});
	}, [device, experimentClient, user]);

	useEffect(() => {
		if (canLogEvent && calmUserId) {
			setAmplitudeUserId(calmUserId);
			initSegmentUser(calmUserId).catch(error =>
				calmLogger.error('Error in AnalyticsContextProvider initSegmentUser', {}, error),
			);
		}
	}, [canLogEvent, calmUserId]);

	useEffect(() => {
		if (canLogEvent && deviceId) {
			setAmplitudeDeviceProperties(deviceId);
		}
	}, [canLogEvent, deviceId]);

	useTrackPartnerDetails();

	const canLogGtmEvent = isGdprOptOutByDefaultEnabled
		? complianceLevel === NO_RESTRICTIONS
		: !user?.is_hipaa_compliant &&
		  !user?.has_opted_in_limited_data_use &&
		  !getCookie(CALM_LIMITED_DATA_USE) &&
		  !getCookie(DECLINE_GDPR_COOKIES_KEY);

	const logEventAsync = useCallback(
		async ({ eventName, eventProps }: LogEventArgs): Promise<LogEventResponse> => {
			if (!canLogEvent && !forciblyLoggedEventNames.includes(eventName)) return null;

			const isLoggedIn = Boolean(calmUserId);
			const hashedUserEmail = userEmail && SHA1(userEmail).toString();
			const shareEventProps =
				typeof shareToken === 'string' && shareToken.length > 0
					? {
							source: 'Shared Link',
							share_token: shareToken,
					  }
					: {};

			const hydratedEventProps = await setupEventProps(
				eventProps || {},
				isLoggedIn,
				purchaseParams?.coupon,
				zoom,
				isTeamsApp,
				device,
				shareEventProps,
				complianceLevel,
				hashedUserEmail,
				calmIdentifier,
				campaign,
			);

			const logProps = {
				eventName,
				eventProps: hydratedEventProps,
			};

			const promises = [logAmplitudeEvent(logProps), logSegmentEvent(logProps)];
			if (canLogGtmEvent) {
				logGtmEvent(logProps);
			}
			const [amplitudeResult] = await Promise.allSettled(promises);
			if (process.env.NEXT_PUBLIC_CALM_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
				calmLogger.info(`Log event: ${eventName}`, eventProps);
			}
			if (amplitudeResult.status === 'fulfilled') {
				return amplitudeResult.value;
			}
		},
		[
			canLogEvent,
			calmUserId,
			userEmail,
			shareToken,
			purchaseParams?.coupon,
			zoom,
			isTeamsApp,
			device,
			complianceLevel,
			calmIdentifier,
			canLogGtmEvent,
			campaign,
		],
	);
	const logEvent = useCallback(
		(args: LogEventArgs): void => {
			logEventAsync(args)?.catch(error =>
				calmLogger.error('Error in AnalyticsContextProvider logEventAsync', {}, error),
			);
		},
		[logEventAsync],
	);

	const logPurchase = useCallback(
		({ productInfo, transactionInfo }: LogPurchaseArgs): void => {
			if (!canLogEvent) return;

			logAmplitudePurchase({ productInfo, transactionInfo }).catch(error =>
				calmLogger.error('Error in AnalyticsContextProvider logAmplitudePurchase', {}, error),
			);
		},
		[canLogEvent],
	);

	const value = useMemo(
		() => ({
			logEventAsync,
			logEvent,
			logPurchase,
			experimentClient,
			isAnalyticsInitialized,
		}),
		[logEventAsync, logEvent, logPurchase, experimentClient, isAnalyticsInitialized],
	);

	return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsContextProvider;
