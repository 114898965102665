import { AxiosPromise } from 'axios';

import { User } from '@/store/user/types';
import { browserApiRequest } from '@/utils/apiRequest/browserApiRequest';
import getUrl from '@/utils/getUrl';

export interface AppleAuthArgs {
	code: string;
	user: string | undefined;
	marketingOptIn: boolean;
	guestPassCode?: string | undefined;
	redirectUri?: string;
}

export function postAppleAuth({
	code,
	user,
	marketingOptIn,
	guestPassCode,
	redirectUri,
}: AppleAuthArgs): AxiosPromise<User> {
	const appleRedirectUri = redirectUri
		? redirectUri
		: `${getUrl('www')}${process.env.NEXT_PUBLIC_APPLE_REDIRECT_PATH}`;
	return browserApiRequest({
		endpoint: 'auth/apple',
		method: 'POST',
		body: {
			code,
			user,
			redirect_uri: appleRedirectUri,
			marketing_opt_in: marketingOptIn,
			guest_pass_code: guestPassCode,
		},
	});
}
