import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const ModalWrapper = styled.form`
	color: ${palette('gray8')};
	text-align: center;
`;

export const ModalHeading = styled.h2`
	${fontSize(FontSizes['2xl'])};
	${fontWeight(FontWeights.Regular)};
	margin-bottom: ${units(3)};
`;

export const ButtonContainer = styled.div<{ $isErrored: boolean }>`
	display: grid;
	grid-column-gap: ${units(1)};
	grid-template-columns: repeat(2, minmax(0, 1fr));
	margin-bottom: ${p => (p.$isErrored ? units(1.5) : '0')};
`;
