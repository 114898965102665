import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, FontWeights, FormInput, Text } from '@calm-web/design-system';

import { CopyLinkField } from '@/components/app/ui/SharedLayouts';

export const ShareTitleComponent = styled(Text).attrs({
	align: 'center',
	noMargin: true,
	color: 'gray5',
	el: 'p',
	size: FontSizes.lg,
	weight: FontWeights.Medium,
})`
	margin-bottom: 10px;
	margin-top: -2rem;
`;

export const ShareFormInput = styled(FormInput)`
	input {
		padding: 0;
		padding-left: 12px;
	}
`;

export const ShareNoticeContainer = styled.div`
	margin-top: 24px;
	margin-bottom: -12px;
`;

export const CopyShareLinkField = styled(CopyLinkField)`
	label {
		color: ${palette('gray4')};
		text-align: center;
		margin-top: 20px;
		margin-bottom: 10px;
		${fontSize(FontSizes.sm)};
	}
`;

export const BreakWordWrapper = styled('p')`
	margin-top: 10px;
	font-weight: bold;
	word-wrap: break-word;
`;
