import { FontSizes } from '@calm-web/design-system';

import { upsellCopy } from '@/components/PreSignupFlow/ConfigData/MessageSets';
import { CopyItem } from '@/components/valuePropList';
import { RouterPush } from '@/hooks/utils/useRouterPush';
import { AMPLITUDE_EXPERIMENTS_UNINITIALIZED_STATE } from '@/utils/experiments/amplitudeExperiment';
import getUrl from '@/utils/getUrl';

import Account from '../../Account';
import { AccountScreenConfig } from '../../Account/types';
import Error from '../../Error';
import { ErrorScreenConfig } from '../../Error/types';
import Handoff from '../../Handoff';
import { HandoffScreenConfig } from '../../Handoff/types';
import Payment from '../../Payment';
import { PaymentScreenConfig } from '../../Payment/types';
import messages from './messages';

export const accountValueProps: Array<CopyItem> = [
	{
		id: 'valueProp1',
		header: messages.valueProp1Head,
		body: messages.valueProp1Body,
	},
	{
		id: 'valueProp2',
		header: messages.valueProp2Head,
		body: messages.valueProp2Body,
	},
	{
		id: 'valueProp3',
		header: messages.valueProp3Head,
		body: messages.valueProp3Body,
	},
];
export const premiumLineItems: Array<CopyItem> = [
	{
		id: 'valueProp1',
		header: messages.comparePremiumProp1Header,
		body: messages.comparePremiumProp1Body,
	},
	{
		id: 'valueProp2',
		header: messages.comparePremiumProp2Header,
		body: messages.comparePremiumProp2Body,
	},
	{
		id: 'valueProp3',
		header: upsellCopy.cancelAnytime,
	},
];

export const basicLineItems: Array<CopyItem> = [{ id: 'limitedAccess', header: upsellCopy.limitedAccess }];

const ControlAccountScreenConfig: AccountScreenConfig = {
	name: 'account',
	component: Account as React.FC,
	nextScreen: 'payment',
	upsellBullets: accountValueProps,
	upsellHeader: () => ({ message: messages.valuePropHeader }),
	hasPremiumSubCallback: async (routerPush: RouterPush) => {
		await routerPush(`${getUrl('www-app')}`);
	},
	showHeader: true,
	heading: ({ authFormMode }) => {
		if (authFormMode === 'login') {
			return { message: messages.loginFormHeading };
		} else {
			return {
				message: messages.signupFormHeading,
				placeholders: {
					dayNumber: 14,
				},
			};
		}
	},
	limitedTimeOffer: false,
	backgroundKey: 'gray0',
	authHeaderSize: FontSizes.lg,
	valuePropsTitleSize: FontSizes['2xl'],
	userCanExit: true,
	onAuthSuccess: experimentClient => {
		if (experimentClient && experimentClient !== AMPLITUDE_EXPERIMENTS_UNINITIALIZED_STATE) {
			experimentClient.variant('www-variant-hopping-monitor');
		}
	},
};
const ControlPaymentScreenConfig: PaymentScreenConfig = {
	component: Payment as React.FC,
	name: 'payment',
	nextScreen: 'handoff',
	showHeader: true,
	upsellBullets: accountValueProps,
	heading: params => {
		const message = params?.trialLength ? messages.paymentFormHeading : messages.valuePropHeader;
		return {
			message,
			placeholders: {
				trialLength: params?.trialLength || 7,
			},
		};
	},
	premiumLineHeader: messages.includedPremium,
	premiumLineItems: premiumLineItems,
	basicLineHeader: messages.includedBasic,
	basicLineItems,
	showToggle: true,
	userCanExit: true,
	limitedTimeOffer: true,
	backgroundKey: 'gray0',
};
const ControlHandoffScreenConfig: HandoffScreenConfig = {
	component: Handoff as React.FC,
	name: 'handoff',
	nextScreen: null,
	showHeader: true,
	images: {},
	bullets: {},
	userCanExit: true,
};
const ControlErrorScreenConfig: ErrorScreenConfig = {
	component: Error as React.FC,
	name: 'error',
	nextScreen: () => {},
	showHeader: false,
};

export const ControlScreenConfigs = {
	account: ControlAccountScreenConfig,
	payment: ControlPaymentScreenConfig,
	handoff: ControlHandoffScreenConfig,
	error: ControlErrorScreenConfig,
};
export default ControlScreenConfigs;
