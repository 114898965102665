import { AxiosPromise } from 'axios';

import { browserApiRequest } from '../apiRequest/browserApiRequest';

export interface PartnerInfo {
	dataSharingOptIn?: boolean;
	linkToken?: string;
	partnerId?: string;
	partnerUserId?: string;
	puidToken?: string;
	groupCode?: string;
}

export function putPartnerSubscribe({
	dataSharingOptIn,
	linkToken,
	partnerId,
	partnerUserId,
	puidToken,
	groupCode,
}: PartnerInfo): AxiosPromise {
	return browserApiRequest({
		endpoint: 'b2b/users/subscription',
		method: 'PUT',
		customHeaders: linkToken
			? {
					'x-calm-link-token': linkToken,
			  }
			: {},
		body: {
			partner_user_id: partnerUserId,
			puid_token: puidToken,
			partner_id: partnerId,
			calm_link_token: linkToken,
			data_sharing_opt_in: dataSharingOptIn,
			group_code: groupCode,
		},
	});
}
