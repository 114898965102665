import FocusLock from 'react-focus-lock';
import { useIntl } from 'react-intl';

import { Button } from '@calm-web/design-system';

import { HeaderProps } from '@/components/app/layout/Header/types';
import sidebarMessages from '@/components/app/sidebar/Sidebar/messages';
import SidebarMenuItem from '@/components/app/sidebar/SidebarMenuItem';
import { SidebarMenuItemProps } from '@/components/app/sidebar/SidebarMenuItem/types';
import CalmLogo from '@/components/CalmLogo';
import FlyoutMenu from '@/components/header/FlyoutMenu';
import HamburgerMenu from '@/components/header/HamburgerMenu';
import useHeaderButtons from '@/hooks/app/useHeaderButtons/useHeaderButtons';
import { useThemeState } from '@/hooks/store';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import messages from '@/messages/messages';
import { useGetIsTeamsApp } from '@/utils/app/msftTeams';
import { CALM_ONE_LINKS } from '@/utils/deeplinks/deeplinks';
import ScenesIcon from 'icons/scenes-outline.svg';
import SearchIcon from 'icons/search-icon.svg';

import {
	MenuWrapper,
	PrivacyPolicy,
	QuickActionButtonWrapper,
	RightSideHeader,
	SceneSearchButtonWrapper,
	Wrapper,
} from './styles';

export interface MobileHeaderProps extends HeaderProps {
	menuItems: Omit<SidebarMenuItemProps, 'id'>[];
}

const MobileHeader = ({ isNavOpen, setIsNavOpen, menuItems }: MobileHeaderProps) => {
	const { formatMessage } = useIntl();
	const theme = useThemeState();
	const { isTeamsApp, isLoading: isGetIsTeamsAppLoading } = useGetIsTeamsApp();
	const { buttonConfig } = useHeaderButtons();
	const routerPush = useRouterPush();

	const onClick = () => {
		setIsNavOpen(!isNavOpen);

		if (!isNavOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	};

	const showMobileHeaderCTA =
		!isTeamsApp && !isGetIsTeamsAppLoading && !theme.isTeamsApp && !theme.isZoomApp && !isNavOpen;

	return (
		<FocusLock disabled={!isNavOpen}>
			<div>
				<Wrapper isOpen={isNavOpen}>
					<CalmLogo href="/app" height={25} aria-label={formatMessage(sidebarMessages.logoAriaLabel)} />
					<RightSideHeader>
						{showMobileHeaderCTA && (
							<Button onPress={buttonConfig.secondButton.onPress} backgroundColor="white" textColor="black">
								{formatMessage(buttonConfig.secondButton.copy)}
							</Button>
						)}
						<HamburgerMenu isDark isOpen={isNavOpen} onClick={onClick} />
					</RightSideHeader>
				</Wrapper>
				<FlyoutMenu isWebApp isOpen={isNavOpen} label="primary">
					<MenuWrapper>
						<QuickActionButtonWrapper>
							<Button onPress={buttonConfig.secondButton.onPress} backgroundColor="white" textColor="black">
								{formatMessage(buttonConfig.secondButton.copy)}
							</Button>
							{buttonConfig?.firstButton && (
								<Button
									onPress={buttonConfig.firstButton.onPress}
									backgroundColor="transparentBlackBackground10"
								>
									{formatMessage(buttonConfig.firstButton.copy)}
								</Button>
							)}
							<Button backgroundColor="transparentBlackBackground10" href={CALM_ONE_LINKS.app}>
								{formatMessage(messages.downloadApp)}
							</Button>
							<SceneSearchButtonWrapper>
								<Button
									onPress={async () => {
										onClick();
										await routerPush('/app/search');
									}}
									backgroundColor="transparentBlackBackground10"
									Icon={SearchIcon}
									fullWidth
									aria-label={formatMessage(sidebarMessages.searchLinkText)}
								>
									{formatMessage(sidebarMessages.searchLinkText)}
								</Button>
								<Button
									onPress={async () => {
										onClick();
										await routerPush('/app/scenes');
									}}
									backgroundColor="transparentBlackBackground10"
									Icon={ScenesIcon}
									fullWidth
									aria-label={formatMessage(sidebarMessages.scenesLinkText)}
								>
									{formatMessage(sidebarMessages.scenesLinkText)}
								</Button>
							</SceneSearchButtonWrapper>
						</QuickActionButtonWrapper>
						{menuItems.map(menuItem => (
							<li key={menuItem.route}>
								<SidebarMenuItem
									title={menuItem.title}
									IconFilled={menuItem.IconFilled}
									IconOutline={menuItem.IconOutline}
									colors={menuItem.colors}
									route={menuItem.route}
									supportedLocales={menuItem.supportedLocales}
									onClick={onClick}
									id={`${menuItem.route}MobileIconBorder`}
								/>
							</li>
						))}
						<PrivacyPolicy href="/privacy" target="_blank">
							{formatMessage(sidebarMessages.privacyPolicy)}
						</PrivacyPolicy>
					</MenuWrapper>
				</FlyoutMenu>
			</div>
		</FocusLock>
	);
};

export default MobileHeader;
