import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { CalmLogo } from '@calm-web/design-system';

import SidebarMenuItem from '@/components/app/sidebar/SidebarMenuItem';
import { SidebarMenuItemProps } from '@/components/app/sidebar/SidebarMenuItem/types';
import UpsellButton from '@/components/app/ui/UpsellButton';
import { QRCodeHandoffExpanded } from '@/components/QRCodeHandoff';

import { OfferBannerContext } from '../../layout/OfferBannerProvider';
import messages from './messages';
import { MenuItems, PrivacyPolicy, Wrapper } from './styles';

export interface SidebarProps {
	menuItems: Omit<SidebarMenuItemProps, 'id'>[];
	upsellButtonCopy?: string;
	onUpsellPress?: () => void;
	isUpsellButtonVisible?: boolean;
	bottomOffset?: number;
}

const Sidebar = ({
	menuItems,
	upsellButtonCopy,
	onUpsellPress,
	isUpsellButtonVisible = false,
	bottomOffset = 0,
}: SidebarProps) => {
	const { formatMessage } = useIntl();
	const { isBannerVisible } = useContext(OfferBannerContext);

	return (
		<Wrapper $bottomOffset={bottomOffset} isBannerVisible={isBannerVisible}>
			<CalmLogo href="/app" aria-label={formatMessage(messages.logoAriaLabel)} />
			<UpsellButton forceShow={isUpsellButtonVisible} copy={upsellButtonCopy} onPress={onUpsellPress} />
			<MenuItems aria-label={formatMessage(messages.navAriaLabel)}>
				<ul>
					{menuItems.map(menuItem => (
						<li key={menuItem.route}>
							<SidebarMenuItem
								title={menuItem.title}
								IconFilled={menuItem.IconFilled}
								IconOutline={menuItem.IconOutline}
								colors={menuItem.colors}
								route={menuItem.route}
								supportedLocales={menuItem.supportedLocales}
								id={`${menuItem.route}SidebarIconBorder`}
							/>
						</li>
					))}
				</ul>
			</MenuItems>
			<PrivacyPolicy href="/privacy" target="_blank">
				{formatMessage(messages.privacyPolicy)}
			</PrivacyPolicy>
			<QRCodeHandoffExpanded />
		</Wrapper>
	);
};

export default Sidebar;
