import { GetServerSidePropsContext } from 'next';
import qs from 'query-string';

import { calmLogger } from '@/utils/calmLogger';
import getUrl from '@/utils/getUrl';

interface TokenResp {
	token?: string;
}

const logPrefix = 'getToken';

export async function getToken({ query }: GetServerSidePropsContext): Promise<TokenResp | null> {
	try {
		const redirectUrl = `${getUrl('www')}${process.env.NEXT_PUBLIC_CALM_OAUTH_REDIRECT_PATH}`;
		const queryParams = {
			code: query.code,
			state: query.state,
			redirectUrl,
		};
		const webAPIUrl = `${getUrl('web-api')}/token?${qs.stringify(queryParams)}`;

		const resp = await fetch(webAPIUrl);
		if (resp.status > 400) {
			throw new Error('Unable to get token');
		}
		const data = await resp.json();

		return data?.token;
	} catch (err) {
		calmLogger.error(`${logPrefix} : Oauth token failed`, {}, err);
		throw err;
	}
}
