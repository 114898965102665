import { useRouter } from 'next/router';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { useAnalytics, useExperimentClient } from '@/hooks/analytics';
import { useFocusedQuizMap } from '@/hooks/onboarding/useFocusedQuizMap';
import useQueryParams from '@/hooks/utils/useQueryParams';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { AMPLITUDE_EXPERIMENTS_UNINITIALIZED_STATE } from '@/utils/experiments/amplitudeExperiment';

import FlowMap from '../PreSignupFlowConfigs';
import { FlowConfig, PreSignupFlowProviderProps, ScreenHistoryEntry } from './types';

const VARIANT_HOPPING_AA_TEST_KEY = 'www-variant-hopping-monitor';

export const PreSignupFlowContext = createContext<FlowConfig>({
	ActiveScreen: {
		componentName: 'Goals',
		analyticsName: 'FTUE : Goal Questionnaire',
		backButtonHidden: true,
		nextScreen: 'start',
	},
	setActiveFlowKey: () => {},
	activeScreenKey: 'start',
	nextScreen: () => {},
	previousScreen: () => {},
	skipToEnd: () => {},
	onExit: () => {},
	activeFlowKey: 'goals',
	skippedToEnd: false,
	quizResults: false,
	focusedQuizMap: {},
});

export const PreSignupFlowContextProvider: React.FC<PreSignupFlowProviderProps> = ({ children }) => {
	const query = useQueryParams();
	const routerPush = useRouterPush();
	const router = useRouter();
	const focusedQuizMap = useFocusedQuizMap();

	const [activeFlowKey, setActiveFlowKey] = useState<string>('goals');
	const [activeScreenKey, setActiveScreenKey] = useState<string>('start');
	const [activeScreenSet, setActiveScreenSet] = useState(FlowMap.goals);
	const [ActiveScreen, setActiveScreen] = useState(() => FlowMap.goals.start);
	const [skippedToEnd, setSkippedToEnd] = useState<boolean>(false);
	const [quizResults, setQuizResults] = useState<boolean>(false);

	const isSimplifiedFTUE: boolean = query?.utm_content?.includes('_ft-sf') || false;

	const [screenHistory, setScreenHistory] = useState<ScreenHistoryEntry[]>([]);
	const { logEvent } = useAnalytics();

	/******************************************************************** */
	//** SCREEN SET MANAGEMENT ****************************************** */
	/******************************************************************** */

	/**
	 * Listens to changes in activeFlowKey state and updates the activeScreenSet
	 */

	useEffect(() => {
		setActiveScreenSet(FlowMap[activeFlowKey]);
	}, [activeFlowKey]);

	/**
	 * Set the current screen
	 * listens to changes in activeScreenKey state and activeScreenSet
	 */
	useEffect(() => {
		const newScreen = activeScreenSet[activeScreenKey];
		setActiveScreen(newScreen);
	}, [activeScreenKey, activeScreenSet]);

	/**
	 * Set the active screen key to the first screen in the flow
	 * Triggered when the active screen set changes
	 */
	useEffect(() => {
		if (activeScreenSet && activeScreenSet[activeScreenKey] === undefined) {
			setActiveScreenKey('start');
		}
	}, [activeScreenSet, activeScreenKey]);

	/**
	 * Set the active flow to the flow specified in the query params
	 * Triggered when the query params change
	 * defaults to 'goals' if no flow is specified
	 */
	useEffect(() => {
		function getActiveFlowFromQuery(): string {
			const queryFocus = query?.focus as string;
			if (queryFocus && Object.keys(FlowMap).includes(queryFocus)) {
				return queryFocus;
			}
			return 'goals';
		}
		setActiveFlowKey(getActiveFlowFromQuery());
	}, [query?.focus]);

	/**
	 * Set the flow to a simplified version of the flow if the user has the simplified FTUE query param
	 *
	 * Triggered when the organic traffic query param changes
	 */
	useEffect(() => {
		if (isSimplifiedFTUE) {
			setActiveFlowKey('simplified_signup');
		}
	}, [isSimplifiedFTUE]);

	//** END SCREEN SET MANAGEMENT **/

	/**************************************************************** */
	//** NAVIGATION ************************************************* */
	/**************************************************************** */

	/**
	 * Skip to the end of the flow
	 * If the user is logged in, skip to the upsell screen
	 */

	const _skipToEnd = useCallback(async () => {
		if (ActiveScreen.skipToEndOverride) {
			await routerPush('/signup-flow', { step: ActiveScreen.skipToEndOverride }, { shallow: true });
			setActiveScreenKey(ActiveScreen.skipToEndOverride);
		} else {
			setActiveFlowKey('simplified_signup');
		}
	}, [ActiveScreen, routerPush]);

	const skipToEnd = useCallback(async () => {
		setSkippedToEnd(true);
		await _skipToEnd();
	}, [_skipToEnd]);

	/**
	 * Navigate to the next screen in the flow
	 */

	const nextScreen = useCallback(
		async (overrideConfig?: { flowKey: string; screenKey: string }) => {
			//Calculate any interactive elements before moving on (e.g. quiz results)
			if (ActiveScreen.questionNumber && ActiveScreen.totalQuestions) {
				// If the user has answered all the questions, show the quiz results
				if (ActiveScreen.questionNumber === ActiveScreen.totalQuestions) {
					setQuizResults(true);
				}
			}

			// Store the existing flow and screen key in the history state
			const screenHistoryEntry: ScreenHistoryEntry = {
				flowKey: activeFlowKey,
				screenKey: activeScreenKey,
			};

			if (!activeScreenKey.includes('Selected')) {
				const newHistory = [...screenHistory, screenHistoryEntry];
				setScreenHistory(newHistory);
			}

			// We need to ensure that all navigation goes through this function
			// If the user has navigated from one flow to another, we want to capture the history
			// Allowing an override config allows us to navigate to a specific screen in a specific flow while retaining the history
			if (overrideConfig) {
				const { flowKey } = overrideConfig;
				setActiveFlowKey(flowKey);
				return;
			}

			// Get the current screen and next screen key
			const nextScreenKey = ActiveScreen.nextScreen;

			// Set the next screen key as the active screen key
			if (nextScreenKey) {
				const nextScreen = activeScreenSet[nextScreenKey];
				if (nextScreen.URLKey) {
					await routerPush('/signup-flow', { step: nextScreen.URLKey }, { shallow: true, scroll: true });
				}
				return setActiveScreenKey(nextScreenKey);
			}

			// If there is no next screen key, send the user to the end
			return _skipToEnd();
		},
		[ActiveScreen, activeFlowKey, activeScreenKey, screenHistory, _skipToEnd, routerPush, activeScreenSet],
	);

	/**
	 * Navigate to the previous screen in the flow
	 * Update the screen history array
	 */
	const previousScreen = useCallback(() => {
		if (screenHistory.length > 0) {
			const clonedHistory = [...screenHistory];
			const previousScreenInfo = clonedHistory.pop();
			if (previousScreenInfo) {
				const { flowKey: previousFlowKey, screenKey: previousScreenKey } = previousScreenInfo;
				if (previousFlowKey && previousFlowKey !== activeFlowKey) {
					setActiveFlowKey(previousFlowKey);
				}
				setActiveScreenKey(previousScreenKey || 'start');
			}
			setScreenHistory(clonedHistory);
			return;
		}
	}, [screenHistory, activeFlowKey, setActiveFlowKey, setActiveScreenKey]);

	/**
	 * Exit the flow
	 * Clear the screen history array
	 * Trigger analytics event
	 */
	const onExit = useCallback(async () => {
		setScreenHistory([]);
		if (activeFlowKey === 'signup_payment' || activeFlowKey === 'simplified_signup') {
			const eventName = () => {
				if (activeScreenKey === 'start') {
					return 'Login Form : Exited';
				} else {
					return 'Subscribe : Purchase : Form : Exited';
				}
			};
			await logEvent({
				eventName: eventName(),
			});
		}
		await routerPush('/app');
	}, [routerPush, activeFlowKey, activeScreenKey, logEvent]);

	useEffect(() => {
		/**
		 *  this is where the page will handle when the step is changed due to the user
		 * pressing back on the browser.
		 *
		 * this is reacting to the activeScreenKey changing and getting out of sync with the existing
		 * ?step query param.
		 */
		const activeScreenConfig = activeScreenSet[activeScreenKey];
		if (router.query.step !== activeScreenConfig?.URLKey && activeScreenSet[router.query.step as string]) {
			setActiveScreenKey(router.query.step as string);
		}
	}, [router.query.step, activeScreenKey, activeScreenSet, setActiveScreenKey]);

	useEffect(() => {
		if (!router.query.step && !!activeScreenSet.start.URLKey) {
			routerPush('/signup-flow', { step: ActiveScreen.URLKey }, { shallow: true }).catch(() => {});
		}
	}, [router.query.step, activeScreenSet?.start.URLKey, routerPush, ActiveScreen?.URLKey]);

	//** END NAVIGATION **/

	/** Experiment Hopping Monitor - Remove after Validation **/
	// This code will only fire the first time the user lands on /signup-flow once the client is initialized

	const experimentClient = useExperimentClient();
	const [mountedVariantExperiment, setMountedVariantExperiment] = useState<boolean>(false);

	useEffect(() => {
		if (
			experimentClient &&
			experimentClient !== AMPLITUDE_EXPERIMENTS_UNINITIALIZED_STATE &&
			!mountedVariantExperiment
		) {
			setMountedVariantExperiment(true);
			experimentClient.variant(VARIANT_HOPPING_AA_TEST_KEY);
		}
	}, [experimentClient, mountedVariantExperiment]);

	/** END Experiment Hopping Monitor **/

	const value = useMemo(
		() => ({
			ActiveScreen,
			activeScreenKey,
			activeFlowKey,
			setActiveFlowKey,
			skippedToEnd,
			nextScreen,
			previousScreen,
			onExit,
			skipToEnd,
			quizResults,
			focusedQuizMap,
		}),
		[
			ActiveScreen,
			activeFlowKey,
			setActiveFlowKey,
			skippedToEnd,
			activeScreenKey,
			onExit,
			skipToEnd,
			nextScreen,
			previousScreen,
			quizResults,
			focusedQuizMap,
		],
	);

	return <PreSignupFlowContext.Provider value={value}>{children}</PreSignupFlowContext.Provider>;
};
