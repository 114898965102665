import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
	paymentFormMessages,
	sleepMessages,
	stressAndAnxietyMessages,
} from '@/components/PreSignupFlow/ConfigData/MessageSets';
import { sleepValueProps } from '@/components/PreSignupFlow/PreSignupFlowConfigs/Bespoke/Sleep-Questionnaire';
import { stressValueProps } from '@/components/PreSignupFlow/PreSignupFlowConfigs/Bespoke/Stress-Questionnaire';
import quizMessages from '@/components/SimplifiedSignup/Components/QuizResults/messages';

export const useFocusedQuizMap = () => {
	const { formatMessage } = useIntl();

	const focusedQuizMap = useMemo(
		() => ({
			stress_questionnaire: {
				header: formatMessage(quizMessages.stress),
				image: '/_n/images/onboarding/stressQuizResult.png',
				recommendedContent: formatMessage(quizMessages.stressQuizResult),
				upsellImage: '/_n/images/onboarding/StressLayeredContent.png',
				valueProps: stressValueProps,
				paymentHeading: () => {
					return {
						message: stressAndAnxietyMessages.valuePropHeader,
					};
				},
				valuePropBacking: formatMessage(stressAndAnxietyMessages.valuePropBacking),
				paymentFormHeader: paymentFormMessages.trialFlowPaymentFormTrialLength,
			},
			stress_questionnaireB: {
				header: formatMessage(quizMessages.stress),
				image: '/_n/images/onboarding/stressQuizResult.png',
				recommendedContent: formatMessage(quizMessages.stressQuizResult),
				upsellImage: '/_n/images/onboarding/StressLayeredContent.png',
				valueProps: stressValueProps,
				paymentHeading: () => {
					return {
						message: stressAndAnxietyMessages.valuePropHeader,
					};
				},
				valuePropBacking: formatMessage(stressAndAnxietyMessages.valuePropBacking),
				paymentFormHeader: paymentFormMessages.trialFlowPaymentFormTrialLength,
			},
			sleep_questionnaire: {
				header: formatMessage(quizMessages.sleep),
				image: '/_n/images/onboarding/sleepQuizResult.png',
				recommendedContent: formatMessage(quizMessages.sleepQuizResult),
				upsellImage: '/_n/images/onboarding/SleepLayeredContent.png',
				valueProps: sleepValueProps,
				paymentHeading: () => {
					return {
						message: sleepMessages.valuePropHeader,
					};
				},
				valuePropBacking: formatMessage(sleepMessages.valuePropBacking),
				paymentFormHeader: paymentFormMessages.trialFlowPaymentFormTrialLength,
			},
			sleep_questionnaireB: {
				header: formatMessage(quizMessages.sleep),
				image: '/_n/images/onboarding/sleepQuizResult.png',
				recommendedContent: formatMessage(quizMessages.sleepQuizResult),
				upsellImage: '/_n/images/onboarding/SleepLayeredContent.png',
				valueProps: sleepValueProps,
				paymentHeading: () => {
					return {
						message: sleepMessages.valuePropHeader,
					};
				},
				valuePropBacking: formatMessage(sleepMessages.valuePropBacking),
				paymentFormHeader: paymentFormMessages.trialFlowPaymentFormTrialLength,
			},
		}),
		[formatMessage],
	);

	return focusedQuizMap;
};
