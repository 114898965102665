import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, lineHeight, units } from '@calm-web/design-system';

export const Wrapper = styled.div`
	position: relative;
	cursor: pointer;
	margin-bottom: 24px;
	height: 240px;
	width: 300px;
`;

export const WhiteTile = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px;
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14);
	background: ${palette('gray0')};

	&:nth-of-type(1) {
		width: 300px;
		height: 150px;
	}

	&:nth-of-type(2) {
		width: 244px;
		height: 190px;
	}
`;

export const Image = styled.div<{
	src: string;
}>`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 12px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
	height: 240px;
	width: 190px;
	background-image: url('${(props): string => props.src}');
	background-position: center;
	background-size: cover;
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
`;

export const ImageOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 140px;
	width: 190px;
	border-radius: inherit;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 14.5%, rgba(0, 0, 0, 0) 100%);
	z-index: -1;
`;

export const Title = styled.p`
	color: ${palette('white')};
	text-align: center;
	margin-top: 8px;
	font-size: ${units(2)};
	line-height: ${units(2.25)};
	letter-spacing: ${units(0.04)};
	${fontWeight(FontWeights.Medium)};
`;

export const Description = styled.p`
	font-size: ${units(1.25)};
	${fontWeight(FontWeights.Demi)};
	color: ${palette('whiteAlpha70')};
	text-transform: uppercase;
	letter-spacing: 2px;
`;

export const Subtitle = styled.p`
	${fontWeight(FontWeights.Medium)};
	${fontSize(FontSizes.sm)};
	${lineHeight(FontSizes.sm)};
	display: inline-flex;
	align-items: center;
	border-radius: 20px;
	background-color: ${palette('transparentBlackBackground')};
	color: ${palette('white')};
	text-align: center;
	padding: 5px 10px;
	backdrop-filter: blur(12px);
`;

export const TitleSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
