import { MessageDescriptor } from 'react-intl';

import { AllowedInputTypes } from '@calm-web/design-system';

import messages from '@/messages/messages';
import IconLock from 'icons/lock-outline.svg';
import IconMail from 'icons/mail.svg';
import IconProfile from 'icons/profile-outline.svg';

export interface FormInput {
	name: 'email' | 'password' | 'name';
	placeholder: MessageDescriptor;
	icon: React.FC;
	type?: AllowedInputTypes;
	errorMessage?: string | null;
	required?: boolean;
}

export const loginFormInputs: FormInput[] = [
	{
		name: 'email',
		placeholder: messages.emailRequiredPlaceholder,
		icon: IconMail,
		type: 'email',
		required: true,
	},
	{
		name: 'password',
		placeholder: messages.loginPasswordRequiredPlaceholder,
		icon: IconLock,
		type: 'password',
		required: true,
	},
];

export const signupFormInputs: FormInput[] = [
	{
		name: 'name',
		placeholder: messages.nameRequiredPlaceholder,
		icon: IconProfile,
		required: true,
	},
	{
		name: 'email',
		placeholder: messages.emailRequiredPlaceholder,
		icon: IconMail,
		type: 'email',
		required: true,
	},
	{
		name: 'password',
		placeholder: messages.signupPasswordRequiredPlaceholder,
		icon: IconLock,
		type: 'password',
		required: true,
	},
];
