import { FlowConfig } from '../../Context/types';
import Control from './Screens';

const ControlFlow: FlowConfig = {
	activeScreenKey: 'account',
	activeScreenConfig: Control.account,
	name: 'SimplifiedSignup',
	pageName: 'SimplifiedSignup',
	screens: {
		account: Control.account,
		payment: Control.payment,
		handoff: Control.handoff,
	},
	plan: 'yearly',
	coupon: 'freetrial',
	hasFreeTrial: true,
};

export default ControlFlow;
