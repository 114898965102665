import { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

import { CalmTheme, DesignSystemProvider } from '@calm-web/design-system';

import IntlWrapper from '@/components/layout/IntlWrapper';
import { useStore } from '@/hooks/store/useStore';
import { AppInitialState } from '@/store';

const ServerProviders = ({
	initialState,
	children,
}: PropsWithChildren<{ initialState: AppInitialState }>) => {
	const store = useStore(initialState);

	return (
		<Provider store={store}>
			<IntlWrapper>
				<DesignSystemProvider theme={CalmTheme}>{children}</DesignSystemProvider>
			</IntlWrapper>
		</Provider>
	);
};

export default ServerProviders;
