import { AxiosPromise } from 'axios';

import { browserApiRequest } from '@/utils/apiRequest/browserApiRequest';

export function postForgotPassword(email: string): AxiosPromise {
	return browserApiRequest({
		endpoint: 'password_reset',
		method: 'POST',
		body: { email },
	});
}
