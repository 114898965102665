import { AxiosPromise } from 'axios';

import { User } from '@/store/user/types';
import { browserApiRequest } from '@/utils/apiRequest/browserApiRequest';

export function postMe(body?: User): AxiosPromise {
	return browserApiRequest({
		endpoint: 'me',
		method: 'POST',
		noCache: true,
		body,
	});
}
