import { useState } from 'react';

import DevToolsOverlay from '@/components/devtools/DevToolsOverlay';
import Gear from '@/components/devtools/Gear';

const Devtools = () => {
	const [isDevToolsOpen, setIsDevToolsOpen] = useState(false);

	if (process.env.NEXT_PUBLIC_CALM_ENV === 'production' || process.env.NODE_ENV === 'test') {
		return null;
	}

	return (
		<>
			<Gear setIsDevToolsOpen={setIsDevToolsOpen} />
			{isDevToolsOpen && <DevToolsOverlay setIsDevToolsOpen={setIsDevToolsOpen} />}
		</>
	);
};

export default Devtools;
