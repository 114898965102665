import { BraintreeTokenizePayload } from '@paypal/react-paypal-js/dist/types/types/braintree/commonsTypes';
import { ExpressCheckoutElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
	ClickResolveDetails,
	StripeExpressCheckoutElementClickEvent,
	StripeExpressCheckoutElementConfirmEvent,
	StripeExpressCheckoutElementOptions,
	StripePaymentElementChangeEvent,
	StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { useRouter } from 'next/router';
import { FormEvent, ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Notice, Text } from '@calm-web/design-system';

import messages from '@/components/purchase/CreditCardForm/messages';
import TrueMedWidget from '@/components/purchase/TrueMedWidget';
import { getPurchaseError } from '@/components/purchase/utils/getPurchaseError';
import { SubscribePurchaseError, subscribePurchaseErrors } from '@/components/purchase/utils/Purchase';
import { useAnalytics } from '@/hooks/analytics';
import { ProductInfo } from '@/hooks/analytics/types';
import { useApi } from '@/hooks/api';
import { usePaymentFormValues } from '@/hooks/purchase/usePaymentFormValues';
import { usePaypalClientToken } from '@/hooks/purchase/usePaypalClientToken';
import { initLoggableSubscriptionDetails } from '@/hooks/purchase/usePurchaseSuccess';
import {
	useBrowserLanguageState,
	useDeviceState,
	usePricesState,
	usePurchaseParamsState,
	useRecaptchaVisibleState,
	useUserState,
} from '@/hooks/store';
import { setUser } from '@/store/user/actions';
import { Subscription } from '@/store/user/types';
import { formatError } from '@/utils/formatErrors';
import { JSONObject } from '@/utils/types';
import { validate } from '@/utils/ui/validator';

import PaymentElementsFormSubmitButton from '../PaymentElementsFormSubmitButton';
import PaymentError from '../PaymentError';
import PaypalButton from '../PaypalButton';
import PurchaseTerms from '../PurchaseTerms';
import SecureTransaction from '../SecureTransaction';
import _messages from './messages';
import { ExpressCheckoutWrapper, NameOnCardInput, NoticeWrapper, TextWrapper, Wrapper } from './styles';
import { isSubscriptionResponse, PurchaseRequestResponse, RequiresActionResponse } from './types';
import { createPurchaseBodyArgs, createSuccessEventProps } from './utils';

export type PaymentElementsFormProps = {
	endpoint: string | ((args: Record<string, unknown>) => string);
	purchaseArgs?: Record<string, unknown>;
	successArgs?: Record<string, unknown>;
	ignoreSubscribeErrors?: boolean;
	subscribePurchaseErrors?: SubscribePurchaseError[];
	analyticsPrefix?: string;
	analyticsEventProps?: Record<string, string>;
	hidePriceAfterDisclaimer?: boolean;
	purchaseTerms?: string | ReactNode;
	ctaCopy?: string;
	productInfo?: ProductInfo;
	afterPurchaseSuccess?: () => void;
	onPurchaseSuccess?: (
		response: Record<string, unknown>,
		purchaseArgs: Record<string, unknown>,
	) => Promise<void>;
	hidePaypal?: boolean;
	hideApplePay?: boolean;
	hideTerms?: boolean;
	hasTrueMedSupport?: boolean;
};

const PaymentElementsForm: React.FC<PaymentElementsFormProps> = ({
	endpoint,
	purchaseTerms,
	ctaCopy,
	onPurchaseSuccess: onPurchaseSuccessOverride,
	afterPurchaseSuccess,
	productInfo: productInfoOverride,
	analyticsPrefix = 'Subscribe : Purchase : Form',
	analyticsEventProps,
	purchaseArgs = {},
	successArgs = {},
	subscribePurchaseErrors: subscribePurchaseErrorsOverride,
	ignoreSubscribeErrors = false,
	hidePriceAfterDisclaimer = true,
	hidePaypal = false,
	hideApplePay = false,
	hideTerms = false,
	hasTrueMedSupport = false,
}) => {
	const prices = usePricesState();
	const purchaseParams = usePurchaseParamsState();
	const user = useUserState();
	const stripe = useStripe();
	const recaptchaVisible = useRecaptchaVisibleState();
	const elements = useElements();
	const apiRequest = useApi();
	const { query } = useRouter();
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { paymentFormCurrency } = usePaymentFormValues();
	const browserLanguage = useBrowserLanguageState();
	const { logEvent, logEventAsync, logPurchase } = useAnalytics();
	const {
		clientToken: paypalClientToken,
		isSupported: isPaypalSupported,
		isLoading: isPaypalClientTokenFetching,
	} = usePaypalClientToken();
	const device = useDeviceState();

	const [selectedPaymentType, setSelectedPaymentType] = useState<string | null>(null);
	const [nameOnCard, setNameOnCard] = useState('');
	const [isPaymentElementsValid, setIsPaymentElementsValid] = useState(false);
	const [noSubscribeError] = useState(
		!ignoreSubscribeErrors && purchaseParams?.purchaseType?.type
			? (subscribePurchaseErrorsOverride ?? subscribePurchaseErrors).includes(
					purchaseParams.purchaseType.type as SubscribePurchaseError,
			  )
			: false,
	);
	const [isLoading, setIsLoading] = useState(false);
	const [formMessage, setFormMessage] = useState<{ isSuccess: boolean; message: string } | null>(null);
	const isPaypalVisible = !hidePaypal && isPaypalSupported;

	const onPurchaseError = (
		errorMessageToDisplay: string,
		eventProps: JSONObject = {},
		errorCode?: string | JSONObject,
	) => {
		logEvent({
			eventName: `${analyticsPrefix} : Error`,
			eventProps: {
				currency: paymentFormCurrency,
				payment_type: selectedPaymentType,
				is_stripe_next: true,
				...(errorCode ? { error: errorCode } : {}),
				...eventProps,
				...analyticsEventProps,
			},
		});

		setFormMessage({
			isSuccess: false,
			message: errorMessageToDisplay,
		});
	};

	const onPurchaseSuccess = async (subscription: Subscription, eventProps: JSONObject = {}) => {
		const _user = {
			...user,
			subscription,
		};
		dispatch(setUser(_user));
		const transactionInfo = { id: _user?.id ?? null };
		const productInfo: ProductInfo = productInfoOverride ?? {
			name: 'web_subscription',
			sku: purchaseParams.plan,
			unit_price: purchaseParams?.purchaseType?.price ? purchaseParams?.purchaseType.price / 100.0 : 0,
			quantity: 1,
		};

		logPurchase({ productInfo, transactionInfo });

		const subscriptionDetails = initLoggableSubscriptionDetails(_user?.subscription);
		const successEventProps = createSuccessEventProps({
			productInfo,
			prices,
			purchaseParams,
			selectedPaymentType,
			paymentFormCurrency,
			browserLanguage,
			userEmail: _user?.email,
			successArgs: {
				is_stripe_next: true,
				source: query?.source ?? '',
				...subscriptionDetails,
				...eventProps,
				...successArgs,
			},
		});

		setFormMessage({
			isSuccess: true,
			message: formatMessage(_messages.success),
		});

		await logEventAsync({
			eventName: `${analyticsPrefix} : Success`,
			eventProps: { ...successEventProps, ...analyticsEventProps },
		});

		if (afterPurchaseSuccess) {
			afterPurchaseSuccess();
		}
	};

	async function onRequiresAction({ clientSecret, customerId, subscriptionId }: RequiresActionResponse) {
		if (!stripe) return;

		logEvent({
			eventName: `${analyticsPrefix} : Requires Action`,
			eventProps: {
				payment_type: selectedPaymentType,
				is_stripe_next: true,
				...analyticsEventProps,
			},
		});

		const { paymentIntent, setupIntent, error } = await stripe.handleNextAction({
			clientSecret,
		});

		if (error) {
			if (error.message) {
				setFormMessage({
					isSuccess: false,
					message: error.message,
				});
			}
			setIsLoading(false);
			return;
		}

		// A payment intent is created for purchases that require immediate payment
		// A setup intent is created for purchases that instead kick off a free trial
		const stripeIntent = paymentIntent ?? setupIntent;

		switch (stripeIntent?.status) {
			case 'succeeded':
				setFormMessage(null);
				setIsLoading(true);
				await submitPaymentRequest({
					paymentMethod: 'stripe',
					stripeCustomerId: customerId,
					stripeIntentId: stripeIntent.id,
					stripePaymentMethodId: stripeIntent.payment_method,
					stripeSubscriptionId: subscriptionId,
				});
				break;
			case 'processing':
				onPurchaseError(formatMessage(_messages.processing), {}, paymentIntent?.status);
				break;
			case 'requires_payment_method':
				onPurchaseError(formatMessage(_messages.unsuccessful), {}, paymentIntent?.status);
				break;
			default:
				onPurchaseError(formatMessage(_messages.fallback));
				break;
		}
	}

	async function submitPaymentRequest(
		paymentTypeSpecificArgs: Record<string, unknown>,
		eventProps: JSONObject = {},
	) {
		try {
			const purchaseBodyArgs = createPurchaseBodyArgs({
				purchaseParams,
				prices,
				user,
				purchaseArgs: {
					isStripeNextEnrolled: true,
					...purchaseArgs,
					...paymentTypeSpecificArgs,
					...(hasTrueMedSupport && {
						trueMedLink: `https://app.truemed.com/qualify/${process.env.NEXT_PUBLIC_TRUEMED_PUBLIC_QUALIFICATION_ID}?user_id=${user?.id}`,
					}),
				},
			});

			const _endpoint = typeof endpoint === 'string' ? endpoint : endpoint(purchaseBodyArgs);
			const { data } = await apiRequest<PurchaseRequestResponse>({
				endpoint: _endpoint,
				method: 'POST',
				body: purchaseBodyArgs,
				excludedHeaders: _endpoint === 'subscription/email' ? ['x-session-token'] : [],
			});

			logEvent({
				eventName: `${analyticsPrefix} : Posted`,
				eventProps: {
					endpoint: _endpoint,
					payment_type: selectedPaymentType,
					is_stripe_next: true,
					...eventProps,
					...analyticsEventProps,
				},
			});

			if (onPurchaseSuccessOverride) {
				await onPurchaseSuccessOverride(data, purchaseBodyArgs);
				return;
			}

			if (isSubscriptionResponse(data)) {
				await onPurchaseSuccess(data, eventProps);
			}
		} catch (err) {
			if (err.data.error.code === 'requires_action') {
				await onRequiresAction(err.data.error.info);
			} else {
				const error = err.data.error;
				const sanitizedError = {
					...error,
					calmCode: error?.calmCode || error?.code,
				};
				onPurchaseError(
					formatMessage(getPurchaseError(error)),
					{ ...formatError(sanitizedError), ...eventProps },
					sanitizedError.calmCode,
				);
			}
		} finally {
			setIsLoading(false);
		}
	}

	const onPaypalSubmit = async (paypalToken: BraintreeTokenizePayload) => {
		setFormMessage(null);
		setIsLoading(true);
		await submitPaymentRequest({ paypalToken, paymentMethod: 'paypal' }, { payment_type: 'paypal' });
	};

	const onStripeSubmit = async (e: FormEvent | StripeExpressCheckoutElementConfirmEvent) => {
		if ('preventDefault' in e) {
			e.preventDefault();
		}
		if (!stripe || !elements || isLoading) return;
		setFormMessage(null);
		setIsLoading(true);

		await elements.submit();
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			elements,
			params: {
				billing_details: {
					name: nameOnCard,
					email: user?.email,
				},
			},
		});

		if (error) {
			if (error.message) {
				setFormMessage({
					isSuccess: false,
					message: error.message,
				});
			}
			setIsLoading(false);
			return;
		}

		await submitPaymentRequest({ stripePaymentMethodId: paymentMethod.id, paymentMethod: 'stripe' });
	};

	const onPaymentElementChange = (e: StripePaymentElementChangeEvent) => {
		setIsPaymentElementsValid(e.complete);
	};

	useEffect(() => {
		if (selectedPaymentType) {
			logEvent({
				eventName: 'Subscribe : Payment Type : Selected',
				eventProps: {
					payment_type: selectedPaymentType,
					is_stripe_next: true,
				},
			});
		}
	}, [selectedPaymentType, logEvent]);

	const nameIsValid = selectedPaymentType === 'credit_card' ? validate('name', nameOnCard) : true;

	const paymentElementOptions: StripePaymentElementOptions = {
		defaultValues: {
			billingDetails: {
				email: user?.email,
				name: user?.name,
				address: {
					country: device.ip_country ?? undefined,
				},
			},
		},
	};

	const expressCheckoutElementOptions: StripeExpressCheckoutElementOptions = {
		buttonHeight: 55,
		wallets: {
			applePay: hideApplePay ? 'never' : 'auto',
		},
	};

	const expressCheckoutOnConfirm = async (event: StripeExpressCheckoutElementConfirmEvent) => {
		await onStripeSubmit(event);
	};

	const expressCheckoutOnCancel = () => {
		logEvent({
			eventName: `${analyticsPrefix} : Cancelled`,
			eventProps: {
				payment_type: selectedPaymentType,
				...analyticsEventProps,
			},
		});
	};

	const expressCheckoutOnClick = (event: StripeExpressCheckoutElementClickEvent) => {
		setSelectedPaymentType(event.expressPaymentType);

		const isFreeTrial = purchaseParams?.purchaseType?.type === 'freetrial';
		const deferredPaymentDate = new Date();
		deferredPaymentDate.setDate(
			deferredPaymentDate.getDate() + (purchaseParams?.purchaseType?.duration ?? 14),
		);
		const freeTrialResolveArgs: ClickResolveDetails = isFreeTrial
			? {
					applePay: {
						deferredPaymentRequest: {
							managementURL: process.env.NEXT_PUBLIC_WWW_URL as string,
							paymentDescription: formatMessage(_messages.applePayPaymentDescription),
							deferredBilling: {
								deferredPaymentDate,
								label: formatMessage(_messages.applePayPaymentDescription),
								amount: 0,
							},
						},
					},
			  }
			: {};

		event.resolve({
			billingAddressRequired: true,
			...freeTrialResolveArgs,
		});
	};

	if (noSubscribeError) {
		return <PaymentError errorType={purchaseParams?.purchaseType?.type as SubscribePurchaseError} />;
	}

	return (
		<Wrapper onSubmit={onStripeSubmit} id="purchase-form">
			<ExpressCheckoutWrapper>
				<ExpressCheckoutElement
					options={expressCheckoutElementOptions}
					onConfirm={expressCheckoutOnConfirm}
					onClick={expressCheckoutOnClick}
					onCancel={expressCheckoutOnCancel}
				/>
			</ExpressCheckoutWrapper>
			{isPaypalVisible && (
				<PaypalButton
					setSelectedPaymentType={setSelectedPaymentType}
					onSubmit={onPaypalSubmit}
					onError={onPurchaseError}
					clientToken={paypalClientToken}
					isLoading={isPaypalClientTokenFetching ?? (selectedPaymentType === 'paypal' && isLoading)}
					isDisabled={recaptchaVisible || isLoading}
					analyticsPrefix={analyticsPrefix}
				/>
			)}
			<TextWrapper>
				<Text color="blackAlpha60">{formatMessage(_messages.or)}</Text>
			</TextWrapper>
			<TextWrapper>
				<NameOnCardInput
					name="name"
					label={formatMessage(messages.firstName)}
					value={nameOnCard}
					isValid={nameIsValid}
					onChange={e => setNameOnCard(e.target.value)}
					error={formatMessage(messages.firstNameError)}
					labelFocusScaleTransform={0.85}
					onFocus={() => setSelectedPaymentType('credit_card')}
					autoComplete="cc-name"
				/>
				<PaymentElement
					onChange={onPaymentElementChange}
					options={paymentElementOptions}
					onFocus={() => setSelectedPaymentType('credit_card')}
				/>
			</TextWrapper>
			{!hideTerms && (
				<PurchaseTerms hidePriceAfterDisclaimer={hidePriceAfterDisclaimer} purchaseTerms={purchaseTerms} />
			)}
			<PaymentElementsFormSubmitButton
				isDisabled={!stripe || recaptchaVisible || !isPaymentElementsValid || !nameIsValid || isLoading}
				isLoading={isLoading}
				ctaCopy={ctaCopy}
			/>
			{hasTrueMedSupport && <TrueMedWidget />}
			<SecureTransaction />
			{formMessage?.message && (
				<NoticeWrapper>
					<Notice
						isSuccess={formMessage.isSuccess}
						onClick={() => setFormMessage(null)}
						describedBy="purchase-form"
					>
						{formMessage.message}
					</Notice>
				</NoticeWrapper>
			)}
		</Wrapper>
	);
};

export default PaymentElementsForm;
