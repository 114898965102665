/*
 * context.res.getHeaders()['set-cookie'] is an array of strings that take the following form:
 * 'calm-user-token=<token>; Max-Age=86400; Domain=local.calm.com; Secure'
 */

import { OutgoingHttpHeader } from 'http';
import cookie from 'cookie';

export const getCookieValueFromRes = (cookies: OutgoingHttpHeader | undefined, cookieKey: string): string => {
	if (!cookies || typeof cookies === 'number' || typeof cookies === 'string') {
		return '';
	}
	for (let i = 0; i < cookies.length; i++) {
		const parsedCookie = cookie.parse(cookies[i]);
		if (parsedCookie[cookieKey]) {
			return parsedCookie[cookieKey];
		}
	}
	return '';
};
