import { AxiosPromise } from 'axios';

import { browserApiRequest } from '@/utils/apiRequest/browserApiRequest';

type PollResponse = {
	poll_name: string;
	question: string;
	answers: string[];
};

export function postQuestionnairePoll(poll_responses: PollResponse[]): AxiosPromise {
	return browserApiRequest({
		endpoint: 'poll-responses',
		method: 'POST',
		body: {
			poll_responses,
		},
	});
}
