import { createContext, ReactNode, useMemo, useState } from 'react';

import CookiePreferencesBanner from '@/components/layout/CookiePreferencesBanner';
import { useElementDimensions } from '@/hooks/ui/useElementDimensions';

import { CookiePreferencesBannerContextProps } from './types';

export const COOKIE_PREFERENCES_BANNER_ID = 'cookie-preferences-banner';

export const CookiePreferencesBannerContext = createContext<CookiePreferencesBannerContextProps>({
	isOpen: false,
	setIsOpen: () => {},
	canBeShown: false,
	setCanBeShown: () => {},
	isAnnounced: false,
	setIsAnnounced: () => {},
	isForciblyShown: false,
	setIsForciblyShown: () => {},
	fixedBottomHeight: 0,
});

const CookiePreferencesBannerProvider = ({
	children,
	hideAcceptCookiesModal = false,
}: {
	children?: ReactNode;
	hideAcceptCookiesModal?: boolean;
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [canBeShown, setCanBeShown] = useState<boolean>(!hideAcceptCookiesModal);
	const [isAnnounced, setIsAnnounced] = useState<boolean>(false);
	const [isForciblyShown, setIsForciblyShown] = useState<boolean>(false);
	const [fixedBottomContainerRef, { height: fixedBottomHeight }] = useElementDimensions();

	const value = useMemo(
		() => ({
			isOpen,
			setIsOpen,
			canBeShown,
			setCanBeShown,
			isAnnounced,
			setIsAnnounced,
			isForciblyShown,
			setIsForciblyShown,
			fixedBottomHeight,
		}),
		[isOpen, canBeShown, isAnnounced, isForciblyShown, fixedBottomHeight],
	);

	return (
		<CookiePreferencesBannerContext.Provider value={value}>
			{children}
			{canBeShown && (
				<CookiePreferencesBanner
					id={COOKIE_PREFERENCES_BANNER_ID}
					willForceShow={isForciblyShown}
					willAnnounce={isAnnounced}
					setIsOpen={setIsOpen}
					isOpen={isOpen}
					innerRef={fixedBottomContainerRef}
				/>
			)}
		</CookiePreferencesBannerContext.Provider>
	);
};

export default CookiePreferencesBannerProvider;
