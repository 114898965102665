import styled from 'styled-components';

import { minWidth } from '@calm-web/design-system';

export const Wrapper = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 32px;
	margin-bottom: 8px;
	overflow: visible;
	list-style: none;
	padding-left: 0;

	${minWidth('desktop')} {
		flex-direction: row;
		grid-column-gap: 16px;
		margin-bottom: 28px;
	}
`;

export const PlanSkuListItem = styled.li`
	flex: 1 1 0;
`;
