import { IncomingMessage } from 'http';

export const getUserToken = (
	cookies: Record<string, string>,
	req: IncomingMessage,
	calmUserToken?: string,
) => {
	if (cookies['calm-user-token']) {
		return cookies['calm-user-token'];
	}

	if (calmUserToken) {
		return calmUserToken;
	}

	if (req.url) {
		const parsedUrl = new URL(req.url, `https://${req.headers.host}`);
		return parsedUrl.searchParams.get('token');
	}
};
