import styled, { css } from 'styled-components';
import { ifNotProp, ifProp } from 'styled-tools';

import { Card, minWidth, Text } from '@calm-web/design-system';

import AnimatedScreenWrapper from '@/components/PreSignupFlow/Components/AnimatedScreenWrapper';
import LimitedTimeCalloutRaw from '@/components/PreSignupFlow/Components/LimitedTimeCallout';
import _LoggedInAs from '@/components/purchase/LoggedInAs';
import { TwoColumnWrapper as _TwoColumnWrapper } from '@/components/SimplifiedSignup/styles';

// The max-height is calculated as the full height of the viewport,
// minus the top margin, top/bottom padding & border of modalBody.
export const PaymentWrapper = styled.div<{ $overflow?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
	width: 100%;

	${ifProp(
		'$overflow',
		css`
			max-height: calc(95vh - 7rem - 2px);
			overflow-y: auto;
		`,
	)}

	${minWidth('desktop')} {
		width: 700px;
	}
`;

export const ValuePropContainer = styled.div<{ showHeader: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	max-width: 700px;
	margin: 0;

	${ifNotProp(
		'showHeader',
		css`
			margin-top: 60px;
		`,
	)}

	${minWidth('desktop')} {
		display: block;
		margin: 0 auto;
		margin-top: 40px;
		max-width: 420px;
		h1 {
			text-align: left;
		}
		text-align: left;
	}
`;

export const PartnerValidationWrapper = styled(Card)`
	padding: 48px;
	border-radius: 20px;
	background-color: white;
`;

export const PurchaseFormWrapper = styled(AnimatedScreenWrapper)`
	position: relative;
`;

export const TwoColumnWrapper = styled(_TwoColumnWrapper)`
	flex-direction: column;

	${minWidth('desktop')} {
		flex-direction: row;
		gap: 60px;
	}
`;

export const ValuePropBackingText = styled(Text)<{
	skippedToEnd?: boolean;
}>`
	${ifNotProp('skippedToEnd', 'display: none;')}

	${minWidth('desktop')} {
		display: block;
		margin-top: 20px;
	}
`;

export const LimitedTimeCallout = styled(LimitedTimeCalloutRaw)`
	margin: 0;
`;

export const LoggedInAs = styled(_LoggedInAs)`
	margin: 0;
`;
